import { Component, OnInit, Input } from '@angular/core';
import { ReportesService } from '../../../services/service.index';
import { Router } from '@angular/router';
import { StpService } from '../../../services/stp/stp.service';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';
import { ParametrosGeneralesService } from '../../../services/parametrosgenerales/parametrosgenerales.service';
//import * as XLSX from 'xlsx';
//import jsPDF from 'jspdf';
//import 'jspdf-autotable';
import * as fs from 'file-saver';
//declare var $;


@Component({
  selector: 'app-stp',
  templateUrl: './stp.component.html',
  styles: []
})
export class StpComponent implements OnInit {

  constructor( private _stpservice: StpService,
               public _reportesservice: ReportesService,
               public http: HttpClient,
               private parametrosService: ParametrosGeneralesService) { }

  token = localStorage.getItem('token');
  //doc = new jsPDF();
  facturas: any[] = [];
  usuario: string;
  cols: any[];
  colspdf: any[];
  selectedFac: any[];
  saldo = 0;
  nombre_ordenante = '';
  tipo_cuenta_ordenante = '';
  cuenta_ordenante = '';
  router: Router;
  fileName = 'ListaDeFacturas.xlsx';
  selectedColumnsp: any[];
  selectedColumnspdf: any[];
  exportColumns: any[];

  _selectedColumns: any[];

  ngOnInit() {

    this.cols = [

    //  { field:  'id_factura', header: 'ID'},
    //   { field:  'id_factura', header: 'ID Factura'},
    //   { field:  'oper', header: 'Operacion'},
    //   { field:  'clave_id', header: 'Clave'},
    //   { field:  'cuenta_origen', header: 'Cuenta Origen'},
    //   { field:  'cuenta_destino', header: 'Cuenta Destino'},
    //   { field:  'importe', header: 'Importe'},
    //   { field:  'referencia', header: 'Referencia'},
    //   { field:  'descripcion', header: 'Descripcion'},
    //   { field:  'rfc_ordenante', header: 'RFC Ordenante'},
    //   { field:  'iva', header: 'IVA'},
    //   { field:  'fecha_aplicacion', header: 'Fecha Aplicacion'},
    //   { field:  'instruccion_pago', header: 'Instruccion Pago'}
      { field:  'institucion_contraparte', header: 'Institucion Contraparte'},
      { field:  'clave_rastreo', header: 'Clave de Rastreo'},
      { field:  'nombre_beneficiario', header: 'Nombre de Beneficiario'},
      { field:  'rfc_curp_beneficiario', header: 'Rfc Curp Beneficiario'},
      { field:  'tipo_pago', header: 'Tipo de Pago'},
      { field:  'tipo_cuenta_beneficiario', header: 'Tipo Cuenta Beneficiario'},
      { field:  'monto', header: 'Monto'},
      { field:  'cuenta_beneficiario', header: 'Cuenta Beneficiario'},
      { field:  'concepto_pago', header: 'Concepto de Pago'},
      { field:  'referencia_numerica', header: 'Referencia Numerica'},
      { field:  'institucion_operante', header: 'Institucion Operante'},
      { field:  'empresa', header: 'Empresa'}

  ];

    this._selectedColumns = this.cols;
    this.colspdf = [
    //  { field:  'id_factura', header: 'ID'},
    // { field:  'id_factura', header: 'ID Factura'},
    // { field:  'oper', header: 'Operacion'},
    // { field:  'clave_id', header: 'Clave'},
    // { field:  'cuenta_origen', header: 'Cuenta Origen'},
    // { field:  'cuenta_destino', header: 'Cuenta Destino'},
    // { field:  'importe', header: 'Importe'},
    // { field:  'referencia', header: 'Referencia'},
    // { field:  'descripcion', header: 'Descripcion'},
    // { field:  'rfc_ordenante', header: 'RFC Ordenante'},
    // { field:  'iva', header: 'IVA'},
    // { field:  'fecha_aplicacion', header: 'Fecha Aplicacion'},
    // { field:  'instruccion_pago', header: 'Instruccion Pago'}
    { field:  'institucion_contraparte', header: 'Institucion Contraparte'},
    { field:  'clave_rastreo', header: 'Clave de Rastreo'},
    { field:  'nombre_beneficiario', header: 'Nombre de Beneficiario'},
    { field:  'rfc_curp_beneficiario', header: 'Rfc Curp Beneficiario'},
    { field:  'tipo_pago', header: 'Tipo de Pago'},
    { field:  'tipo_cuenta_beneficiario', header: 'Tipo Cuenta Beneficiario'},
    { field:  'monto', header: 'Monto'},
    { field:  'cuenta_beneficiario', header: 'Cuenta Beneficiario'},
    { field:  'concepto_pago', header: 'Concepto de Pago'},
    { field:  'referencia_numerica', header: 'Referencia Numerica'},
    { field:  'institucion_operante', header: 'Institucion Operante'},
    { field:  'empresa', header: 'Empresa'}
];
    this.selectedColumnsp = this.cols;
    this.exportColumns = this.colspdf.map(col => ({title: col.header, dataKey: col.field}));
    
      this.parametrosService.getGeneralParam('NOMBRE_ORDENANTE').subscribe(resp => {
      this.nombre_ordenante = resp['value'];
      this.parametrosService.getGeneralParam('CUENTA_ORIGEN_STP').subscribe(resp => {
        this.cuenta_ordenante = resp['value'];
        this.parametrosService.getGeneralParam('TIPO_CUENTA_ORDENANTE').subscribe(resp => {
          this.tipo_cuenta_ordenante = resp['value'];
          this.consulta_saldo_stp();
        })
      })
    })

  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
}

set selectedColumns(val: any[]) {
  // restore original order
  this._selectedColumns = this.cols.filter(col => val.includes(col));
}

generarReporte() {

  swal2.fire({
    title: 'Cargando',
    allowOutsideClick: false
});
  swal2.showLoading();

  const d = new Date((document.getElementById('fechaconsulta')as HTMLInputElement).value);
  d.setMinutes( d.getMinutes() + d.getTimezoneOffset() );
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) {
        month = '0' + month;
    }
  if (day.length < 2) {
        day = '0' + day;
    }

  const fecharepor = [year, month, day].join('-');
  const type = ((document.getElementById('type')as HTMLInputElement).value);

  this._reportesservice.getStp(fecharepor,type).subscribe(resp => {this.facturas = resp;
                                                                  swal2.close();
                                                                  if ( this.facturas.length === 0 ) {

                                                                        swal2.fire(
                                                                          'No se encontraron datos con la fecha:',
                                                                          fecharepor,
                                                                          'error'
                                                                          );
                                                                      } else {
                                                                        // tslint:disable-next-line: forin
                                                                        for (const prop in this.facturas) {
                                                                          this.facturas[prop].importe = this.facturas[prop].monto.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                                                        }
                                                                      }
  }, (err) => {
    swal2.close();
    console.log(err);
    swal2.fire(
         'Error al Consultar los Datos',
         '',
         'error'
      );
    this.ngOnInit();
   } );


}

  consulta_saldo_stp(){
    const params = {
      empresa: this.nombre_ordenante,
      cuentaOrdenante: this.cuenta_ordenante
    };
    this._stpservice.getStpAccountBalanceStp(params)
    .subscribe(resp => {
      try{
        this.saldo = resp['response']['respuesta']['saldo'];
        swal2.close();
      }catch{
        swal2.fire('Atención', 'Hubo un problema en la consulta de saldo', 'info');
        return
      }
      
    } , err => {
      console.log(err)
      swal2.fire({title: 'Ocurrio un error al cargar la información', allowOutsideClick: false })} );
  }

  exportexcel() {

    /*
    console.log(this.facturas);
     const element = document.getElementById('tablaFacturas');
     const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     if ( this.facturas.length > 0 ) {
      this.fileName = this.facturas[0].payment_report_folio + '.xlsx';
     }
     XLSX.writeFile(wb, this.fileName);  */
     //console.log(this.facturas)
     //let numberrow = 2;
     this.fileName = this.facturas[0].clave_rastreo + '.xlsx';
     const Excel = require('exceljs');
     let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('stp');
    let header=['INSTITUCION_CONTRAPARTE','CLAVE_RASTREO','NOMBRE_BENEFICIARIO', 'RFC_CURP_BENEFICIARIO', 'TIPO_PAGO', 'TIPO_CUENTA_BENEFICIARIO', 'MONTO', 'CUENTA_BENEFICIARIO', 'CONCEPTO_PAGO', 'REFERENCIA_NUMERICA', 'INSTITUCION_OPERANTE', 'EMPRESA'];
    worksheet.addRow(header);
    for (const prop in this.facturas) {
      /*let fechaaplicacion = this.facturas[prop].fecha_aplicacion;
      console.log(fechaaplicacion)
      let fecapli = fechaaplicacion.split('/');
      console.log(fecapli) */
      worksheet.addRow([this.facturas[prop].institucion_contraparte,this.facturas[prop].clave_rastreo,this.facturas[prop].nombre_beneficiario,this.facturas[prop].rfc_curp_beneficiario,
        this.facturas[prop].tipo_pago,this.facturas[prop].tipo_cuenta_beneficiario,this.facturas[prop].monto,(this.facturas[prop].cuenta_beneficiario).toString(),this.facturas[prop].concepto_pago,
        this.facturas[prop].referencia_numerica,this.facturas[prop].institucion_operante,this.facturas[prop].empresa]);
        
        //worksheet.getCell('K' + numberrow).value = new Date(`${fecapli[2]}, ${fecapli[1]}, ${fecapli[0]} GMT-0000`);
        //numberrow = numberrow + 1;
    } 
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: '.xlsx' });
      const file = blob;
      fs.saveAs(blob, this.fileName);
    });
  }


  /*exportpdf() {

   import('jspdf').then( jsPDF => {
    import('jspdf-autotable').then(x => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(this.exportColumns, this.facturas);
        doc.save('ListaFacturas.pdf');
    });
});

  } */

}
