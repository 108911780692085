import { RouterModule, Routes } from '@angular/router';
//import { LoginRGuardGuard } from './services/service.index'
import { PagesComponent } from './pages/pages.component';
import { AuthRedirectGuard } from './services/guards/redirect.guard';
import { LoginComponent } from './login/login.component';
import { LoginBaseComponent } from './loginbase/loginbase.component';
import { LoginRyComponent } from './loginry/loginry.component';
import { LoginTriComponent } from './logintrinitas/logintri.component';
import { LoginFinantahComponent } from './loginfinantah/loginfinantah.component';
import { LoginVentusComponent } from './loginventus/loginventus.component';
import { LoginFliebenCapComponent } from './loginfliebencap/loginfliebencap.component';
import { LoginFliebenTresComponent } from './loginfliebentres/loginfliebentres.component';
import { ResetpwdComponent } from './login/resetpwd.component';
import { ResetpwdBaseComponent } from './loginbase/resetpwdbase.component';
import { ResetpwdryComponent } from './loginry/resetpwdry.component';
import { ResetpwdTriComponent } from './logintrinitas/resetpwdtri.component';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';
import { AccesoDenegadoComponent } from './shared/accesodenegado/accesodenegado.component';


const appRoutes: Routes = [
    { path: 'login', component: LoginComponent, canActivate: [AuthRedirectGuard]},
    { path: 'loginbase', component: LoginBaseComponent, canActivate: [AuthRedirectGuard]},
    { path: 'loginry', component: LoginRyComponent , canActivate: [AuthRedirectGuard]},
    { path: 'logintri', component: LoginTriComponent, canActivate: [AuthRedirectGuard] },
    { path: 'logintah', component: LoginFinantahComponent, canActivate: [AuthRedirectGuard] },
    { path: 'loginven', component: LoginVentusComponent, canActivate: [AuthRedirectGuard] },
    { path: 'loginfbcap', component: LoginFliebenCapComponent, canActivate: [AuthRedirectGuard]},
    { path: 'loginfbtres', component: LoginFliebenTresComponent, canActivate: [AuthRedirectGuard] },
    { path: 'resetpwd/:token', component: ResetpwdComponent },
    { path: 'resetpwdbase/:token', component: ResetpwdBaseComponent },
    { path: 'resetpwdtri/:token', component: ResetpwdTriComponent },
    { path: 'resetpwdry/:token', component: ResetpwdryComponent },
    { path: 'accesodenegado', component: AccesoDenegadoComponent },
    { path: '**', component: NopagefoundComponent }
];


export const APP_ROUTES = RouterModule.forRoot( appRoutes, { useHash: true } );
