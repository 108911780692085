export class FacturaSimulacion {

    constructor(
        public total: string,
        public porcentaje: string,
        public requestdate: string,
        public useddate: string,
        public duedate: string,
        public tmoneda: string,
        public companyid: string,
        public supplierid: string,
        public userid: string,
        public folio?: string,
    ) { }

}

export class Invoices {

    constructor(
     )
     { }

}

