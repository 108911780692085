import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ContribuyentesService } from '../../services/service.index';
import { ActivatedRoute } from '@angular/router';
//Se importa el servicio de emisiones
import { MantContEmit } from '../../services/mantcontemitter/mantcontemiiter.service';
//se importan los componentes hijos
import { DomiciliosComponent } from './domicilios/domicilios.component';
import { FirmantesComponent } from './firmantes/firmantes.component';
import { ActaCComponent } from './actaconstitutiva/actac.component';
import { CapAfiliacionComponent } from './capafiliacion/capafiliacion.component';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-mantcontmain',
  templateUrl: './mantcontmain.component.html',
  styleUrls: ['./mantcontmain.component.css']
})
export class MantContMainComponent implements OnInit {
  //para estar viendo la los componentes hijos
  @ViewChild(DomiciliosComponent, {static: false}) domiciliosComponent: DomiciliosComponent;
  @ViewChild(FirmantesComponent, {static: false}) firmantesComponent: FirmantesComponent;
  @ViewChild(ActaCComponent, {static: false}) actaconstComponent: ActaCComponent;
  @ViewChild(CapAfiliacionComponent, {static: false}) capafiliacionComponent: CapAfiliacionComponent;
  @Input() eventEmitter: EventEmitter<Object>;
  @Output() childEvent = new EventEmitter();
  contribuyenteEmitter = new EventEmitter<Object>(); //declaro el emisor
  escriturasEmitter = new EventEmitter<Object>(); //declaro el emisor
  afiliacionEmitter = new EventEmitter<Object>(); //declaro el emisor
  idc;
  clk = false;
  nombcont = '';
  //booleanos para controlar la visibilidad de las pestañas
  fir: boolean;
  domi: boolean;
  accons: boolean;
  capaf: boolean;
  muestroTabs: boolean;
  constructor( public route: ActivatedRoute,
               private eventService: MantContEmit,
               private _contribuyentesService: ContribuyentesService,
                ) {swal2.fire('espere, por favor');
                swal2.showLoading();
                 }

                

  ngOnInit() {
    this.subscribeToParentEmitter();
  }

  subscribeToParentEmitter(): void { 
    this.eventEmitter.subscribe((d: { contid: any; nomcont: string; }) => {
      swal2.showLoading();
      this.muestroTabs = true;
      this.fir = false;
      this.domi = false;
      this.accons = false;
      this.capaf = false;
      this.clk = false;
      this.idc = d.contid;
      this.nombcont = d.nomcont;
      //aqui tengo que ver si su info esta, esta incompleta o esta completa
      this._contribuyentesService.getContributorDetails(d.contid).subscribe(r => {
      if (r.data) {
        const esValido = this.validarDatos(r);
       // if(r.data.length > 0 && (r.data[0].reca_date !== 'N/A' && r.data[0].reca_date !== undefined && r.data[0].reca_date !== null && r.data[0].reca_date !== '')) {
       if (esValido) {
          this.muestroTabs = false;
        } else {
          console.log('');
        }
      }
    }, (err) => {
        this.muestroTabs = true;
    })
    setTimeout(() => {
      const checkele = document.getElementById("one") as HTMLInputElement;
      checkele.checked = true;
      this.handleClickNumber(0);

    }, 1000);
    })
  }

  // funcion para checar que todos los campos de la caratula vengan llenos
   validarDatos(r) {
    // Campos específicos que deseas evaluar
    const fieldsToCheck = [
        'cat',
        'term',
        'payment_due_date',
        'cutoff_date',
        'opening_commission',
        'reca',
        'reca_date',
        'anticipate_payment_commission',
        'tiie',
        'credit_line_amount',
        'payment_total'
    ];

    // Verificar si todos los campos específicos son diferentes de 'N/A', undefined, null, o ''
    let isValid = true;
    let encontradoInvalido = false;
    fieldsToCheck.forEach(field => {
      if (encontradoInvalido) return;
        const value = r.data[0][field];
        if (value === 'N/A' || value === undefined || value === null || value === '') {
            isValid = false;
            encontradoInvalido = true; // 
        }
    });

    return isValid;
}
  //funcion para crear o destruir los componentes
  handleClickNumber(tab: number) {
    switch (tab) {
      case 0:
        this.fir = false;
       this.domi = false;
       this.accons = false;
       this.capaf = false;
        this.contribuyenteEmitter.emit({idc: this.idc});
        break
      case 1:
        this.escriturasEmitter.emit({idc: this.idc});
        break;
      case 2:
       this.fir = true;
       this.domi = false;
       this.accons = false;
       this.capaf = false;
        setTimeout(() => {
          this.checkAndEmitEvent();
        });
        break;
      case 3:
       this.fir = false;
       this.domi = true;
       this.accons = false;
       this.capaf = false;
       setTimeout(() => {
        this.checkAndEmitEvent();
      });
        break;
      case 4:
      this.fir = false;
       this.domi = false;
       this.accons = true;
       this.capaf = false;
       setTimeout(() => {
        this.checkAndEmitEvent();
      });
        break;
      case 5:
        this.fir = false;
       this.domi = false;
       this.accons = false;
       this.capaf = false;
        this.afiliacionEmitter.emit({idc: this.idc});
        break;
      case 6:
       this.fir = false;
       this.domi = false;
       this.accons = false;
       this.capaf = true;
       setTimeout(() => {
        this.checkAndEmitEvent();
      });
        break;
      default:
        //Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
        break;
    }
  }
  //funcion para emitir los eventos dependiendo de a que se le dio click
  checkAndEmitEvent() {
    if (this.domiciliosComponent && this.domi) {
      this.eventService.domiciliosEmitter.emit({idc: this.idc});
    } else if (this.firmantesComponent && this.fir) {
      this.eventService.firmantesEmitter.emit({idc: this.idc});
    } else if (this.actaconstComponent && this.accons) {
      this.eventService.actacEmitter.emit({idc: this.idc});
    } else if (this.capafiliacionComponent && this.capaf) {
      this.eventService.capafiliacionEmitter.emit({idc: this.idc});
    }
  }
  //funcion que recibe evento de hijo para poner a pantalla completa las pestañas o no
  cch() {
    const panelElement = document.getElementById('myPanel');
    this.clk = !this.clk;
    if (this.clk) {
      panelElement.classList.add('panels-modified-height');
    } else {
      panelElement.classList.remove('panels-modified-height');
    }
    this.childEvent.emit({clk: this.clk})
  }
//recibo evento de capafiliacion y emito evento a mantcont para disparar la consulta y logica de pestañas
  vdcapafiliacion(event) {
    const panelElement = document.getElementById('myPanel');
    if (panelElement.classList.contains('panels-modified-height')) {
      panelElement.classList.remove('panels-modified-height');
    }
   this.childEvent.emit({clk: event.clk, check: event.check, nombcont: this.nombcont});
  }

}
