import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NotionService } from 'src/app/services/service.index';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-ayuda',
  templateUrl: './ayuda.component.html',
  styleUrls: ['./ayuda.component.css']
})
export class AyudaComponent implements OnInit {
  incidents: any[] = [];
  expandedIncidentId: string | null = null;
  selectedIncident: any = null;
  newComment: { content: string } = { content: '' };
  searchTerm: string = '';
  constructor(
              private notion: NotionService
  ) {}

  ngOnInit() {}

  tabcrea() {
    this.incidents = [];
  }

  onSubmit(form: any) {
    Swal.fire({
      title: `Enviando ticket`,
      text: 'Por favor, espere...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
      const incidentData = {
        titulo: form.value.title,
        cliente: localStorage.getItem('usuario') ? localStorage.getItem('usuario').replace(/['"]/g, '').toUpperCase(): 'NO PRESENTE',
        descripcion: form.value.description,
        caso: form.value.case,
        pasos: form.value.steps,
        prioridad: form.value.priority,
        notas: form.value.notes,
        usuario: localStorage.getItem('emailuser') || '',
        empresa: (localStorage.getItem('quiensoy') ? localStorage.getItem('quiensoy').toUpperCase() : ''),
        sistema: "Factoraje"
      };
      this.notion.guardaIncidencia(incidentData).subscribe(() => {
        Swal.fire({
          title: 'Éxito',
          text: 'Ticket enviado correctamente',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result) {
            location.reload(); // Refresca la pantalla
          }
        });
      }, (err => {
        Swal.fire('Ocurrio un error', err.error.error, 'info');
      }));
  }

  modIncident(data: { id: any; }) {
    Swal.fire({
      title: `Modificando ticket`,
      text: 'Por favor, espere...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
    if (data.id) {
      this.notion.modifIncidencia(data).subscribe(() => {
        Swal.fire('Modificacion de ticket correcta', '', 'info')
      }, (err) => {
        Swal.fire('Ocurrio un error al modificar el incidente', err.error.error, 'info');
      })
    } 
  }

  vertickets() {
    Swal.fire({
      title: `Cargando tickets`,
      text: 'Por favor, espere...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
    this.expandedIncidentId = null;
    const params = new HttpParams()
      .set('usuario', localStorage.getItem('emailuser') || '')
      .set('empresa', localStorage.getItem('quiensoy') ? localStorage.getItem('quiensoy').toUpperCase() : '');
    this.notion.obtenerIncidencias(params).subscribe(data => {
      this.incidents = data;
      Swal.close();
    }, (err) => {
      Swal.fire('Ocurrio un error al cargar los tickets', err.error.error, 'info');
    })
  }

  toggleExpand(id: string): void {
    if (this.expandedIncidentId === id) {
      this.expandedIncidentId = null;
      this.selectedIncident = null;
    } else {
      this.loadIncidentDetails(id);
    }
  }

  loadIncidentDetails(id: string): void {
    Swal.fire({
      title: `Cargando datos del ticket`,
      text: 'Por favor, espere...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });

    this.notion.obtenerDetalleIncidencia(id).subscribe(data => {
      if (data.ComentariosPaginas.length > 0) {
        data.ComentariosPaginas.forEach((element: { user_name: string; }) => {
          if (element.user_name === 'Incidencias') {
            element.user_name = localStorage.getItem('emailuser') || ''
          }
        });
      }
      this.selectedIncident = data;
      this.expandedIncidentId = id;
      Swal.close(); // Cierra el spinner de carga
    }, (err => {
      Swal.fire('Ocurrio un error al obtener el detalle', err.error.error, 'info');
      Swal.close();
    }));
  }

  addComment(incidentId: string, form: NgForm): void {
    Swal.fire({
      title: `Agregando comentario`,
      text: 'Por favor, espere...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });

    if (form.valid) {
      const commentData = {
        content: this.newComment.content,
        author: localStorage.getItem('emailuser') || 'Anonymous'
      };

      this.notion.agregaComentario(incidentId, commentData).subscribe(() => {
        this.newComment.content = '';
        this.loadIncidentDetails(incidentId); // Refresca los detalles del incidente
      }, (err) => {
        Swal.fire('Ocurrio un error al enviar el comentario', err.error.error, 'info');
        Swal.close();
      });
    }
  }

    openFile(url: string): void {
      window.open(url, '_blank');
  }

  myFunction() {
    var input: any, filter: any, table: HTMLElement, tr: string | any[] | HTMLCollectionOf<HTMLTableRowElement>, td: HTMLTableRowElement, i: number, txtValue: string;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("myTable");
    tr = table.getElementsByTagName("tr");
    for (i = 1; i < tr.length; i++) {
    td = tr[i];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
  }
}


