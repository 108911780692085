import { Component, OnInit, Input } from '@angular/core';
import { ReportesService, OptionsService, FundersService } from '../../services/service.index';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { catchError } from 'rxjs/operators';
import { saveAs } from 'file-saver';
declare var $;


@Component({
  selector: 'app-reportesolicitudesfondeo',
  templateUrl: './reportesolicitudesfondeo.component.html',
  styleUrls: ['./reportesolicitudesfondeo.component.css']
})
export class ReporteSolicitudesFondeoComponent implements OnInit {

  constructor(public _reportesservice: ReportesService,
    public router: Router,
    public _optionsservice: OptionsService,
    public http: HttpClient,
    private fundersService: FundersService) { }

  token = localStorage.getItem('token');
  doc = new jsPDF();
  facturas: any[] = [];
  usuario: string;
  cols: any[];
  colspdf: any[];
  selectedFac: any[];
  // router: Router;
  fileName = 'ListaDeSolicitudesFondeo.xlsx';
  selectedColumnsp: any[];
  selectedColumnspdf: any[];
  exportColumns: any[];
  showmodalds: boolean;
  _selectedColumns: any[];
  datosdetalles: any[] = [];
  //NuevaTabla ///
  sortColumn: string = ''; // Columna actual de ordenación
  sortDirection: 'asc' | 'desc' = 'asc'; // Dirección de ordenación
  bancoppeldash = false;
  SolFonHisEssol: any[] = [];
  SolFonHisEsDifsol: any[] = [];
  ClienxCadena: any[] = [];
  items: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 10;
  searchTerm: string = '';
  maxVisiblePages: number = 8; // Número máximo de páginas visibles
  searchTerms = {
    folio_solicitud_fondeo: '',
    cadena:'',
    fondedor:'',
    fecha_solicitud:'',
    moneda:'',
    total:'',
    total_operado:'',
    intereses:'',
    monto_neto:'',
    usuario: ''
    };
    selectedRows = new Set<any>(); // Almacena las filas seleccionadas
    allData = []; // Todos los datos disponibles (incluidos los de otras páginas).
    selectedIdsf: string | null = null;
    /////////////
  ngOnInit() {

    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
    });
    swal2.showLoading();
    this._reportesservice.getReporteSolicitudesFondeo().subscribe(resp => {
      this.facturas = resp;
      swal2.close();
    });

    this.cols = [

      //  { field:  'id_factura', header: 'ID'},
      { field: 'folio_solicitud_fondeo', header: 'Folio Solicitud Fondeo' },
      { field: 'cadena', header: 'Empresa' },
      { field: 'fondedor', header: 'Fondeador' },
      { field: 'fecha_solicitud', header: 'Fecha Solicitud' },
      { field: 'moneda', header: 'Moneda' },
      { field: 'total', header: 'Total' },
      { field: 'total_operado', header: 'Total Operado' },
      { field: 'intereses', header: 'Intereses' },
      { field: 'monto_neto', header: 'Monto Neto' },
      { field: 'usuario', header: 'Usuario' },
    ];

    this._selectedColumns = this.cols;
    this.colspdf = [

      //  { field:  'id_factura', header: 'ID'},
      { field: 'folio_solicitud_fondeo', header: 'Folio Solicitud Fondeo' },
      { field: 'cadena', header: 'Empresa' },
      { field: 'fondeador', header: 'Fondeador' },
      { field: 'fecha_solicitud', header: 'Fecha Solicitud' },
      { field: 'moneda', header: 'Moneda' },
      { field: 'total', header: 'Total' },
      { field: 'total_operado', header: 'Total Operado' },
      { field: 'intereses', header: 'Intereses' },
      { field: 'monto_neto', header: 'Monto Neto' },
      { field: 'usuario', header: 'Usuario' },
    ];
    this.selectedColumnsp = this.cols;
    this.exportColumns = this.colspdf.map(col => ({ title: col.header, dataKey: col.field }));

  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  hideds() {
    this.showmodalds = false;
  }

  set selectedColumns(val: any[]) {
    // restore original order
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }

  reporfac(idsf) {
    this.fundersService.getfundingrequest(idsf).subscribe(resp => {
      this.selectedIdsf = idsf;
      this.datosdetalles = resp['data'].relations.funding_request_invoices;
      this.showmodalds = true;
    })
  }

  delsolfon(idsf) {
    swal2.fire({
      title: 'Desea eliminar la solicitud de fondeo',
      text: '?',
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false
    }).then(resp => {
      if (resp.value) {
        swal2.showLoading();
        this._reportesservice.borraSolicitudFondeo(idsf).pipe(catchError(() => {
           return  swal2.fire('Ocurrio un error', 'al borrar la solicitud', 'error');
        })).subscribe(resp => {
          swal2.fire({
            title: 'Solicitud eliminada correctamente',
            text: '',
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }).then(r => {
            if (r.value) {
              window.location.reload();
            }
          })
        })

      }
    });
  }

  exportexcel() {
    const readOpts = {
      cellText: false,
      cellDates: true,
    };
    /* table id is passed over here */
    const element = document.getElementById('tablaFacturas');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, readOpts);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, this.fileName, readOpts);
  }


  exportpdf() {

    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(this.exportColumns, this.facturas);
        doc.save('ListaFacturas.pdf');
      });
    });

  }

  askResend(rowData) {
    swal2.fire({
      title: '¿Desea reenviar el correo?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.fundersService.getEnviaMail(rowData.id_solicitud_fondeo).subscribe(resp => {
          console.log(resp)
          swal2.fire('Éxito', resp.message, 'success');
        }, err => {
          console.log(err);
          swal2.fire('Atención', 'Algo salió mal', 'info');
        })
      }
    })
  }

  //Nueva tabla//
  pgone() {
    this.currentPage = 1;
  }
  
  get filteredDataq(): any[] {
    const filtered = this.facturas.filter(item => //return
      (!this.searchTerms.folio_solicitud_fondeo || (item.folio_solicitud_fondeo && item.folio_solicitud_fondeo.toLowerCase().includes(this.searchTerms.folio_solicitud_fondeo.toLowerCase()))) &&
      (!this.searchTerms.cadena || (item.cadena && item.cadena.toLowerCase().includes(this.searchTerms.cadena.toLowerCase()))) &&
      (!this.searchTerms.fondedor || (item.fondedor && item.fondedor.toLowerCase().includes(this.searchTerms.fondedor.toLowerCase()))) &&
      (!this.searchTerms.fecha_solicitud || (item.fecha_solicitud && item.fecha_solicitud.toLowerCase().includes(this.searchTerms.fecha_solicitud.toLowerCase()))) &&
      (!this.searchTerms.moneda || (item.moneda && item.moneda.toString().toLowerCase().includes(this.searchTerms.moneda.toLowerCase()))) &&
      (!this.searchTerms.total || (item.total && item.total.toString().toLowerCase().includes(this.searchTerms.total.toLowerCase()))) &&
      (!this.searchTerms.total_operado || (item.total_operado && item.total_operado.toString().toLowerCase().includes(this.searchTerms.total_operado.toLowerCase()))) &&
      (!this.searchTerms.intereses || (item.intereses && item.intereses.toString().toLowerCase().includes(this.searchTerms.intereses.toLowerCase()))) &&
      (!this.searchTerms.monto_neto || (item.monto_neto && item.monto_neto.toString().toLowerCase().includes(this.searchTerms.monto_neto.toLowerCase()))) &&
      (!this.searchTerms.usuario || (item.usuario && item.usuario.toString().toLowerCase().includes(this.searchTerms.usuario.toLowerCase())))
    );
    return filtered.sort((a, b) => this.compare(a, b, this.sortColumn, this.sortDirection));
  }
  
  get dataToShow(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.filteredDataq.slice(startIndex, startIndex + this.itemsPerPage);
  }
  
  get totalPages(): number {
    return Math.ceil(this.filteredDataq.length / this.itemsPerPage);
  }
  
  get pages(): number[] {
    const totalPages = this.totalPages;
  
    if (totalPages <= this.maxVisiblePages) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }
  
    const pages = [];
    const halfRange = Math.floor(this.maxVisiblePages / 2);
  
    let startPage = Math.max(1, this.currentPage - halfRange);
    let endPage = Math.min(totalPages, startPage + this.maxVisiblePages - 1);
  
    if (endPage === totalPages) {
      startPage = totalPages - this.maxVisiblePages + 1;
    }
    if (startPage === 1) {
      endPage = this.maxVisiblePages;
    }
  
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
  
    return pages;
  }
  
  // Métodos de navegación
  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }
  
  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
  
  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }
  
  goToEllipsisPage(position: 'first' | 'last'): void {
    if (position === 'first') {
      this.currentPage = 1;
    } else if (position === 'last') {
      this.currentPage = this.totalPages;
    }
  }
  
  exportExcelFil(tab: string) {
    let fullData: any[]
    fullData = this.filteredDataq;
    const readOpts = {
        cellText: false, 
        cellDates: true,
    };
   // Generar los datos personalizados para exportación
   const dataToExport = fullData.map(item => ({
    'Folio Solicitud Fondeo': item.folio_solicitud_fondeo || '',
    'Empresa': item.cadena || '',
    'Fondeador': item.fondedor || '',
    'Fecha Solicitud': item.fecha_solicitud || '',
    'Moneda': item.moneda || '',
    'Total': item.total || '',
    'Total Operado': item.total_operado || '',
    'Intereses': item.intereses || '',
    'Monto Neto': item.monto_neto || '',
    'Usuario': item.usuario || ''
  }));
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport, readOpts);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'solicitudes.xlsx', readOpts);
  }
  
   formatFecha(fecha: { split: (arg0: string) => [any, any, any]; }) {
    if (!fecha) return '';
    const [year, month, day] = fecha.split('-');
    return `${day}/${month}/${year}`;
  }
  
  removeCommas(monto: { toString: () => string; }) {
    if (!monto) return '';
    return monto.toString().replace(/,/g, ''); // Elimina todas las comas
  }
  
  /*reporfac(idsf: any) {
    this.fundersService.getfundingrequest(idsf).subscribe(resp => {
      this.datosdetalles = resp['data'].relations.funding_request_invoices;
      this.showmodalds = true;
    })
  }
  
  hideds() {
    this.showmodalds = false;
  }*/
  
  exportExcelcxc() {
    let fileName = 'Data.xlsx';
    /* table id is passed over here */
    const element = document.getElementById('CXC');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, fileName);
  }
  
  sortData(column: string): void {
    // Cambia la dirección de ordenación si se selecciona la misma columna, de lo contrario reinicia a ascendente
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
  }
  
  private compare(a: any, b: any, column: string, direction: 'asc' | 'desc'): number {
    if (!column) return 0;
  
    let valueA = a[column];
    let valueB = b[column];
  
    // Lógica personalizada para la columna de íconos
    if (column === 'statusIcon') {
      valueA = a.status === 'ESPERA FIRMA DIGITAL' ? 0 : 1; // Prioridad para mdi-close-circle
      valueB = b.status === 'ESPERA FIRMA DIGITAL' ? 0 : 1;
    }
  
    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
  
    return direction === 'asc' ? comparison : -comparison;
  }
  
  //seleccionar filas//
  toggleRowSelection(row: any): void {
    if (this.selectedRows.has(row)) {
      this.selectedRows.delete(row);
    } else {
      this.selectedRows.add(row);
    }
  }
  
  // Devuelve true si la fila está seleccionada.
  isRowSelected(row: any): boolean {
    return this.selectedRows.has(row);
  }
  
  // Selecciona todas las filas.
  
  selectAllRows(): void {
    if (this.selectedRows.size === this.filteredDataq.length) {
      // Si ya están todos seleccionados, desmarcarlos
      this.selectedRows.clear();
    } else {
      // Si no, seleccionar todos
      this.allData = this.filteredDataq; // Sincronizar los datos
      this.allData.forEach(row => this.selectedRows.add(row));
    }
  }
  
  get isAllSelected(): boolean {
    return this.selectedRows.size === this.filteredDataq.length;
  }

  exportexcelN() {
    if (this.selectedIdsf) {
      this.fundersService.getfundersrequestinvoicesdetails(this.selectedIdsf).subscribe(response => {
        let datos = response;
        this.generateExcel(datos);
      });
    }
  }

  generateExcel(data: any[]) {
    // Crear encabezados agrupados según el formato del Excel
    const headers = [
      [
        "DATOS DE LOS DOCUMENTOS", "", "", "", "", "","","","","","",
        "SOLICITUD DE FACTORAJE", "","","","","","",
        "SOLICITUD DE FONDEO", "","","","","","",
        "REBATE", "", "", "", "","",""
      ],
      [
        "Folio Letra", "Folio solicitud", "Folio Solicitud Fondeo", "Emisor", "Receptor", "Moneda", "Fecha de operación", "Fecha de cobranza","Fecha de vencimiento","Fecha de carga","Estatus",
        "Tasa interbancaria",	"Sobretasa",	"Tasa total",	"Días descuento",	"Total operado",	"Intereses",	"Monto neto",
        "Tasa interbancaria",	"Sobretasa",	"Tasa total",	"Días descuento",	"Total operado",	"Intereses",	"Monto neto",
        "Total operado",	"Días descuento",	"Fee Factor",	"Tasa rebate",	"Rebate total",	"Rebate cadena",	"Rebate fondeador"
      ]
    ];

    // Transformar datos en formato para incluir en el Excel
    const rows = data.map(item => [
      item.folio_letra,
      item.folio_solicitud,
      item.folio_solicitud_fondeo,
      item.emisor,
      item.receptor,
      item.moneda,
      item.fecha_operacion,
      item.fecha_cobranza,
      item.fecha_vencimiento,
      item.fecha_carga,
      item.estatus,
      item.tasa_interbancaria,
      item.sobre_tasa,
      item.tasa_total,
      item.dias_descuento,
      item.total_operado,
      item.intereses,
      item.monto_neto,
      item.fr_tasa_interbancaria,
      item.fr_sobre_tasa,
      item.fr_tasa_total,
      item.fr_dias_descuento,
      item.fr_total_operado,
      item.fr_intereses,
      item.fr_monto_neto,
      item.fri_rebate_total_operado,
      item.fri_rebate_dias_descuento,
      item.fee_factor,
      item.tasa_rebate,
      item.rebate_total,
      item.rebate_cadena,
      item.rebate_fondeador
    ]);

    // Combinar encabezados y datos
    const worksheetData = headers.concat(rows);

    // Crear la hoja de cálculo
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Ajustar los estilos de los encabezados agrupados
    worksheet['!merges'] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }, // "Datos de las Letras de Cambio"
      { s: { r: 0, c: 11 }, e: { r: 0, c: 17 } }, // "Solicitud de Factoraje"
      { s: { r: 0, c: 18 }, e: { r: 0, c: 24 } }, // "Solicitud de Fondeo"
      { s: { r: 0, c: 25 }, e: { r: 0, c: 31 } }, // "Rebate"
    ];

    // Crear el libro de Excel
    const workbook: XLSX.WorkBook = {
      SheetNames: ['Detalle'],
      Sheets: { Detalle: worksheet }
    };

    // Exportar el archivo
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Guardar el archivo usando FileSaver
    const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(dataBlob, 'Detalle_Operaciones.xlsx');
  }
}
  /////////////////////////////


