import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, ViewEncapsulation, Output } from '@angular/core';
import { UserOptionsService, UsuarioService } from '../../services/service.index';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Idd } from '../../models/usuario.model';
import swal2 from 'sweetalert2';
declare var $;

@Component({
  selector: 'app-asignaoptions',
  templateUrl: './asignaoptions.component.html',
  styleUrls: ['./useroptions.component.css']
})
export class AsignaOptionsComponent implements OnInit {
  @Input() eventEmitter: EventEmitter<Object>;
  @Output() childEvent = new EventEmitter();
  optionsfiltradas: any[] = [];
  options: any[] = [];
  datosTabla: Observable<any[]> = new Observable();
  sourceCars: any[];
  usuarioOptions: any[] = [];
  cols: any[];
  selectedColumns: any[];
  seleccion: Idd;
  selecciona: Idd;
  colso: any[];
  resul: any[] = [];
  agregar = false;
  seleccionOptions: any[] = [];
  idu: string;
  nombreUsuaOp: string;
  subscription: Subscription;
  itemsSeleccionados: any[] = [];
  itemsSeleccionadosop: any[] = [];
  clk = true;
  constructor( public _optionsservice: UserOptionsService,
               public _usuariosservice: UsuarioService,
               public router: Router,
               public http: HttpClient,
               private route: ActivatedRoute ) {

                }

  ngOnInit() {
    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
 });
    swal2.showLoading();
    this.subscribeToParentEmitter();
    this.cols = [

      { field: 'name', header: 'Opciones Disponibles' }
  ];

    this.colso = [

    { field: 'name', header: 'Opciones Asignadas al Usuario' }
];

  }

  subscribeToParentEmitter(): void { 
    this.eventEmitter.subscribe((d: { contid: string; nomcont: string; }) => {
    (document.getElementById("myInput") as HTMLInputElement).value = '';
    this.myFunction();
    (document.getElementById("myInput2") as HTMLInputElement).value = '';
    this.myFunction2();
    this.usuarioOptions = [];
    this.options = [];
    this.seleccionOptions = [];
    this.idu = d.contid
    this.nombreUsuaOp = d.nomcont
    //this._optionsservice.getUsuario(this.idu).subscribe( (resp: string) =>  this.nombreUsuaOp = resp  );
    this.subscription = this._optionsservice.getOptions()
    .subscribe( resp => { this.options = resp; // console.log(this.options);
                          this._usuariosservice.getUsuarioOptions(this.idu)
                          .subscribe( resp2 => {this.usuarioOptions = resp2; // console.log(this.usuarioOptions);
                                                if (this.usuarioOptions.length === 0) {
                                                  this.seleccionOptions = this.options;
                                                } else {
                                                // tslint:disable-next-line: forin
                                                for (const i in this.options) {
                                                  // tslint:disable-next-line: forin
                                                for (const j in this.usuarioOptions) {
                                                  if ( parseInt(this.options[i].id, 10) === parseInt(this.usuarioOptions[j].option_id, 10) ) {
                                                    this.usuarioOptions[j].name = this.options[i].group + ' / ' + this.usuarioOptions[j].name;
                                                    this.agregar = false;
                                                    break;
                                                  } else {
                                                    this.agregar = true;

                                                  }
                                                                                     }
                                                if (this.agregar) {
                                                  this.seleccionOptions.push(this.options[i]);
                                                  this.agregar = false;
                                                }
                                                                                }
                                                        }
                                                        // tslint:disable-next-line: forin
                                                for (const prop in this.seleccionOptions) {
                                                          this.seleccionOptions[prop].name = this.seleccionOptions[prop].group + ' / ' + this.seleccionOptions[prop].name;
                                                        }
                                                    });
                                                swal2.close();
                          } );
    })
  }
  

  regreso() {
    this._optionsservice.getOptions().subscribe( resp => { this.options = resp; return this} );
  }

  agregaOption(  ) {
    //this._optionsservice.agregaOption( this.idu, ido ).subscribe();
    console.log(this.itemsSeleccionados);
    setTimeout(() => {
      this.refresh();
    }, 100);


  }

  quitarOption( idq: string ) {
    this._optionsservice.quitarOption(idq).subscribe();

    setTimeout(() => {
      this.refresh();
    }, 100);

  }

  refresh() {

    this.agoq();
  // window.location.reload();
  }

  agoq() {
    (document.getElementById("myInput") as HTMLInputElement).value = '';
    this.myFunction();
    (document.getElementById("myInput2") as HTMLInputElement).value = '';
    this.myFunction2();
    this.usuarioOptions = [];
    this.options = [];
    this.seleccionOptions = [];
    //this._optionsservice.getUsuario(this.idu).subscribe( (resp: string) =>  this.nombreUsuaOp = resp  );
    this.subscription = this._optionsservice.getOptions()
    .subscribe( resp => { this.options = resp; // console.log(this.options);
                          this._usuariosservice.getUsuarioOptions(this.idu)
                          .subscribe( resp2 => {this.usuarioOptions = resp2; // console.log(this.usuarioOptions);
                                                if (this.usuarioOptions.length === 0) {
                                                  this.seleccionOptions = this.options;
                                                } else {
                                                // tslint:disable-next-line: forin
                                                for (const i in this.options) {
                                                  // tslint:disable-next-line: forin
                                                for (const j in this.usuarioOptions) {
                                                  if ( parseInt(this.options[i].id, 10) === parseInt(this.usuarioOptions[j].option_id, 10) ) {
                                                    this.usuarioOptions[j].name = this.options[i].group + ' / ' + this.usuarioOptions[j].name;
                                                    this.agregar = false;
                                                    break;
                                                  } else {
                                                    this.agregar = true;

                                                  }
                                                                                     }
                                                if (this.agregar) {
                                                  this.seleccionOptions.push(this.options[i]);
                                                  this.agregar = false;
                                                }
                                                                                }
                                                        }
                                                        // tslint:disable-next-line: forin
                                                for (const prop in this.seleccionOptions) {
                                                          this.seleccionOptions[prop].name = this.seleccionOptions[prop].group + ' / ' + this.seleccionOptions[prop].name;
                                                        }
                                                    });
                                                swal2.close();
                          } );
  }

  toggleSelection(item: any) {
    const index = this.itemsSeleccionados.findIndex(i => i.id === item.id);
    if (index !== -1) {
      this.itemsSeleccionados.splice(index, 1);
    } else {
      this.itemsSeleccionados.push(item);
    }
  }

  isSelected(item: any): boolean {
    return this.itemsSeleccionados.some(i => i.id === item.id);
  }

  toggleSelectionop(item: any) {
    const index = this.itemsSeleccionadosop.findIndex(i => i.id === item.id);
    if (index !== -1) {
      this.itemsSeleccionadosop.splice(index, 1);
    } else {
      this.itemsSeleccionadosop.push(item);
    }
  }

  isSelectedop(item: any): boolean {
    return this.itemsSeleccionadosop.some(i => i.id === item.id);
  }

  ejecutarAcciones() {
    const observables = this.itemsSeleccionados.map(item =>
      this._optionsservice.agregaOption( this.idu, item.id )
    );

    forkJoin(observables).subscribe(
      () => {
        this.itemsSeleccionados = [];
        this.agoq();
      },
      error => {
        console.error('Error al llamar al servicio:', error);
      }
    );
  }

  ejecutarAccionesop() {
    const observables = this.itemsSeleccionadosop.map(item =>
      this._optionsservice.quitarOption( item.id )
    );

    forkJoin(observables).subscribe(
      () => {
        this.itemsSeleccionadosop = [];
        this.agoq();
      },
      error => {
        console.error('Error al llamar al servicio:', error);
      }
    );
  }

  cch() {
    this.clk = !this.clk;
    (document.getElementById("myInput") as HTMLInputElement).value = '';
    this.myFunction();
    (document.getElementById("myInput2") as HTMLInputElement).value = '';
    this.myFunction2();
    this.childEvent.emit({clk: this.clk})
  }

  myFunction() {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("myTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
    td = tr[i];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
  }

  myFunction2() {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInput2");
    filter = input.value.toUpperCase();
    table = document.getElementById("myTable2");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
    td = tr[i];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
  }

}







