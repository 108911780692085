// AMBIENTE DEV desarrollo
// ng serve --configuration=dev
// ng build --configuration=dev
export const environment = {
  production: true,
  Ambiente: 'fliebencaptest',
  // para mi fiel /////
  APP_ID: '',
  APP_SECRET: '',
  URL_MIFIEL: '',
  // para mi fiel /////
  URL_SERVICIOS: 'https://testfliebencap.factoringdev.com/api/v1',
  SECRET_KEY: '5478d5023bd81ec9e303028d1a122644',
  CLIENTE: 'FACTORGFCGLOBAL',
  // firebase del proyecto de flieben
  firebase: {
    apiKey: "AIzaSyAH-0mX8StH3WJQSSSGs8vEDJp7gZDHctw",
    authDomain: "fliebencapitaltest.firebaseapp.com",
    projectId: "fliebencapitaltest",
    storageBucket: "fliebencapitaltest.appspot.com",
    messagingSenderId: "640075615337",
    appId: "1:640075615337:web:8cf8d8d7915f10b3ab66f8",
    measurementId: "G-E6EVNHXYJZ"
  },
  AWS: {
    accessKeyId: "",
    secretAccessKey: "",
    region: "",
    folder: "",
    bucketNomina: "",
  },

};
