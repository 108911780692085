import { Component, OnInit } from '@angular/core';
import { mergeMap } from 'rxjs-compat/operator/mergeMap';
import { retryWhen } from 'rxjs-compat/operator/retryWhen';
import { retry } from 'rxjs/operators';
import { FacturasService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-revision-facturas-sat',
  templateUrl: './revision-facturas-sat.component.html',
  styleUrls: ['./revision-facturas-sat.component.css']
})
export class RevisionFacturasSatComponent implements OnInit {
  facturas = [];
  cont = 0;
  cols = [
    {field: "invoice_folio", header: "Folio"},
    {field: "uuid", header: "UUID"},
    {field: "emitter_rfc", header: "RFC emisor"},
    {field: "emisor", header: "Emisor"},
    {field: "receiver_rfc", header: "RFC receptor"},
    {field: "receptor", header: "Receptor"},
    // {field: "used_date", header: "Fecha de operación"},
    {field: "invoice_date", header: "Fecha"},
    {field: "due_date", header: "Fecha de vencimiento"},
    // {field: "entry_date", header: "Fecha de ingreso"},
    {field: "status", header: "Estatus"},
    {field: "total", header: "Total"},
    {field: "currency", header: "Moneda"},
  ];
  colsE = [
    {field: "invoice_folio", header: "Folio"},
    {field: "uuid", header: "UUID"},
    {field: "emitter_rfc", header: "RFC emisor"},
    {field: "emisor", header: "Emisor"},
    {field: "receiver_rfc", header: "RFC receptor"},
    {field: "receptor", header: "Receptor"},
    // {field: "used_date", header: "Fecha de operación"},
    {field: "invoice_date", header: "Fecha"},
    {field: "due_date", header: "Fecha de vencimiento"},
    // {field: "entry_date", header: "Fecha de ingreso"},
    {field: "status", header: "Estatus"},
    {field: "total", header: "Total"},
    {field: "currency", header: "Moneda"},
    {field: "error", header: "Mensaje"},
  ];
  itemsPerPage: number = 10;
  facturasConsultadas = 0;
  
  // VIGENTES
  searchTermVigentes: string = '';
  facturasVigentes = [];
  desplegarVigentes = false;
  currentPageVigentes: number = 1;
  
  // NO ENCONTRADAS
  searchTermNoEncontradas: string = '';
  facturasNoEncontradas = [];
  desplegarNoEncontradas = true;
  currentPageNoEncontradas: number = 1;
  
  // NO CONSULTADAS
  searchTermNoConsultadas: string = '';
  facturasNoConsultadas = [];
  desplegarNoConsultadas = false;
  currentPageNoConsultadas: number = 1;
  
  constructor(private facturasService: FacturasService) { }

  ngOnInit() {
    this.facturasService.getExecutedInvoices().subscribe(resp => {
    this.facturas = resp
    swal2.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      title: `Consultando facturas en el SAT`,
      text: `Por favor, espere...`,
      onOpen: () => {
          swal2.showLoading();
          this.facturas = resp;
          this.facturasNoEncontradas = [];
          this.facturasVigentes = [];
          this.facturas.forEach(async (f, i) => {
            await setTimeout(async() => {
              await this.checkInvoiceSat(f);
            }, i * 800);
          })
      }
    });
    })
  }

  async checkInvoiceSat(invoice){
    var params = {
      emitter_rfc: invoice.emitter_rfc,
      receiver_rfc: invoice.receiver_rfc,
      total: invoice.total,
      uuid: invoice.uuid,
    }
    var i = this.facturasNoConsultadas.findIndex(f => f.id == invoice.id);
    if(i >= 0){
      this.facturasNoConsultadas.splice(i, 1);
    }
    await this.facturasService.checkInvoiceSat(params).subscribe(r => {
      if(r){
        invoice.error = 'Vigente'
        this.facturasVigentes.push(invoice);
        this.totalVerificadas();
      }else{
        invoice.error = 'No encontrada'
        this.facturasNoEncontradas.push(invoice);
        this.totalVerificadas();
      }
    }, err => {
      console.log(err);
      if(err.status == 422){
        var errores = '';
        err.error.datos.forEach(e => {
          errores += e + ', '
        });
        invoice.error = err.error.error + ': '+ errores
      }else if(err.status == 500){
        invoice.error = 'Falla del servicio SAT';
      }else{
        try{
          invoice.error = err.error.error
        }catch{
          invoice.error = 'Falla inesperada';
        }
      }
      this.desplegarNoEncontradas = false;
      this.desplegarNoConsultadas = true;
      this.facturasNoConsultadas.push(invoice);
      this.totalVerificadas();
    })
  }

  totalVerificadas(){
    this.facturasConsultadas = this.facturasVigentes.length + this.facturasNoEncontradas.length + this.facturasNoConsultadas.length;
    if(this.facturasConsultadas == this.facturas.length){
      swal2.close();
      if(this.facturasNoEncontradas.length == 0 && this.facturasNoConsultadas.length == 0){
        this.desplegarVigentes = true;
        this.desplegarNoConsultadas = false;
        this.desplegarNoEncontradas = false
      }else if(this.facturasNoEncontradas.length > 0 && this.facturasNoConsultadas.length == 0){
        this.desplegarVigentes = false;
        this.desplegarNoConsultadas = false;
        this.desplegarNoEncontradas = true
      }
    }
  }

  patchInvoice(invoice, status){
    if(status == 'CANCELADA'){
      var params = {};
      var inv = {status: 'CANCELADA'}

      params['invoice'] = inv;
  
      swal2.fire({
        title: 'Confirmación',
        text: `¿Desea cancelar la factura ${invoice.invoice_folio}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, cancelar',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          /* params['invoices'] = invoices;
          params['company_id'] = invoice.company_id;
          this.facturasService.rejectInvoices(params).subscribe(resp => {
            swal2.fire('Éxito', 'Factura rechazada correctamente', 'info');
            switch(invoice['error']){
              case 'Vigente':
                var i = this.facturasVigentes.findIndex(f => f.id == invoice.id);
                this.facturasVigentes.splice(i, 1);
                break;
              case 'No encontrada':
                var i = this.facturasNoEncontradas.findIndex(f => f.id == invoice.id);
                this.facturasNoEncontradas.splice(i, 1);
                break;
              default:
                var i = this.facturasNoConsultadas.findIndex(f => f.id == invoice.id);
                this.facturasNoConsultadas.splice(i, 1);
                break;
            }
          }, err => {
            console.log(err);
            swal2.fire('Error', 'Contacte al administrador', 'info');
          }); */
          this.facturasService.updateInvoices(invoice.id, params).subscribe(resp => {
            console.log(resp);
            swal2.fire('Éxito', 'Factura actualizada correctamente', 'success');
            switch(invoice['error']){
              case 'Vigente':
                var i = this.facturasVigentes.findIndex(f => f.id == invoice.id);
                this.facturasVigentes.splice(i, 1);
                break;
                case 'No encontrada':
                  var i = this.facturasNoEncontradas.findIndex(f => f.id == invoice.id);
                  this.facturasNoEncontradas.splice(i, 1);
                  break;
                  default:
                    var i = this.facturasNoConsultadas.findIndex(f => f.id == invoice.id);
                    this.facturasNoConsultadas.splice(i, 1);
                    break;
                  }
                }, err => {
                  console.log(err);
                  swal2.fire('Atención', 'Algo salió mal', 'info');
                })
              }
              
            })
        }else{
          swal2.fire('Éxito', 'Factura actualizada correctamente', 'success');
    }
  }

  // VIGENTES
  resetPageVigentes() {
    this.currentPageVigentes = 1;
  }

  get vigentesFiltradas(): any[] {

    return this.facturasVigentes.filter(item =>
      item.invoice_folio.toLowerCase().includes(this.searchTermVigentes.toLowerCase()) ||
      item.uuid.toLowerCase().includes(this.searchTermVigentes.toLowerCase()) ||
      item.emitter_rfc.toLowerCase().includes(this.searchTermVigentes.toLowerCase()) ||
      item.emisor.toLowerCase().includes(this.searchTermVigentes.toLowerCase()) ||
      item.receiver_rfc.toLowerCase().includes(this.searchTermVigentes.toLowerCase()) ||
      item.receptor.toLowerCase().includes(this.searchTermVigentes.toLowerCase()) ||
      item.invoice_date.toLowerCase().includes(this.searchTermVigentes.toLowerCase()) ||
      item.due_date.toLowerCase().includes(this.searchTermVigentes.toLowerCase()) ||
      item.total.toLowerCase().includes(this.searchTermVigentes.toLowerCase()) ||
      item.currency.toLowerCase().includes(this.searchTermVigentes.toLowerCase())
    );
  }
  
  get dataToShowVigentes(): any[] {
    const startIndex = (this.currentPageVigentes - 1) * this.itemsPerPage;
    return this.vigentesFiltradas.slice(startIndex, startIndex + this.itemsPerPage);
  }

  get totalPagesVigentes(): number {
    return Math.ceil(this.vigentesFiltradas.length / this.itemsPerPage);
  }

  get pagesVigentes(): number[] {
    return Array.from({ length: this.totalPagesVigentes }, (_, i) => i + 1);
  }

  goToPageVigentes(page: number): void {
    if (page >= 1 && page <= this.totalPagesVigentes) {
      this.currentPageVigentes = page;
    }
  }

  previousPageVigentes(): void {
    if (this.currentPageVigentes > 1) {
      this.currentPageVigentes--;
    }
  }

  nextPageVigentes(): void {
    if (this.currentPageVigentes < this.totalPagesVigentes) {
      this.currentPageVigentes++;
    }
  }

  // NO ENCONTRADAS
  resetPageNoEncontradas() {
    this.currentPageNoEncontradas = 1;
  }

  get noEncontradasFiltradas(): any[] {

    return this.facturasNoEncontradas.filter(item =>
      item.invoice_folio.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase()) ||
      item.uuid.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase()) ||
      item.emitter_rfc.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase()) ||
      item.emisor.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase()) ||
      item.receiver_rfc.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase()) ||
      item.receptor.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase()) ||
      item.invoice_date.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase()) ||
      item.due_date.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase()) ||
      item.total.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase()) ||
      item.currency.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase())
    );
  }
  
  get dataToShowNoEncontradas(): any[] {
    const startIndex = (this.currentPageNoEncontradas - 1) * this.itemsPerPage;
    return this.noEncontradasFiltradas.slice(startIndex, startIndex + this.itemsPerPage);
  }

  get totalPagesNoEncontradas(): number {
    return Math.ceil(this.noEncontradasFiltradas.length / this.itemsPerPage);
  }

  get pagesNoEncontradas(): number[] {
    return Array.from({ length: this.totalPagesNoEncontradas }, (_, i) => i + 1);
  }

  goToPageNoEncontradas(page: number): void {
    if (page >= 1 && page <= this.totalPagesNoEncontradas) {
      this.currentPageNoEncontradas = page;
    }
  }

  previousPageNoEncontradas(): void {
    if (this.currentPageNoEncontradas > 1) {
      this.currentPageNoEncontradas--;
    }
  }

  nextPageNoEncontradas(): void {
    if (this.currentPageNoEncontradas < this.totalPagesNoEncontradas) {
      this.currentPageNoEncontradas++;
    }
  }
  
  // NO CONSULTADAS
  resetPageNoConsultadas() {
    this.currentPageNoConsultadas = 1;
  }
  
  get noConsultadasFiltradas(): any[] {

    return this.facturasNoConsultadas.filter(item =>
      item.invoice_folio.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase()) ||
      item.uuid.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase()) ||
      item.emitter_rfc.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase()) ||
      item.emisor.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase()) ||
      item.receiver_rfc.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase()) ||
      item.receptor.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase()) ||
      item.invoice_date.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase()) ||
      item.due_date.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase()) ||
      item.total.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase()) ||
      item.currency.toLowerCase().includes(this.searchTermNoEncontradas.toLowerCase())
    );
  }
  
  get dataToShowNoConsultadas(): any[] {
    const startIndex = (this.currentPageNoConsultadas - 1) * this.itemsPerPage;
    return this.noConsultadasFiltradas.slice(startIndex, startIndex + this.itemsPerPage);
  }

  get totalPagesNoConsultadas(): number {
    return Math.ceil(this.noConsultadasFiltradas.length / this.itemsPerPage);
  }

  get pagesNoConsultadas(): number[] {
    return Array.from({ length: this.totalPagesNoConsultadas }, (_, i) => i + 1);
  }

  goToPageNoConsultadas(page: number): void {
    if (page >= 1 && page <= this.totalPagesNoConsultadas) {
      this.currentPageNoConsultadas = page;
    }
  }

  previousPageNoConsultadas(): void {
    if (this.currentPageNoConsultadas > 1) {
      this.currentPageNoConsultadas--;
    }
  }

  nextPageNoConsultadas(): void {
    if (this.currentPageNoConsultadas < this.totalPagesNoConsultadas) {
      this.currentPageNoConsultadas++;
    }
  }

}
