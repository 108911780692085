import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthYearFormat'
})
export class MonthYearFormatPipe implements PipeTransform {

  private monthNames = [
    'ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO',
    'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'
  ];

  transform(value: any): string {
    if (!value) {
      return '';
    }

    let date: Date;

    // Manejar el caso cuando el valor es de tipo Date
    if (value instanceof Date) {
      date = value;
    } 
    // Manejar el caso cuando el valor es una cadena
    else if (typeof value === 'string') {
      // Manejar el formato yyyy-MM-dd
      if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
        const [year, month, day] = value.split('-').map(part => parseInt(part, 10));
        date = new Date(year, month - 1, day);
      } 
      // Manejar el formato yyyy-MM
      else if (/^\d{4}-\d{2}$/.test(value)) {
        const [year, month] = value.split('-').map(part => parseInt(part, 10));
        date = new Date(year, month - 1);
      } 
      // Si no coincide con ninguno de los formatos esperados, devolver el valor original
      else {
        return value;
      }
    } else {
      // Si el tipo no es Date ni string, devolver el valor original
      return value;
    }

    // Formatear la fecha a "MMMM yyyy" en español
    const month = this.monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${year}`;
  }
}


