import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UsuarioService, PrivilegiosUsuariosService, OptionsService, PagosService } from '../../services/service.index';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';
import { forkJoin, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
declare var $: any;
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-usuariosproveedores',
  templateUrl: './usuariosproveedores.component.html',
  styleUrls: ['./usersupliers.component.css']
})
export class UsuariosProveedoresComponent implements OnInit {

  myControl = new FormControl();
  filteredProveedores: Observable<any[]>;
  proveedorControl = new FormControl('');
  usuarios: any[] = [];
  proveedores: any[] = []; // Inicializa proveedores como una lista vacía
  cols: any[];
  selectedFac: any[];
  terminoBusqueda: string = '';
  constructor( public _usuarioservice: UsuarioService,
               public _privilegiosusuarios: PrivilegiosUsuariosService,
               public _optionsservice: OptionsService,
               public router: Router,
               public _pagosservice: PagosService,
               public http: HttpClient) {}

              private _filter(value: string): any[] {
                const filterValue = value.toLowerCase();
                return this.proveedores.filter(proveedor => proveedor.nombre_proveedor.toLowerCase().includes(filterValue));
              }

  

  ngOnInit() {
    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
    });
    swal2.showLoading();
    // Restablecer el filtro a una cadena vacía en ngOnInit
    this.proveedorControl.setValue('');
    const observable1 = this._usuarioservice.getUsuarios();
    const observable2 = this._pagosservice.getProveedores();
    forkJoin([observable1, observable2]).subscribe(async ([usuarios, proveedores]) => {
      await this.getProvedorUsuario(usuarios);
      for (const prep in usuarios) {
        let proveedorEncontrado = false; // Bandera para verificar si se encontró un proveedor para el usuario actual
        for (const prop in proveedores) {
          if (proveedores[prop].id_proveedor === usuarios[prep].suplierid) {
            usuarios[prep].nambreProv = proveedores[prop].nombre_proveedor;
            proveedorEncontrado = true;
            break; // Salir del bucle interno una vez que se encuentre un proveedor para el usuario
          }
        }
        // Si no se encontró ningún proveedor para el usuario, establecer nambreProv en null
        if (!proveedorEncontrado) {
          usuarios[prep].nambreProv = null;
        }
      }
      this.usuarios = usuarios;
      this.proveedores = proveedores;
      this.filteredProveedores = this.proveedorControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.nombre_proveedor),
        map(name => name ? this._filter(name) : this.proveedores.slice())
      );
      setTimeout(() => {
        swal2.close();
      }, 3);
      
    }, (err) => {
      swal2.fire({
        title: 'Atención', 
        text: 'Algo salió mal, contacte al administrador', 
        icon: 'info',
        showConfirmButton: true,
        allowOutsideClick: false
      }).then(res => {
        if(res.value){
          // this.ngOnInit();
          swal2.close();
        }
      });
    });

  }

  displayFn(proveedor?: any): string | undefined {
    return proveedor ? proveedor.nombre_proveedor : undefined;
  }

  async getProvedorUsuario(usuarios) {
    try {
      const promises = [];
      for (const prop in usuarios) {
        const promise = new Promise<void>((resolve, reject) => {
          this._pagosservice.getSuplierUser(usuarios[prop].id).subscribe(resp => {
            if (resp.length > 0) {
              usuarios[prop].suplierid = resp[0].supplier_id;
              usuarios[prop].suplierUserid = resp[0].supplier_user_id;
            } else {
              usuarios[prop].suplierid = null;
              usuarios[prop].suplierUserid = null;
            }
            resolve();
          });
        });
        promises.push(promise);
      }
  
      await Promise.all(promises);
      
      return usuarios;
    } catch (error) {
      console.error('Error en getProvedorUsuario:', error);
      throw error;
    }
  }

  asignaProveedor(idusu: any, nusu: string, prov: any) {
    const idProveedor = prov.id_proveedor;
    const nombreProveedor = prov.nombre_proveedor;

    if (idProveedor == undefined || idProveedor == '') {
      swal2.fire('Debe seleccionar un proveedor de la lista','','info');
      return;
    }

    swal2.fire({
      title: 'Desea asignar el proveedor: ' + nombreProveedor + ' al usuario: '+nusu, 
      text: '', 
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false
    }).then(res => {
      if(res.value){
        const params = {
          token: '',
          secret_key: '',
          user_id: idusu,
          supplier_id: idProveedor
        };
        this._pagosservice.postUsuarioProveedor(params).subscribe( () => {
          swal2.close();
          swal2.fire({
            title: 'El proveedor fue asignado',
            text: 'Con exito',
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {
    
            if ( res.value ) {
              this.ngOnInit();
            }
    
          } );
    
         }, (err) => {
          swal2.close();
          console.log(err);
          swal2.fire(
               'Error al guardar',
               err.error.errors[0],
               'error'
            );
         } );
      } else {
        this.ngOnInit();
      }
    });
  }

  filtrarDatos() {
    return this.usuarios.filter(item =>
      item.name.toLowerCase().includes(this.terminoBusqueda.toLowerCase()) ||
      item.email.toLowerCase().includes(this.terminoBusqueda.toLowerCase())
    );
  }

  borraProveedor(item: any) {
    swal2.fire({
      title: 'Desea eliminar el proveedor?', 
      text: '', 
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false
    }).then(res => {
      if(res.value){
        this._pagosservice.borraSuplierUser(item).subscribe(
          resp2 => {
           swal2.fire({
             title: 'El proveedor fue eliminado',
             text: 'Con exito',
             icon: 'success',
             showConfirmButton: true,
             showCancelButton: false,
             allowOutsideClick: false
           }). then ( res => {
             if ( res.value ) {
               this.ngOnInit();
             }

           } );
          }, (err) => {
           swal2.close();
           console.log(err);
           swal2.fire(
                'Error al eliminar',
                err.error.errors[0],
                'error'
             );
          }
        );
      }
    });
  }
  
}
