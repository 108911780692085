import { Component, OnInit } from '@angular/core';
import { AwsService } from 'src/app/services/awsser/aws.service';
import { PagosService, ReportesService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-facturas-operadas',
  templateUrl: './facturas-operadas.component.html',
  styleUrls: ['./facturas-operadas.component.css']
})
export class FacturasOperadasComponent implements OnInit {
  companies = [];
  fecha = '';
  company = 0;
  cols = [
    {field: 'id_factura', header: 'Id factura'}, 
    {field: 'folio_factura', header: 'Folio factura'}, 
    {field: 'emisor', header: 'Emisor'}, 
    {field: 'receptor', header: 'Receptor'}, 
    {field: 'fecha_cobranza', header: 'Fecha de cobranza'}, 
    {field: 'fecha_operacion', header: 'Fecha operación'}, 
    {field: 'monto_neto', header: 'Monto neto'}, 
    {field: 'intereses', header: 'Intereses'}, 
    {field: 'total_factura', header: 'Total factura'}, 
  ];
  currentPage: number = 1;
  itemsPerPage: number = 10;
  filaSeleccionada: number | null = null;
  invoices = [];

  constructor(private pagosService: PagosService,
              private reportesService: ReportesService) { }

  ngOnInit() {
    this.pagosService.getCadenas().subscribe(resp => {
      this.companies = resp;
    })
  }

  seleccionarFila(index: number, currentPage: number, itemsPerPage: number) {
    this.filaSeleccionada = index + (currentPage - 1) * itemsPerPage;
  }

  get totalPages(): number {
    return Math.ceil(this.invoices.length / this.itemsPerPage);
  }

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  get dataToShow(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.invoices.slice(startIndex, startIndex + this.itemsPerPage);
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  generarReporte(){
    if(this.company == 0 || this.fecha == ''){
      swal2.fire('Atención', 'Faltan datos para la consulta', 'info');
      return;
    }
    swal2.showLoading();
    var params = {};
    params['used_date'] = this.fecha.replace(/-/g, '/');
    params['company_id'] = this.company;
    this.reportesService.getOperatedInvoices(params).subscribe(resp => {
      this.invoices = resp;
      swal2.close();
    })
  }

}
