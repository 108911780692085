import { Component, OnInit } from '@angular/core';
import { FacturasService } from '../../services/facturas/facturas.service';
import { ContribuyentesService } from '../../services/contribuyentes/contribuyentes.service';
import { ReportesService } from '../../services/reportes/reportes.service';
import swal2 from 'sweetalert2';
import { CamaraService } from '../../services/camaraService/camara.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-captura-facturas',
  templateUrl: './captura-facturas.component.html',
  styleUrls: ['./captura-facturas.component.css']
})
export class CapturaFacturasComponent implements OnInit {
  shots = [];
  cols = [];
  src = '/assets/images/noimage.jpg';
  defaultSrc = '/assets/images/noimage.jpg';
  pos = -1;
  document_id = '';
  emitter_rfc = '';
  invoice_date = '';
  total = '';

  constructor(private facturasService: FacturasService,
              private reportesService: ReportesService,
              private camaraService: CamaraService) { }

  async ngOnInit() {
    await this.facturasService.getShots().subscribe(resp => {
      this.shots = [];
      resp.data.forEach(shot => {
        if(shot.attributes.status == 'PENDIENTE'){
          let att = shot.attributes;
          this.shots.push(att);
        }
      });
      this.shots.forEach(shot => {
        this.facturasService.getShot(shot.id).subscribe(s => {
          shot.company = s.data.relations.company.attributes;
          shot.company_name = shot.company.business_name;
        });
      });
    });


    this.cols = [

      { field:  'document_id', header: 'ID documento'},
      { field:  'company_name', header: 'Empresa'}
  ];
  }

  verInfo(id){
    let shot = this.shots.find(s => s.id === id);
    this.pos = this.shots.findIndex(s => s.id === id);
    this.src = shot.shot;
    if(this.src.length < 100){
      this.src = this.defaultSrc;
    }
  }

  move(s){
    this.pos = this.pos + s;
    let img = this.shots[this.pos].shot;
    if(img.length > 100){
      this.src = img;
    }
    else{
      this.src = this.defaultSrc;
    }

    this.document_id = '';
    this.emitter_rfc = '';
    this.invoice_date = '';
    this.total = '';
  }

  rechazar(){
    Swal.fire({
      title: 'Describe la razón del rechazo',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if(result.value && result.value != ''){
        this.camaraService.updateShot(this.shots[this.pos].id, {status: 'RECHAZADA', notes: result.value}).subscribe(resp => {
          swal2.fire('Éxito', 'Factura rechazada', 'success');
          this.ngOnInit();
          this.src = this.defaultSrc;
        }, err => {
          console.log(err)
          swal2.fire('Atención', err.error.errors[0], 'info');
        })
      }else{
        swal2.fire('Error', 'Debe teclear una razón válida', 'info');
      }
    })
  }

  registrar(){
    let shot = this.shots[this.pos];
    this.reportesService.getCompanyRFC(shot.company.contributor_id).subscribe(res => {
      let reciever_rfc = res[0].rfc_contribuyente;
      let date = new Date()
  
      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()
  
      let entry_date;
  
      if(month < 10){
        entry_date = `${day}-0${month}-${year}`;
      }else{
        entry_date = `${day}-${month}-${year}`;
      }

      date = new Date(this.invoice_date)
  
      day = date.getDate()
      month = date.getMonth() + 1
      year = date.getFullYear()
  
      let invoice_date;
  
      if(month < 10){
        invoice_date = `${day}-0${month}-${year}`;
      }else{
        invoice_date = `${day}-${month}-${year}`;
      }
  
      let params = {};
      let invoice = {
        receiver_rfc: this.emitter_rfc,
        emitter_rfc: reciever_rfc,
        document_type: 'INVOICE', 
        invoice_serie: String(year),
        invoice_folio: this.document_id,
        invoice_date: invoice_date,
        entry_date: entry_date,
        currency: 'DOLARES',
        exchange_rate: '0',
        total: this.total,
        status: 'PENDIENTE',
        xml: 'N/A',
        pdf: shot.shot,
        uuid: this.emitter_rfc + this.document_id,
      };
  
      
      params['invoice'] = invoice;
      params['document_type'] = 'SHOT';
  
      this.facturasService.guardaFactura(params).subscribe(resp => {
        let updateShot = {
          invoice_id: resp['data'].attributes.id,
          document_id: resp['data'].attributes.invoice_folio,
          status: "PROCESADA"
        }

        let par = {
          document_shot: updateShot
        }
    
        //PATCH SHOT
        this.camaraService.updateShot(shot.id, par).subscribe(resp => {
          console.log(resp);
          swal2.fire('Éxito', 'Factura procesada correctamente', 'success');
          this.ngOnInit();
        }, err => {
          console.log(err)
          swal2.fire('Atención', err.error.errors[0], 'info');
        })
      }, err => {
        console.log(err)
        swal2.fire('Atención', err.error.errors[0], 'info');
      })
  
    })
  }

}
