import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Privilegio } from '../../models/usuario.model';
import { map } from 'rxjs/operators';
import * as S3 from "aws-sdk/clients/s3";

@Injectable()
export class FacturasService {

    token: string;

    constructor(public http: HttpClient,
                public router: Router) {
                  this.cargarStorage();
                }


    cargarStorage() {

    if ( localStorage.getItem('token')) {
         this.token = localStorage.getItem('token');
       } else {
         this.token = '';
        }
    }


    ////////////////////////////////////////
    xml(xmlData) {

      const XmlReader = require('xml-reader');
      const reader = XmlReader.create();
      const xhr = new XMLHttpRequest();
      // xhr.open('GET', '../../../assets/xml/1.xml');
      xhr.responseType = 'text';
      xhr.onload = function () {
        if (xhr.readyState === xhr.DONE) {
            if (xhr.status === 200) {
  
  
                const xml = xmlData; // xhr.response;
                reader.on('done', data => {console.log(data); return data; });
                reader.parse(xml);
                console.log(reader.parse(xml));
  
            }
        }
    };
  
      xhr.send(null);
  
    }
    ///////////////////////////////////////

    getInvoices() {

      const url = `${environment.URL_SERVICIOS}/invoices?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
      return this.http.get(url).pipe(
        map( (resp: any) => {
          return (resp);
        } )
      );
    }

    getCompanyAccountStatus(id) {

      const url = `${environment.URL_SERVICIOS}/company_account_status?company_id=${id}&token=${this.token}&secret_key=${environment.SECRET_KEY}`;
      return this.http.get(url).pipe(
        map( (resp: any) => {
          return (resp);
        } )
      );
    }

    updateInvoices( id, params ) {
      params.token = this.token;
      params.secret_key = environment.SECRET_KEY;
      const url = `${environment.URL_SERVICIOS}/invoices/${id}`;
      return this.http.patch( url, params ).pipe(
        map( (resp: any) => {
          return resp;
        }));
    }

    rejectInvoices( params ) {
      params.token = this.token;
      params.secret_key = environment.SECRET_KEY;
      const url = `${environment.URL_SERVICIOS}/reject_invoices`;
      return this.http.post( url, params )
    }

    deleteInvoice( id, params ) {
      const url = `${environment.URL_SERVICIOS}/invoices/${id}?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
      return this.http.delete( url, params ).pipe(
        map( (resp: any) => {
          return resp;
        }));
    }

    guardaFactura(data) {

    data.token = this.token;
    data.secret_key = environment.SECRET_KEY;

    const url = `${environment.URL_SERVICIOS}/invoices`;

    return this.http.post(url, data);

    }

    // LISTAS

    getInvoiceCurrency() {

      const url = `${environment.URL_SERVICIOS}/lists/domain/INVOICE_CURRENCY?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
      return this.http.get(url).pipe(
        map( (resp: any) => {
          return this.crearArregloCurrency(resp);
        } )
      );
    }

    getInvoiceStatus() {

      const url = `${environment.URL_SERVICIOS}/lists/domain/INVOICE_STATUS?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
      return this.http.get(url).pipe(
        map( (resp: any) => {
          return this.crearArregloCurrency(resp);
        } )
      );
    }
    crearArregloCurrency( contribuObj: any) {

      const rr: any[] = [];
      const resul: any[] = [];

      if ( contribuObj === null ) { return []; }
      Object.keys ( contribuObj ).forEach( key => {
        const rol: any = contribuObj[key];
        rr.push( rol );
      });
      // tslint:disable-next-line: forin
      for ( const prop in rr[0] ) {

        resul.push( rr[0][prop].attributes.value );

      }

      return resul;

    }

    getInvoicescopachisa() {

      const url = `${environment.URL_SERVICIOS}/reports/copachisa_invoices?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
      return this.http.get(url).pipe(
        map( (resp: any) => {
          return (resp);
        } )
      );
    }

    postInvoicescopachisa(params: any, aut: string, urlcopa: string) { 
      let headers = new HttpHeaders({
        'Access-Control-Allow-Origin': 'http://localhost:4200/#/regfactcopachisa',
        'Content-Type': 'application/json',
        'Authorization': aut });
    let options = { headers: headers };
      return this.http.post(urlcopa,params, options).pipe(
        map( (resp: any) => {
          return (resp);
        } )
      );
    }

    postFacturaCopa(idf, idu, ip) {
      const url = `${environment.URL_SERVICIOS}/invoices/${idf}/invoice_details?invoice_detail[ip]=${ip}&invoice_detail[host]=localhostcopa&invoice_detail[status]=REGISTRO CADENA&invoice_detail[notes]=Registro factura copachisa&invoice_detail[user_id]=${idu}&token=${this.token}&secret_key=${environment.SECRET_KEY}`;
      return this.http.post(url, null);
  
      }

     async getListxdomain(key: string) {

        const url = `${environment.URL_SERVICIOS}/get_general_param?key=${key}&token=${this.token}&secret_key=${environment.SECRET_KEY}`;
        return this.http.get(url).pipe(
          map( (resp: any) => {
            return (resp);
          } )
        );
      }

      async getIp() {

        const url = `http://api.ipify.org/?format=json`;
        return this.http.get(url).pipe(
          map( (resp: any) => {
            return (resp);
          } )
        );
      }


      // document_shot
      getShots(){
        const url = `${environment.URL_SERVICIOS}/document_shots?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
        return this.http.get(url).pipe(
          map( (resp: any) => {
            return (resp);
          } )
        );
      }

      getShot(id){
        const url = `${environment.URL_SERVICIOS}/document_shots/${id}?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
        return this.http.get(url).pipe(
          map( (resp: any) => {
            return (resp);
          } )
        );
      }
// Para el bucket de aws
async uploadFile(file: any) {
  const contentType = file.type;
  const bucket = new S3({
    accessKeyId: environment.AWS.accessKeyId,
    secretAccessKey: environment.AWS.secretAccessKey,
    region: environment.AWS.region,
  });
  const params = {
    Bucket: environment.AWS.bucketNomina,
    Key:
      environment.AWS.folder +
      "Facturas/" +
      file.name.substring(0, file.name.length - 4) +
      "/" +
      file.name,
    Body: file,
    ACL: "public-read",
    ContentType: contentType,
  };
  return bucket.upload(params).promise();
}

async delteFile(key: string) {
  const params = {
    Bucket: "nominabucket",
    Key: key,
  };
  const s3 = new S3({
    accessKeyId: environment.AWS.accessKeyId,
    secretAccessKey: environment.AWS.secretAccessKey,
    region: environment.AWS.region,
  });
  s3.deleteObject(params, function (err, data) {
    if (err) console.log(err);
    else console.log("Successfully deleted myBucket/myKey");
  });
}

async guardaFacturaDD(data) {
  data.token = this.token;
  data.secret_key = environment.SECRET_KEY;

  //const url = `${environment.URL_SERVICIOS}/invoices?invoice[admin]=SI`;
  const url = `${environment.URL_SERVICIOS}/invoices`;
  return this.http.post(url, data).pipe(
    map((resp: any) => {
      // console.log(resp);
      return resp; //this.crearArreglosimul(resp);
    })
  );
}

async uploadFileLayout(file: any) {
  const contentType = file.type;
  const bucket = new S3({
    accessKeyId: environment.AWS.accessKeyId,
    secretAccessKey: environment.AWS.secretAccessKey,
    region: environment.AWS.region,
  });
  const params = {
    Bucket: environment.AWS.bucketNomina,
    Key:
      environment.AWS.folder +
      "FacturasLayout/" +
      file.name.substring(0, file.name.length - 4) +
      "/" +
      file.name,
    Body: file,
    ACL: "public-read",
    ContentType: contentType,
  };
  return bucket.upload(params).promise();
}

async guardaFacturaLayout(data) {
  data.token = this.token;
  data.secret_key = environment.SECRET_KEY;

  //const url = `${environment.URL_SERVICIOS}/invoices?invoice[admin]=SI`;
  const url = `${environment.URL_SERVICIOS}/invoices`;
  return this.http.post(url, data).pipe(
    map((resp: any) => {
      // console.log(resp);
      return resp; //this.crearArreglosimul(resp);
    })
  );
}

getRfcUser(id){
  const url = `${environment.URL_SERVICIOS}/reports/user_id/${id}/get_rfc_from_user?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
  return this.http.get(url).pipe(
    map( (resp: any) => {
      return (resp);
    } )
  );
}

getFacturasUser(id){
  const url = `${environment.URL_SERVICIOS}/reports/user_id/${id}/user_invoices?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
  return this.http.get(url).pipe(
    map( (resp: any) => {
      return (resp);
    } )
  );
}

getEstatusFacturas() {

  const url = `${environment.URL_SERVICIOS}/lists/domain/INVOICE_STATUS?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
  return this.http.get(url).pipe(
    map( (resp: any) => {
      return this.crearArregloRate(resp);
    } )
  );

}

getReviewInvoices(id) {

  const url = `${environment.URL_SERVICIOS}/review_invoices?token=${this.token}&secret_key=${environment.SECRET_KEY}&company_id=${id}`;
  return this.http.get(url).pipe(
    map( (resp: any) => {
      return (resp);
    } )
  );

}

getCompanyForReview() {

  const url = `${environment.URL_SERVICIOS}/company_for_review?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
  return this.http.get(url).pipe(
    map( (resp: any) => {
      return (resp);
    } )
  );

}

crearArregloRate( contribuObj: any) {

  const rr: any[] = [];
  const resul: any[] = [];

  if ( contribuObj === null ) { return []; }
  Object.keys ( contribuObj ).forEach( key => {
    const rol: any = contribuObj[key];
    rr.push( rol );
  });
  // tslint:disable-next-line: forin
  for ( const prop in rr[0] ) {

    resul.push( rr[0][prop].attributes.value );

  }

  return resul;

}
}
