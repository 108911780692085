import { Component, OnInit, Input, EventEmitter, OnDestroy } from '@angular/core';
import { ContribuyentesService, OptionsService, MantenimientoContribuyentesService, UsuarioService, PagosService } from '../../../services/service.index';
import { AwsService } from 'src/app/services/awsser/aws.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Subscription, of, Observable } from 'rxjs';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-afiliacion',
  templateUrl: './afiliacion.component.html',
  styleUrls: ['../mantcontmain.component.css']
})
export class AfiliacionComponent implements OnInit, OnDestroy {
  @Input() eventEmitter: EventEmitter<Object>;
  message: string;
  idc;
  contributor_id = '';
  regUsuario = {
    contributor_id: this.contributor_id,
    name_denomination: '',
    legal_personality: '',
    legal_representative_name: '',
    birthdate_constitution_date: '',
    // fecha_prov: '',
    country_of_birth: '',
    rfc_homoclave: '',
    curp: '',
    nationality: '',
    gender: '',
    civil_status: '',
    fiel_number: '',
    proof_of_fiscal_situation: '',// numero de identificacion fiscal
    activity: '',
    profession: '',
    tax_residence: '',
    neighborhood: '',
    postal_code: '',
    municipality: '',
    state: '',
    chain_name: '',
    average_billing: '',
    current_credits: '',
    credit_type_amount: '',
    stock_structure: '',
    public_charge: '',
    public_charge_detail: '',
    relative_public_charge: '',
    relative_public_charge_detail: '',
    phone: '',
    email: '',
    marketing_autorization: '',
    pesos_usd_billing: '',
    insurance: 'N/A',
    deliver_account_status_home: false,
    deliver_account_status_internet: false,
    deliver_account_status_email: true,
    pesos_billing: 'false', 
    cat: '0', 
    tiie: '', 
    sofr: '', 
    credit_line_amount: '', 
    credit_line_amount_usd: '', 
    payment_total: '', 
    payment_total_usd: '', 
    term: '', 
    payment_due_date: '',
    cutoff_date: '', 
    opening_commission: '', 
    anticipate_payment_commission: '', 
    reca: '',
    reca_date: '',
    public_deed: '',
    deed_volume: '',
    deed_date: '',
    place: '',
    holder_licensee: '',
    notary_number: '',
    judicial_district: '',
    deed_state: '',
    state_signed_up: '',
    trade_folio: '',
    testimony_date: '',
    book: '',
    inscription_number: ''
   };
   nacionalidades = [
    {descripcion: 'Prefiero no responder', value: 'N/A'},
    {descripcion: 'Mexicano', value: 'Mexicano'},
    {descripcion: 'Extranjero', value: 'Extranjero'}
  ];
  generos = [
    {descripcion: 'Prefiero no responder', value: 'N/A'},
    {descripcion: 'Femenino', value: 'Femenino'},
    {descripcion: 'Masculino', value: 'Masculino'},
  ];
  sino = [
    {descripcion: 'Si', value: 'SI'},
    {descripcion: 'No', value: 'NO'},
  ];
  billing = [
    {descripcion: 'Pesos', value: 'false'},
    {descripcion: 'Pesos/Dólares', value: 'true'}
  ]
   previousDetails = true;
   contributor_type: any;
   colonias: any[] = [];
   estados: any[] = [];
   paises: any[];
   municipios: any[];
   manual = true;
   cadenas = [];
   show_credit_type_amount = false;
   show_public_detail = false;
   show_relative_public_charge_detail = false;
   archivoNombresSubidos = [];
   archivoNombresFirmas: any;
   idpais: any;
  constructor(
               public _contribuyentesService: ContribuyentesService,
               private pagosService: PagosService,
               private aws: AwsService,
               private usuarioService: UsuarioService,
               public _optionsservice: OptionsService,
               public _mantenimientocontservice: MantenimientoContribuyentesService,
               public router: Router,
               public http: HttpClient ) { }

  ngOnInit() {
    this.subscribeToParentEmitter();
    this.previousDetails = true;
  }

  ngOnDestroy(): void { 
    this.eventEmitter.unsubscribe();
   // console.log('DESTRUYO COMPONENTE afiliacion')

}

  subscribeToParentEmitter(): void { 
    this.eventEmitter.subscribe(d => {
    this.contributor_id = d.idc;
    this.aws.getDocuments(this.contributor_id).subscribe(resp => {
     // console.log(resp)
      this.archivoNombresSubidos = resp.filter(r => r.status == 'IN');
      this.archivoNombresFirmas = resp.filter(r => r.status == 'FIRMADO DIGITALMENTE' || r.status == 'ESPERA FIRMA DIGITAL' || r.status == 'PENDIENTE APROBACION' || r.status == 'PENDIENTE DE GENERACION');
      this.archivoNombresFirmas.forEach(f => {
      if(f.status == 'FIRMADO DIGITALMENTE'){
        if(f.document_type == 'CONTRATO'){
          f.url = resp.find(r => r.document_type == 'CONTRATO FIRMA').url;
        }else if(f.document_type == 'KYC'){
          f.url = resp.find(r => r.document_type == 'AVISO KYC FIRMA').url;
        }
      }      
    });
    })
    this._contribuyentesService.getContributorDetails(this.contributor_id).subscribe(resp => {
     // console.log(resp)
      if(resp == null){
        this.previousDetails = false;
        this._mantenimientocontservice.getContribuyented(this.contributor_id).subscribe(r => {
          this.contributor_type = r[0].contributor_type;
          this.contributor_id = r[0].id;
          this.regUsuario.contributor_id = this.contributor_id;
          if(this.contributor_type == 'PERSONA MORAL'){
            this.regUsuario.legal_personality = 'PM';
          }else if(this.contributor_type == 'PERSONA FISICA'){
            this.regUsuario.legal_personality = 'PFAE'
          }
        })
      }else{
        this.previousDetails = true;
        this.regUsuario = resp['data'][0];
        this._mantenimientocontservice.getContribuyented(this.contributor_id).subscribe(resp => {
          this.contributor_type = resp[0].contributor_type;
        })
       // console.log(this.regUsuario)
       // console.log(this.regUsuario.current_credits)
        if (this.regUsuario.postal_code.length>0) {
          try {
            this.aws.getColoniasxcp(this.regUsuario.postal_code).subscribe(r => {this.colonias = r;})
          } catch (error) {
            
          }
        }
        if (this.regUsuario.current_credits == 'SI' || this.regUsuario.current_credits == 'Si' ) {
          this.credvigentes('SI');
        }
        if (this.regUsuario.public_charge == 'SI' || this.regUsuario.public_charge == 'Si') {
          this.cpublico('SI');
        }
        if (this.regUsuario.relative_public_charge == 'SI' || this.regUsuario.relative_public_charge == 'Si') {
          this.cpublicorelative('SI');
        }
        this.aws.getCountriesD().subscribe( resp => { this.paises = resp;
          this.paises.forEach((ele) => {
          if (ele.name == 'Mexico') {
            this.idpais = ele.id;
          }
          this.aws.getStatesId(this.idpais).subscribe(resp => { this.estados = resp;
           // console.log(this.regUsuario.state[0].toUpperCase() + this.regUsuario.state.slice(1).toLowerCase());
            //this.regUsuario.state = this.regUsuario.state[0].toUpperCase() + this.regUsuario.state.slice(1).toLowerCase();
            //this.regUsuario.municipality = this.regUsuario.municipality[0].toUpperCase() + this.regUsuario.municipality.slice(1).toLowerCase();
            let idE = this.estados.find(s => s.name == this.regUsuario.state);
            this.aws.getMunicipios(idE.id).subscribe(resp => { this.municipios = resp; })
          });
          
          })
          } );
          this.pagosService.getCadenas().subscribe( resp => {
            this.cadenas = resp;
          } );
      }
      
    })
    })
}

//FIRMAS////
generarCaratula(){
  swal2.fire({
    title: 'Desea generar el contrato', 
    text: '', 
    icon: 'question',
    showConfirmButton: true,
    showCancelButton: true,
    allowOutsideClick: false
  }).then(res => {
    if(res.value){
      //console.log(this.contributor_id)
      this._mantenimientocontservice.generateContract(this.contributor_id).subscribe(resp => {
        swal2.fire({
          title: 'Contrato generado correctamente', 
          text: '', 
          icon: 'success',
          showConfirmButton: true,
          showCancelButton: false,
          allowOutsideClick: false
        }).then(res => {
          window.location.reload();
        })
      }, err => {
        console.log(err);
        swal2.fire('Algo salió mal', err.error.errors[0], 'warning');
    })
    }
  });
  
}

firmarKyc(){
  swal2.fire({
    title: 'Desea firmar KYC', 
    text: '', 
    icon: 'question',
    showConfirmButton: true,
    showCancelButton: true,
    allowOutsideClick: false
  }).then(res => {
    if(res.value){
    this._mantenimientocontservice.kycSignature(this.contributor_id).subscribe(resp => {
      //console.log(resp);
      swal2.fire({
        title: 'Solicitud de firma correcto', 
        text: '', 
        icon: 'success',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }).then(res => {
        window.location.reload();
      })
    }, err => {
      swal2.fire('Atención', 'Algo salió mal', 'info');
      console.log(err);
    })
  }
  })
  
}

firmarCaratula(){
  swal2.fire({
    title: 'Desea firmar contrato', 
    text: '', 
    icon: 'question',
    showConfirmButton: true,
    showCancelButton: true,
    allowOutsideClick: false
  }).then(res => {
    if(res.value) {
      this._mantenimientocontservice.contractCoverSignature(this.contributor_id).subscribe(resp => {
       // console.log(resp);
        swal2.fire({
          title: 'Solicitud de firma correcto', 
          text: '', 
          icon: 'success',
          showConfirmButton: true,
          showCancelButton: false,
          allowOutsideClick: false
        }).then(resp => {
          window.location.reload();
        })
      }, err => {
        swal2.fire('Atención', 'Algo salió mal', 'info');
        console.log(err);
      })
    }
  })
  
}
//FIN FIRMAS/////

//PARA LA EDICION DE LOS DATOS///
getColonias(event) {
  this.aws.getColoniasxcp(event).subscribe(r => {this.colonias = r;})
 }
 getStates(e){
 // console.log(e)
  this.aws.getStatesId(e).subscribe(resp => { this.estados = resp; })
}

getMunicipalities(e){
//  console.log(e);
  let idE = this.estados.find(s => s.name == e);
  this.aws.getMunicipios(idE.id).subscribe(resp => { this.municipios = resp; })
}

cpublico(e) {
//  console.log(e)
  if (e === 'SI') {
    this.show_public_detail = true;
  } else {
    this.show_public_detail = false;
    this.regUsuario.public_charge_detail = 'N/A';
  }
}

cpublicorelative(e) {
 // console.log(e)
  if (e === 'SI') {
    this.show_relative_public_charge_detail = true;
  } else {
    this.show_relative_public_charge_detail = false;
    this.regUsuario.relative_public_charge_detail = 'N/A'
    
  }
}

patchUsr(){
  let params = {};
  params['contributor_type'] = this.regUsuario.legal_personality;
  params['contributor_details'] = this.regUsuario;
 // console.log(params);
  this._contribuyentesService.patchContributorDetails(params, params['contributor_details'].id).subscribe(resp => {
    swal2.fire({
      title: 'Exito',
      text: 'Modificacion exitosa',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( resp => {
      if ( resp.value) {
        window.location.reload();
      }
    });
  }, err => {
    swal2.fire('Atención', 'Algo salió mal', 'info');
    console.log('error', err);
  })
}

credvigentes(e) {
 // console.log(e)
  if (e === 'SI') {
    this.show_credit_type_amount = true;
  } else {
    this.show_credit_type_amount = false;
    this.regUsuario.credit_type_amount = 'N/A'
  }
}

//FIN PARA LA EDICION DE LOS DATOS


}