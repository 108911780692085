import { Component, HostListener, OnInit } from "@angular/core";
import {
  AltaSolicitudesService,
  OptionsService,
  ContribuyentesService,
  MantenimientoContribuyentesService,
  FundersService,
  PagosService,
} from "../../../services/service.index";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder } from "@angular/forms";
import swal2 from "sweetalert2";
import { Facturas } from "src/app/models/usuario.model";
import { AngularFireStorage } from "@angular/fire/storage";
import { Observable } from "rxjs";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import * as fs from "file-saver";
import { Angular2Txt } from "angular2-txt/Angular2-txt";
import { SelectItem } from 'primeng/primeng';
import Bugsnag from '@bugsnag/js';
declare var $;
@Component({
  selector: "app-solicituddefondeo",
  templateUrl: "./solicituddefondeo.component.html",
  styleUrls: ['../../alta solicitudes/altasolicitudes.component.css']
})
export class SolicitudDeFondeoComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event)
  {
    this.esmovil(event.target.innerWidth);
    
  }
  mobile = false;
  isActive = true;
  modoMulticadena = false;
  cols: any[];
  disableCadenaInput = false;
  poperacion: number;
  horalimite: boolean;
  selectedCars1: any[] = [];
  selectedCars2: any[] = [];
  selectedFac: Facturas[];
  options: any[] = [];
  facturas: any[] = [];
  facturass: any[] = [];
  facturasfiltradas: any[] = [];
  simulacion: any[];
  idu: string;
  cadenaproveedor: any[];
  nombrecadena: string;
  mon: any[];
  fo: string;
  nombrefondeador: string[];
  companyid: string;
  funderid: string;
  supplierid: string[];
  invoices: any[] = [];
  load: boolean;
  firmantes: any[] = [];
  confirma = true;
  selectedValue = 0;
  muestratabla = true;
  muestratablafirmantes = false;
  currency: any[] = [];
  vienesinfiltro = false;
  totalaoperar;
  totalaoperearfiltro;
  proyectos: any[] = [];
  fechaHoy: string;
  fechaHoyParametro: string;
  muestracalendar: boolean;
  muestraaviso = false;
  // PARA REPORTE
  layoutbase: any[] = [];
  primerRow = [];
  segundoRow = [];
  detalles = [];
  ligaguardar = "";
  facturasReporte = [];
  respuesta: any[];
  uploadURL: Observable<string>;
  direcciones: any[];
  contribuyentes: any[];
  idcontsuplier = "";
  idcontcomany = "";
  startdatesuplier = "";
  direccioncompany = "";
  firmantesreporte: any[];
  firmantesreportenombres = "";
  bancoppel_multichain_mode = "OFF"
  idfunder = "";
  cadenas: any[];
  cadenaso: any[] = [];
  financieras: any[] = [];
  showModal: boolean;
  invoicesrequest: any[] = [];
  datostablapopup = [];
  brands: SelectItem[];
  constructor(
    private _formBuilder: FormBuilder,
    public router: Router,
    private _firestorage: AngularFireStorage,
    private route: ActivatedRoute,
    public _optionsservice: OptionsService,
    public _contribuyentesService: ContribuyentesService,
    public _mantenimientocontservice: MantenimientoContribuyentesService,
    public _fundersservice: FundersService,
    public _pagosservice: PagosService,
    public _solicitudesservice: AltaSolicitudesService
  ) { }

  ngOnInit() {
    this.esmovil(window.innerWidth);
    this.isActive = true;
    swal2.fire({
      title: "Cargando",
      allowOutsideClick: false,
    });
    this._solicitudesservice.getGeneralParam('BANCOPPEL_MULTICHAIN_MODE').subscribe(param => {
      this.bancoppel_multichain_mode = param['value'];
    })
    swal2.showLoading();
    this.muestraaviso = false;
    this.horalimite = false;
    this.muestracalendar = false;
    this.disableCadenaInput = false;
    this.fechaHoyParametro = "";
    this.idcontsuplier = "";
    this.idcontcomany = "";
    this.idfunder = "";
    this.totalaoperar = 0;
    this.totalaoperearfiltro = 0;
    this.poperacion = 100;
    this.confirma = true;
    const valormoneda = "PESOS";
    this.firmantesreporte = [];
    this.cadenaso = [];
    this.cadenas = [];
    this.facturas = [];
    this.vienesinfiltro = false;
    this._solicitudesservice
      .getPaymentCurrency()
      .subscribe((resp) => (this.currency = resp));
    this._solicitudesservice.getFechaParametrofunder().subscribe((resp) => {
      this.fechaHoyParametro = resp;
      if (this.fechaHoyParametro === "calendar") {
        this.muestracalendar = true;
        const a = new Date();
        a.setMinutes(a.getMinutes() + a.getTimezoneOffset());
        let montha = "" + (a.getMonth() + 1);
        let daya = "" + a.getDate();
        const yeara = a.getFullYear();
        if (montha.length < 2) {
          montha = "0" + montha;
        }
        if (daya.length < 2) {
          daya = "0" + daya;
        }
        this.fechaHoy = [yeara, montha, daya].join("-");
      } else if (this.fechaHoyParametro.includes("Hora")) {
        this.horalimite = true;
      } else {
        const a = new Date();
        a.setMinutes(a.getMinutes() + a.getTimezoneOffset());
        let montha = "" + (a.getMonth() + 1);
        let daya = "" + a.getDate();
        const yeara = a.getFullYear();
        if (montha.length < 2) {
          montha = "0" + montha;
        }
        if (daya.length < 2) {
          daya = "0" + daya;
        }
        this.fechaHoy = [yeara, montha, daya].join("-");
        this.muestracalendar = false;
      }
    });
    // OBTENER LA FECHA ACTUAL ///////////////
    this.muestratabla = true;
    this.muestratablafirmantes = false;
    this.selectedCars1 = [];
    this.selectedCars2 = [];
    // (document.getElementById('porcentajeoperacion') as HTMLInputElement).value = '';
    (document.getElementById("fechafactura") as HTMLInputElement).value = "";
    // (document.getElementById('fechaoperacion') as HTMLInputElement).value = '';
    // (document.getElementById('folio') as HTMLInputElement).value = '';
    this.simulacion = [];

    this.idu = localStorage.getItem("id");

    this.get_chains();
    this._fundersservice.getfundersfinancial().subscribe((resp) => {
      this.financieras = resp;
    });
    this.brands = [];
    this._fundersservice.getInvoiceStatusFiltro().subscribe(resp => {
      this.brands.push({ label: 'Todos', value: null });
      for (const prop in resp['data']) {
        this.brands.push({ label: resp['data'][prop].attributes.value, value: resp['data'][prop].attributes.value });
      }
    })
    //----------------------inicio del test -------------------------------------------------
    let id = 146;

    // this._fundersservice.getInvexLayout_sup(id).subscribe(resp2 => {
    //   console.log('la respuesta del sup', resp2);
    //   // this.armarRows(99, resp2.resumen[0], resp2.detalles[0], true);
    //   this.exportExcelInvexSup(id, resp2, 'sup');
    // }, err => {
    //   console.log('error', err);
    // });

    // this._fundersservice.getInvexLayout_fin(id).subscribe(resp2 => {
    //   console.log('la respuesta del fin', resp2);
    //   this.exportExcelInvex(id, resp2, 'fin');
    // });
    //test base

    // this._fundersservice.getBaseLayout(id).subscribe( resp2 => {
    //   // console.log(resp2);
    //   this.armarRows(id, resp2.resumen[0], resp2.detalles);
    // } );

    //test monex

    // this._fundersservice.getMonexLayout(id).subscribe(resp2 => {
    //   this.exportExcelInvex(id, resp2, 'fin');
    // });

    // -----------------------fin del test -------------

    this.cols = [
      { field: "invoice_folio", header: "Número de Factura" },
      { field: "uuid", header: "UUID" },
      { field: "status", header: "Estatus" },
      { field: "invoice_date", header: "Fecha Factura" },
      { field: "due_date", header: "Fecha de vencimiento" },
      { field: "totalformateado", header: "Total" },
      { field: "business_name", header: "Cadena" },
    ];

    swal2.close();
  }

  esmovil(wid) {
    if(wid < 640) {
      this.mobile = true;
    } else {
    this.mobile = false;
    }
  }

  refresca() {
    window.location.reload();
  }
  detail() {

  }

  show() {
    this.showModal = true; // Show-Hide Modal Check
  }
  hide() {
    this.showModal = false;
  }
  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  pruebaBanregio() {
    this._fundersservice.getBanregioLayout("80").subscribe((resp2) => {
      console.log(resp2);
      this.exportExcelBanregio("234", resp2, "FO1666295151");
    });
  }
  async exportExcelBanregio(ids, detalles, folio) {
    let cadena: string = "";
    let fileName = folio;
    for await (const prop of detalles) {
      cadena =
        cadena +
        ('"' +
          prop.uuid +
          '"' +
          "," +
          '"' +
          prop.tipo_documento +
          '"' +
          "," +
          '"' +
          prop.fecha_inicio +
          '"' +
          "," +
          parseFloat(prop.monto).toFixed(2) +
          "," +
          '"' +
          prop.moneda +
          '"' +
          "," +
          '"' +
          prop.fecha_vencimiento +
          '"' +
          "," +
          '"' +
          prop.numero_comprador +
          '"' +
          "," +
          '"' +
          prop.linea +
          '"' +
          "," +
          '"' +
          prop.retencion_isr_cantidad_base +
          '"' +
          "," +
          '"' +
          prop.retencion_isr_factor +
          '"' +
          "," +
          '"' +
          prop.retencion_isr_valor_tasa +
          '"' +
          "," +
          '"' +
          prop.retencion_isr_valor_impuesto +
          '"' +
          "," +
          '"' +
          prop.retencion_iva_cantidad_base +
          '"' +
          "," +
          '"' +
          prop.retencion_iva_factor +
          '"' +
          "," +
          '"' +
          prop.retencion_iva_valor_tasa +
          '"' +
          "," +
          '"' +
          prop.retencion_iva_valor_impuesto +
          '"' +
          "," +
          '"' +
          prop.retencion_ieps_cantidad_base +
          '"' +
          "," +
          '"' +
          prop.retencion_ieps_factor +
          '"' +
          "," +
          '"' +
          prop.retencion_ieps_valor_tasa +
          '"' +
          "," +
          '"' +
          prop.retencion_ieps_valor_impuesto +
          '"' +
          "," +
          '"' +
          prop.traslado_isr_cantidad_base +
          '"' +
          "," +
          '"' +
          prop.traslado_isr_factor +
          '"' +
          "," +
          '"' +
          prop.traslado_isr_valor_tasa +
          '"' +
          "," +
          '"' +
          prop.traslado_isr_valor_impuesto +
          '"' +
          "," +
          '"' +
          parseFloat(prop.traslado_iva_cantidad_base).toFixed(2) +
          '"' +
          "," +
          '"' +
          prop.traslado_iva_factor +
          '"' +
          "," +
          '"' +
          prop.traslado_iva_valor_tasa +
          '"' +
          "," +
          '"' +
          parseFloat(prop.traslado_iva_valor_impuesto).toFixed(2) +
          '"' +
          "," +
          '"' +
          prop.traslado_ieps_cantidad_base +
          '"' +
          "," +
          '"' +
          prop.traslado_ieps_factor +
          '"' +
          "," +
          '"' +
          prop.traslado_ieps_valor_tasa +
          '"' +
          "," +
          '"' +
          prop.traslado_ieps_valor_impuesto +
          '"') +
        "\n";
    }
    let blob = new Blob([cadena], { type: "text" });
    const file = blob;
    const filepath = fileName + "/" + fileName;
    // var FileSaver = require("file-saver");
    // FileSaver.saveAs(blob, `ejemplo.txt`);
    this.fbEmailBanregio(filepath, file, ids);
  }

  fbEmailBanregio(filepath, file, ids) {
    const fileRef = this._firestorage.ref(filepath);
    this._firestorage.upload(filepath, file).then(() => {
      fileRef.getDownloadURL().subscribe((resp) => {
        console.log("up", resp);
        this.uploadURL = resp;
        const params = {
          token: "",
          secret_key: "",
        };

        params["attached"] = this.uploadURL;
        console.log("guardando en attached");
        this._fundersservice.agregaattached(ids, params).subscribe(
          () =>
            this._fundersservice
              .getEnviaMail(ids)
              .subscribe((resp) => console.log(resp)),
          (err) => {
            console.log(err);
          }
        );
      });
    });
  }
  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  getAcceso(url) {
    let tieneacceso = false;
    this._optionsservice
      .getOptionsxUsuario(localStorage.getItem("id"))
      .subscribe((resp2) => {
        // tslint:disable-next-line: forin
        for (const j in resp2) {
          if (resp2[j].url === url) {
            tieneacceso = true;
            break;
          }
        }
        if (!tieneacceso) {
          this.router.navigate(["/accesodenegado"]);
        }
      });
  }

  getIds() {
    /////////////////////////
    ////////////////////////
    this.selectedCars1 = [];
    this.selectedCars2 = [];
    this.companyid = (
      document.getElementById("cadena") as HTMLInputElement
    ).value;
    const moneda: any = document.getElementById("moneda");
    const valormoneda = moneda.options[moneda.selectedIndex].value;
    if (this.companyid != "noval") {
      this._fundersservice
        .getFacturasxcadena(this.companyid, valormoneda)
        .subscribe((resp2) => {
          this.facturas = resp2;
          // tslint:disable-next-line: forin
          for (const prop in this.facturas) {
            this.facturas[prop].porcentaje = 100;
            this.facturas[prop].totalaoperar = parseFloat(
              this.facturas[prop].total
            )
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.facturas[prop].totalformateado = parseFloat(
              this.facturas[prop].total
            )
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        });
    }
  }

  evaluateFunder(){
    if (this.bancoppel_multichain_mode == 'ON'){
      this.funderid = (document.getElementById("proveedor") as HTMLInputElement).value;
      this.companyid = (document.getElementById("cadena") as HTMLInputElement).value;
      let funder = this.financieras.find(
      (financiera) => financiera.id_funder === Number(this.funderid)
      );
      if (funder.nombre_fondeador === 'BANCOPPEL') {
        this.disableCadenaInput = true;
        this.nombrecadena = '';
        this.companyid = '';
        (document.getElementById('cadena') as HTMLInputElement).value = '';

        const moneda: any = document.getElementById("moneda");
        const valormoneda = moneda.options[moneda.selectedIndex].value;
        this._fundersservice.getFacturasxcadenaBancoppel(this.funderid, valormoneda)
        .subscribe((resp2) => {
          this.facturas = resp2;

          for (const prop in this.facturas) {
            this.facturas[prop].porcentaje = 100;
            this.facturas[prop].totalaoperar = parseFloat(
              this.facturas[prop].total
            )
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.facturas[prop].totalformateado = parseFloat(
              this.facturas[prop].total
            )
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        });
      } else {

        if (this.companyid){

        }else{

          this.disableCadenaInput = false;
          this.facturas = [];
          this.selectedValue = 0;

        }
      }
    }
    else {

    }

  }

  actualizatosinfiltro() {
    this.totalaoperar = 0;
    // tslint:disable-next-line: forin
    for (const prop in this.selectedCars2) {
      this.selectedCars2[prop].totalaoperar =
        this.selectedCars2[prop].total *
        (this.selectedCars2[prop].porcentaje / 100);
      this.totalaoperar =
        this.totalaoperar + this.selectedCars2[prop].totalaoperar;
      this.selectedCars2[prop].totalaoperar = parseFloat(
        this.selectedCars2[prop].totalaoperar.toString()
      )
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    this.totalaoperar = this.totalaoperar
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  actualizatoconfiltro() {
    this.totalaoperearfiltro = 0;
    // tslint:disable-next-line: forin
    for (const prop in this.selectedCars1) {
      this.selectedCars1[prop].totalaoperar =
        this.selectedCars1[prop].total *
        (this.selectedCars1[prop].porcentaje / 100);
      this.totalaoperearfiltro =
        this.totalaoperearfiltro + this.selectedCars1[prop].totalaoperar;
      this.selectedCars1[prop].totalaoperar = parseFloat(
        this.selectedCars1[prop].totalaoperar.toString()
      )
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    this.totalaoperearfiltro = this.totalaoperearfiltro
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  muestraxcurr() {
    this.selectedCars1 = [];
    this.selectedCars2 = [];
    this.companyid = (
      document.getElementById("cadena") as HTMLInputElement
    ).value;

    const moneda: any = document.getElementById("moneda");
    const valormoneda = moneda.options[moneda.selectedIndex].value;

    this._fundersservice
      .getFacturasxcadena(this.companyid, valormoneda)
      .subscribe((resp2) => {
        this.facturas = resp2;
        // tslint:disable-next-line: forin
        for (const prop in this.facturas) {
          this.facturas[prop].porcentaje = 100;
          this.facturas[prop].totalaoperar = parseFloat(
            this.facturas[prop].total
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.facturas[prop].totalformateado = parseFloat(
            this.facturas[prop].total
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      });
  }

  lipiarcampos() {
    this.ngOnInit();
  }

  filtrafacturas() {
    this.totalaoperearfiltro = "0.00";
    this.totalaoperar = "0.00";
    this.selectedCars1 = [];
    this.selectedCars2 = [];
    const moneda: any = document.getElementById("moneda");
    //  const proyecto: any = document.getElementById('proyecto');
    const valormoneda = moneda.options[moneda.selectedIndex].value;
    const valorproyecto = "todos";
    // this.muestratabla = false;
    this.facturasfiltradas = [];
    const a = new Date(
      (document.getElementById("fechafactura") as HTMLInputElement).value
    );
    a.setMinutes(a.getMinutes() + a.getTimezoneOffset());
    let montha = "" + (a.getMonth() + 1);
    let daya = "" + a.getDate();
    const yeara = a.getFullYear();
    if (montha.length < 2) {
      montha = "0" + montha;
    }
    if (daya.length < 2) {
      daya = "0" + daya;
    }
    const fechaoperacion = [yeara, montha, daya].join("-");
    this._fundersservice
      .getFacturasxcadena(this.companyid, valormoneda)
      .subscribe((resp) => {
        this.facturass = resp;
        // tslint:disable-next-line: forin
        for (const prop in this.facturass) {
          this.facturass[prop].totalformateado = parseFloat(
            this.facturass[prop].total
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        if (fechaoperacion === "NaN-NaN-NaN" && valorproyecto === "todos") {
          this.muestratabla = true;
        } else if (
          fechaoperacion !== "NaN-NaN-NaN" &&
          valorproyecto === "todos"
        ) {
          this.muestratabla = false;
          for (const prop in this.facturas) {
            if (this.facturass[prop].invoice_date === fechaoperacion) {
              this.facturasfiltradas.push(this.facturass[prop]);
            }
          }
          // tslint:disable-next-line: forin
          for (const prep in this.facturasfiltradas) {
            this.facturasfiltradas[prep].porcentaje = 100;
            this.facturasfiltradas[prep].totalaoperar = parseFloat(
              this.facturasfiltradas[prep].total
            )
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        }
      });
  }

  recalcula() {
    if (this.horalimite) {
      swal2
        .fire({
          title: "La hora límite para hacer solicitudes de factoraje es:",
          text: this.fechaHoyParametro,
          icon: "error",
          showConfirmButton: true,
          showCancelButton: false,
          allowOutsideClick: false,
        })
        .then((res) => {
          if (res.value) {
            location.reload();
          }
        });
    } else {
      if (this.selectedCars1.length === 0) {
        swal2.fire("Debe seleccionar al menos una factura", "", "info");
      } else {
        this.isActive = false;
        let total = 0;
        swal2.fire({
          title: "Cargando",
          allowOutsideClick: false,
        });
        swal2.showLoading();
        const moneda: any = document.getElementById("moneda");

        const valormoneda = moneda.options[moneda.selectedIndex].value;
        // tslint:disable-next-line: forin
        for (const prop in this.selectedCars1) {
          total = total + parseFloat(this.selectedCars1[prop].total);
        }
        // Fecha de operación request date
        const d = new Date(
          (document.getElementById("fechafactura") as HTMLInputElement).value
        );
        d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) {
          month = "0" + month;
        }
        if (day.length < 2) {
          day = "0" + day;
        }

        let fechafactura = [year, month, day].join("-");

        let fechaMayorFactura = this.selectedCars1[0].due_date;
        // tslint:disable-next-line: forin
        for (const prop in this.selectedCars1) {
          if (fechaMayorFactura < this.selectedCars1[prop].due_date) {
            fechaMayorFactura = this.selectedCars1[prop].due_date;
          }

          fechafactura = fechaMayorFactura;
        }

        // Fecha Factura used date
        const a = new Date(
          (document.getElementById("fechaoperacion") as HTMLInputElement).value
        );
        a.setMinutes(a.getMinutes() + a.getTimezoneOffset());
        let montha = "" + (a.getMonth() + 1);
        let daya = "" + a.getDate();
        const yeara = a.getFullYear();

        if (montha.length < 2) {
          montha = "0" + montha;
        }
        if (daya.length < 2) {
          daya = "0" + daya;
        }

        const fechaoperacion = [yeara, montha, daya].join("-");
        this.idfunder = (
          document.getElementById("proveedor") as HTMLInputElement
        ).value;
        const paramssimul = {
          token: "",
          secret_key: "",
          simulation: true,
          invoices: [],
          funding_request: {
            funder_id: this.idfunder,
            company_id: this.companyid.toString(),
            user_id: this.idu,
            funding_request_date: this.fechaHoy,
            // funding_request_date: fechaoperacion,
            attached: "https://attached",
            currency: valormoneda,
          },
        };

        // tslint:disable-next-line: forin
        for (const prop in this.selectedCars1) {
          paramssimul.invoices[prop] = {
            id: this.selectedCars1[prop].id.toString(),
            percent: this.selectedCars1[prop].porcentaje,
          };
        }
        //  console.log(paramssimul);
        try {
          this.nombrecadena = (this.cadenas.filter(p =>  p.id_cadena == this.companyid)[0].nombre_cadena);
          this.nombrefondeador = (this.financieras.filter(p =>  p.id_funder == this.idfunder)[0].nombre_fondeador);
          this.mon = (valormoneda)
          this.fo = (fechaoperacion);
        } catch (error) {
          console.log(error);
        }
        this._fundersservice.getSimulacion(paramssimul).subscribe(
          (resp) => {
            this._fundersservice.getSimulacionparadetalle(paramssimul).subscribe(resp => {
              this.datostablapopup = [];
              for (const prop in this.selectedCars1) {
                for (const h in resp.data.relations.funding_request_invoices) {
                  if (this.selectedCars1[prop].id.toString() === resp.data.relations.funding_request_invoices[h].attributes.invoice_id.toString()) {
                    this.datostablapopup.push({
                      folio: this.selectedCars1[prop].invoice_folio,
                      uuid: this.selectedCars1[prop].uuid,
                      ext_rate: resp.data.relations.funding_request_invoices[h].attributes.ext_rate,
                      int_rate: resp.data.relations.funding_request_invoices[h].attributes.int_rate,
                      total_rate: resp.data.relations.funding_request_invoices[h].attributes.total_rate,
                      interests: resp.data.relations.funding_request_invoices[h].attributes.interests,
                      total_used: resp.data.relations.funding_request_invoices[h].attributes.total_used
                    })
                  }
                }
              }
            });
            swal2.close();
            let funder = this.financieras.find(
              (financiera) => financiera.id_funder === Number(this.idfunder)
            );
            if (funder.nombre_fondeador === 'BANCO INVEX') {
              this.muestraaviso = true;
            } else {
              this.muestraaviso = false;
            }
            this.simulacion = resp;
            const fecha1 = new Date(this.simulacion[0].used_date);
            const fecha2 = new Date(this.simulacion[0].due_date);
            const milisegundosdia = 24 * 60 * 60 * 1000;
            const milisegundostranscurridos = Math.abs(
              fecha1.getTime() - fecha2.getTime()
            );
            const diastranscurridos = Math.round(
              milisegundostranscurridos / milisegundosdia
            );
            this.simulacion[0].diastranscurridos = diastranscurridos;
            this.muestratablafirmantes = true;
            const totalformat = parseFloat(
              this.simulacion[0].total.replace(/,/g, "")
            );
            this.simulacion[0].total = totalformat
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            const totalusedformat = parseFloat(
              this.simulacion[0].total_used.replace(/,/g, "")
            );
            this.simulacion[0].total_used = totalusedformat
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            const interestsformat = parseFloat(
              this.simulacion[0].interests.replace(/,/g, "")
            );
            this.simulacion[0].interests = interestsformat
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            const netamountformat = parseFloat(
              this.simulacion[0].net_amount.replace(/,/g, "")
            );
            this.simulacion[0].net_amount = netamountformat
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
          (err) => {
            console.log(err);
            swal2
              .fire({
                title: "Ocurrio un error",
                text: err.error.errors[0],
                icon: "error",
                showConfirmButton: true,
                showCancelButton: false,
                allowOutsideClick: false,
              })
              .then((res) => {
                if (res.value) {
                  location.reload();
                }
              });
          }
        );
      }
    }
  }

  prueba() {
    if (this.vienesinfiltro) {
      this.load = true;
      let total = 0;
      this.invoices = [];
      let fechaMayorFactura = "";
      let fechaMayorDueDate = "";
      const moneda: any = document.getElementById("moneda");
      const valormoneda = moneda.options[moneda.selectedIndex].value;
      // tslint:disable-next-line: forin
      for (const prop in this.selectedCars2) {
        total = total + parseFloat(this.selectedCars2[prop].total);

        this.invoices.push(this.selectedCars2[prop].id);
      }

      fechaMayorFactura = this.selectedCars2[0].invoice_date;
      for (const prop in this.selectedCars2) {
        if (fechaMayorFactura < this.selectedCars2[prop].invoice_date) {
          fechaMayorFactura = this.selectedCars2[prop].invoice_date;
        }
      }

      const fechafactura = fechaMayorFactura;

      const a = new Date(
        (document.getElementById("fechaoperacion") as HTMLInputElement).value
      );
      a.setMinutes(a.getMinutes() + a.getTimezoneOffset());
      let montha = "" + (a.getMonth() + 1);
      let daya = "" + a.getDate();
      const yeara = a.getFullYear();

      if (montha.length < 2) {
        montha = "0" + montha;
      }
      if (daya.length < 2) {
        daya = "0" + daya;
      }

      const fechaoperacion = [yeara, montha, daya].join("-");

      fechaMayorDueDate = this.selectedCars2[0].due_date;
      for (const prop in this.selectedCars2) {
        if (fechaMayorDueDate < this.selectedCars2[prop].due_date) {
          fechaMayorDueDate = this.selectedCars2[prop].due_date;
        }
      }
      this.idfunder = (
        document.getElementById("proveedor") as HTMLInputElement
      ).value;
      const data = {
        token: "",
        secret_key: "",
        invoices: [],
        funding_request: {
          funder_id: this.idfunder,
          company_id: this.companyid.toString(),
          user_id: this.idu,
          funding_request_date: this.fechaHoy,
          // funding_request_date: fechaoperacion,
          attached: "https://attached",
          currency: valormoneda,
        },
      };

      // tslint:disable-next-line: forin

      // tslint:disable-next-line: forin
      for (const prop in this.selectedCars2) {
        data.invoices[prop] = {
          id: this.selectedCars2[prop].id.toString(),
          percent: this.selectedCars2[prop].porcentaje,
        };
      }

      // console.log(data);

      swal2.fire({
        title: "Cargando",
        allowOutsideClick: false,
      });
      swal2.fire({
        title: "Cargando",
        allowOutsideClick: false,
      });
      swal2.showLoading();
      this.facturasReporte = [];
      this._fundersservice.getSimulacion(data).subscribe(
        async (resp) => {
          let funder = this.financieras.find(
            (financiera) => financiera.id_funder === Number(this.idfunder)
          );
          switch (funder.nombre_fondeador) {
            case "BANCO BASE":
              this._fundersservice
                .getBaseLayout(resp[0].id)
                .subscribe((resp2) => { this.armarRows(resp[0].id, resp2.resumen[0], resp2.detalles);}
                , err => {
                  Bugsnag.notify(new Error('Funders -> Solicitud de fondeo layout base'), function (event) {
                    event.severity = 'error'
                    event.context = 'Solicitud de fondeo creada, error en layout base'
                    event.addMetadata('Informacion regresada', { err, data, resp })
                    event.addMetadata('Datos del usuario', { 'id': localStorage.getItem('id'),
                                                   'nombre': localStorage.getItem('usuario'),
                                                   'correo': localStorage.getItem('emailuser')}) 
                  });
                });
                // setTimeout(() => {
                  // this._fundersservice.getEnviaMail(resp[0].id).subscribe((resp) => console.log(resp));
                // }, 9000);
              break;
            case "BANREGIO":
              this._fundersservice
                .getBanregioLayout(resp[0].id)
                .subscribe((resp2) => {this.exportExcelBanregio(resp[0].id, resp2, resp[0].folio); }
                , err => {
                  Bugsnag.notify(new Error('Funders -> Solicitud de fondeo layout banregio'), function (event) {
                    event.severity = 'error'
                    event.context = 'Solicitud de fondeo creada, error en layout banregio'
                    event.addMetadata('Informacion regresada', { err, data, resp })
                    event.addMetadata('Datos del usuario', { 'id': localStorage.getItem('id'),
                                                   'nombre': localStorage.getItem('usuario'),
                                                   'correo': localStorage.getItem('emailuser')}) 
                  });
                });
                setTimeout(() => {
                this._fundersservice.getEnviaMail(resp[0].id).subscribe((resp) => console.log(resp));
              }, 9000);
              break;
            case "BANCO INVEX":
              this._fundersservice.getInvexLayout_sup(resp[0].id).subscribe((resp2) => { this.exportExcelInvexSup(resp[0].id, resp2, "sup"); }
              , err => {
                Bugsnag.notify(new Error('Funders -> Solicitud de fondeo layout invex_sup'), function (event) {
                  event.severity = 'error'
                  event.context = 'Solicitud de fondeo creada, error en layout invex_sup'
                  event.addMetadata('Informacion regresada', { err, data, resp })
                  event.addMetadata('Datos del usuario', { 'id': localStorage.getItem('id'),
                                                   'nombre': localStorage.getItem('usuario'),
                                                   'correo': localStorage.getItem('emailuser')}) 
                });
              });
              this._fundersservice.getInvexLayout_fin(resp[0].id).subscribe((resp2) => {
                this.exportExcelInvex(resp[0].id, resp2, "fin");
                // this.anexoBsuplemento(resp[0].id, resp2); joseluisdc48@gmail.com FO1737655138
              }, err => {
                Bugsnag.notify(new Error('Funders -> Solicitud de fondeo layout invex_fin'), function (event) {
                  event.severity = 'error'
                  event.context = 'Solicitud de fondeo creada, error en layout invex_fin'
                  event.addMetadata('Informacion regresada', { err, data, resp })
                  event.addMetadata('Datos del usuario', { 'id': localStorage.getItem('id'),
                                                   'nombre': localStorage.getItem('usuario'),
                                                   'correo': localStorage.getItem('emailuser')}) 
                });
              });
              setTimeout(() => {
                this._fundersservice.getEnviaMail(resp[0].id).subscribe((resp) => console.log(resp));
              }, 9000);
              break;
              case "BANCOPPEL":
                const id = resp[0].id;
                try {
                  await Promise.all([
                    this._fundersservice.getBancoppelLayout_rao(id).toPromise().then(resp2 =>
                      this.exportTxtBancoppelRao(id, resp2, "rao")
                      
                    ),
                    this._fundersservice.getBancoppelLayout_fin(id).toPromise().then(resp2 =>
                      this.exportExcelBancoppel(id, resp2, "anb")
                    )
                  ]);
                  const mailResponse = await this._fundersservice.getEnviaMail(id).toPromise();
                  console.log(mailResponse);
                } catch (error) {
                  Bugsnag.notify(new Error("Error en procesamiento de BANCOPPEL"), (event) => {
                    event.severity = "error";
                    event.addMetadata("Detalles", { error });
                  });
                }
                break;

            case "BANCO MONEX":
              this._fundersservice
                .getMonexLayout(resp[0].id)
                .subscribe((resp2) => {
                  this.exportExcelInvexMonex(resp[0].id, resp2, "fin");
                }, err => {
                  Bugsnag.notify(new Error('Funders -> Solicitud de fondeo layout layout monex'), function (event) {
                    event.severity = 'error'
                    event.context = 'Solicitud de fondeo creada, error en layout layout monex'
                    event.addMetadata('Informacion regresada', { err, data, resp })
                    event.addMetadata('Datos del usuario', { 'id': localStorage.getItem('id'),
                                                   'nombre': localStorage.getItem('usuario'),
                                                   'correo': localStorage.getItem('emailuser')}) 
                  });
                });
                setTimeout(() => {
                  this._fundersservice.getEnviaMail(resp[0].id).subscribe((resp) => console.log(resp));
                }, 9000);
              break;
            
            default:
              this._fundersservice
                .getBaseLayout(resp[0].id)
                .subscribe((resp2) => {
                  this.armarRows(resp[0].id, resp2.resumen[0], resp2.detalles);
                }, err => {
                  Bugsnag.notify(new Error('Funders -> Solicitud de fondeo layout base'), function (event) {
                    event.severity = 'error'
                    event.context = 'Solicitud de fondeo creada, error en layout base PERO en default'
                    event.addMetadata('Informacion regresada', { err, data, resp })
                    event.addMetadata('Datos del usuario', { 'id': localStorage.getItem('id'),
                                                   'nombre': localStorage.getItem('usuario'),
                                                   'correo': localStorage.getItem('emailuser')}) 
                  });
                });
              //swal2.fire("error", "Fondeador inválido", "error");
              break;
          }

          //-_______--------------_______________--------------
          // console.log(resp);
          //         this._fundersservice.getBaseLayout(resp[0].id).subscribe( resp2 => {
          //         //console.log(resp2);
          //         // this.layoutbase = resp2;
          //         // this.ligaguardar =  resp2.resumen[0].numero_acuse + '/' + resp2.resumen[0].numero_acuse + '.xls';
          //         // this.primerRow.push(resp2.resumen[0].epo);
          //         // this.primerRow.push((resp2.resumen[0].monto_descuento_mn));
          //         // this.primerRow.push((resp2.resumen[0].monto_intereses_mn));
          //         // this.primerRow.push((resp2.resumen[0].monto_operar_mn));
          //         // this.primerRow.push((resp2.resumen[0].monto_descuento_usd));
          //         // this.primerRow.push((resp2.resumen[0].monto_intereses_usd));
          //         // this.primerRow.push((resp2.resumen[0].monto_operar_usd));
          //         // this.segundoRow.push(resp2.resumen[0].numero_acuse);
          //         // this.segundoRow.push(resp2.resumen[0].fecha_carga);
          //         // this.segundoRow.push(resp2.resumen[0].hora_carga);
          //         // this.segundoRow.push(resp2.resumen[0].usuario_captura);
          //         // //console.log(resp2.detalles);
          //         // this.detalles.push(resp2.detalles);
          //         //console.log(this.primerRow);
          //         //console.log(this.segundoRow);
          //         //console.log(this.detalles[0]);
          //         this.armarRows(resp[0].id, resp2.resumen[0], resp2.detalles);
          //         // this.exportexcell( resp[0].id );

          // } );

          /// -------------------________--______
          swal2.close();
          swal2
            .fire({
              title: "Creacion de Solicitud Exitosa",
              text: resp[0].folio,
              icon: "success",
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: false,
            })
            .then((res) => {
              if (res.value) {
                this.load = false;
                this.ngOnInit();
              }
            });
        },
        (err) => {
          console.log(err);
          Bugsnag.notify(new Error('Funders -> Solicitud de fondeo'), function (event) {
            event.severity = 'error'
            event.context = 'En crear solicitud fondeo'
            event.addMetadata('Informacion regresada', { err, data }) 
            event.addMetadata('Datos del usuario', { 'id': localStorage.getItem('id'),
                                                   'nombre': localStorage.getItem('usuario'),
                                                   'correo': localStorage.getItem('emailuser')}) 
          });
          swal2
            .fire({
              title: "Ocurrio un error",
              text: err.error.errors[0],
              icon: "error",
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: false,
            })
            .then((res) => {
              if (res.value) {
                this.load = false;
                location.reload();
              }
            });
        }
      );
    } else {
      this.load = true;
      let total = 0;
      this.invoices = [];
      const moneda: any = document.getElementById("moneda");

      const valormoneda = moneda.options[moneda.selectedIndex].value;
      // tslint:disable-next-line: forin
      for (const prop in this.selectedCars1) {
        total = total + parseFloat(this.selectedCars1[prop].total);

        this.invoices.push(this.selectedCars1[prop].id);
      }

      const d = new Date(
        (document.getElementById("fechafactura") as HTMLInputElement).value
      );
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
      let month = "" + (d.getMonth() + 1);
      let day = "" + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }

      let fechafactura = [year, month, day].join("-");

      let fechaMayorFactura = this.selectedCars1[0].due_date;
      for (const prop in this.selectedCars1) {
        if (fechaMayorFactura < this.selectedCars1[prop].due_date) {
          fechaMayorFactura = this.selectedCars1[prop].due_date;
        }
      }
      fechafactura = fechaMayorFactura;

      const a = new Date(
        (document.getElementById("fechaoperacion") as HTMLInputElement).value
      );
      a.setMinutes(a.getMinutes() + a.getTimezoneOffset());
      let montha = "" + (a.getMonth() + 1);
      let daya = "" + a.getDate();
      const yeara = a.getFullYear();

      if (montha.length < 2) {
        montha = "0" + montha;
      }
      if (daya.length < 2) {
        daya = "0" + daya;
      }

      const fechaoperacion = [yeara, montha, daya].join("-");

      this.idfunder = (
        document.getElementById("proveedor") as HTMLInputElement
      ).value;
      const data = {
        token: "",
        secret_key: "",
        invoices: [],
        funding_request: {
          funder_id: this.idfunder,
          company_id: this.companyid.toString(),
          user_id: this.idu,
          funding_request_date: this.fechaHoy,
          // funding_request_date: fechaoperacion,
          attached: "https://attached",
          currency: valormoneda,
        },
      };
      // tslint:disable-next-line: forin
      for (const prop in this.selectedCars1) {
        data.invoices[prop] = {
          id: this.selectedCars1[prop].id.toString(),
          percent: this.selectedCars1[prop].porcentaje,
        };
      }

      //  console.log(data);

      swal2.fire({
        title: "Cargando",
        allowOutsideClick: false,
      });
      swal2.fire({
        title: "Cargando",
        allowOutsideClick: false,
      });
      swal2.showLoading();
      this.facturasReporte = [];
      this._fundersservice.getSimulacion(data).subscribe(
        async (resp) => {
          //vivo von el fondeador, aqui va la condicion !!!!!
          let funder = this.financieras.find(
            (financiera) => financiera.id_funder === Number(this.idfunder)
          );
          switch (funder.nombre_fondeador) {
            case "BANCO BASE":
              this._fundersservice
                .getBaseLayout(resp[0].id)
                .subscribe((resp2) => { this.armarRows(resp[0].id, resp2.resumen[0], resp2.detalles); }
                , err => {
                  Bugsnag.notify(new Error('Funders -> Solicitud de fondeo layout base'), function (event) {
                    event.severity = 'error'
                    event.context = 'Solicitud de fondeo creada, error en layout base'
                    event.addMetadata('Informacion regresada', { err, data, resp })
                    event.addMetadata('Datos del usuario', { 'id': localStorage.getItem('id'),
                                                   'nombre': localStorage.getItem('usuario'),
                                                   'correo': localStorage.getItem('emailuser')}) 
                  });
                });
              break;
            case "BANREGIO":
              this._fundersservice
                .getBanregioLayout(resp[0].id)
                .subscribe((resp2) => { this.exportExcelBanregio(resp[0].id, resp2, resp[0].folio);
                }, err => {
                  Bugsnag.notify(new Error('Funders -> Solicitud de fondeo layout banregio'), function (event) {
                    event.severity = 'error'
                    event.context = 'Solicitud de fondeo creada, error en layout banregio'
                    event.addMetadata('Informacion regresada', { err, data, resp })
                    event.addMetadata('Datos del usuario', { 'id': localStorage.getItem('id'),
                                                   'nombre': localStorage.getItem('usuario'),
                                                   'correo': localStorage.getItem('emailuser')}) 
                  });
                });
              break;
            case "BANCO INVEX":
              this._fundersservice.getInvexLayout_sup(resp[0].id).subscribe((resp2) => { this.exportExcelInvexSup(resp[0].id, resp2, "sup"); }
              , err => {
                Bugsnag.notify(new Error('Funders -> Solicitud de fondeo layout invex_sup'), function (event) {
                  event.severity = 'error'
                  event.context = 'Solicitud de fondeo creada, error en layout invex_sup'
                  event.addMetadata('Informacion regresada', { err, data, resp })
                  event.addMetadata('Datos del usuario', { 'id': localStorage.getItem('id'),
                                                   'nombre': localStorage.getItem('usuario'),
                                                   'correo': localStorage.getItem('emailuser')}) 
                });
              });
              this._fundersservice.getInvexLayout_fin(resp[0].id).subscribe((resp2) => {
                this.exportExcelInvex(resp[0].id, resp2, "fin");
                // this.anexoBsuplemento(resp[0].id, resp2);
              }, err => {
                Bugsnag.notify(new Error('Funders -> Solicitud de fondeo layout invex_fin'), function (event) {
                  event.severity = 'error'
                  event.context = 'Solicitud de fondeo creada, error en layout invex_fin'
                  event.addMetadata('Informacion regresada', { err, data, resp })
                  event.addMetadata('Datos del usuario', { 'id': localStorage.getItem('id'),
                                                   'nombre': localStorage.getItem('usuario'),
                                                   'correo': localStorage.getItem('emailuser')}) 
                });
              });
              setTimeout(() => {
                this._fundersservice.getEnviaMail(resp[0].id).subscribe((resp) => console.log(resp));
              }, 9000);
              break;

              case "BANCOPPEL":
                const id = resp[0].id;
                try {
                  await Promise.all([
                    this._fundersservice.getBancoppelLayout_rao(id).toPromise().then(resp2 =>
                      this.exportTxtBancoppelRao(id, resp2, "rao")
                      
                    ),
                    this._fundersservice.getBancoppelLayout_fin(id).toPromise().then(resp2 =>
                      this.exportExcelBancoppel(id, resp2, "fin")
                    )
                  ]);
                  const mailResponse = await this._fundersservice.getEnviaMail(id).toPromise();
                  console.log(mailResponse);
                } catch (error) {
                  Bugsnag.notify(new Error("Error en procesamiento de BANCOPPEL"), (event) => {
                    event.severity = "error";
                    event.addMetadata("Detalles", { error });
                  });
                }
                break;

            case "BANCO MONEX":
              this._fundersservice
                .getMonexLayout(resp[0].id)
                .subscribe((resp2) => {
                  this.exportExcelInvexMonex(resp[0].id, resp2, "fin");
                }, err => {
                  Bugsnag.notify(new Error('Funders -> Solicitud de fondeo layout layout monex'), function (event) {
                    event.severity = 'error'
                    event.context = 'Solicitud de fondeo creada, error en layout layout monex'
                    event.addMetadata('Informacion regresada', { err, data, resp })
                    event.addMetadata('Datos del usuario', { 'id': localStorage.getItem('id'),
                                                   'nombre': localStorage.getItem('usuario'),
                                                   'correo': localStorage.getItem('emailuser')}) 
                  });
                });
              break;

            default:
              this._fundersservice
                .getBaseLayout(resp[0].id)
                .subscribe((resp2) => {
                  this.armarRows(resp[0].id, resp2.resumen[0], resp2.detalles);
                }, err => {
                  Bugsnag.notify(new Error('Funders -> Solicitud de fondeo layout base'), function (event) {
                    event.severity = 'error'
                    event.context = 'Solicitud de fondeo creada, error en layout base PERO en default'
                    event.addMetadata('Informacion regresada', { err, data, resp })
                    event.addMetadata('Datos del usuario', { 'id': localStorage.getItem('id'),
                                                   'nombre': localStorage.getItem('usuario'),
                                                   'correo': localStorage.getItem('emailuser')}) 
                  });
                });;
              break;
          }

          swal2.close();
          swal2
            .fire({
              title: "Creacion de Solicitud Exitosa",
              text: resp[0].folio,
              icon: "success",
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: false,
            })
            .then((res) => {
              if (res.value) {
                this.load = false;
                this.ngOnInit();
              }
            });
        },
        (err) => {
          console.log(err);
          Bugsnag.notify(new Error('Funders -> Solicitud de fondeo'), function (event) {
            event.severity = 'error'
            event.context = 'En crear solicitud fondeo'
            event.addMetadata('Informacion regresada', { err, data }) 
            event.addMetadata('Datos del usuario', { 'id': localStorage.getItem('id'),
                                                   'nombre': localStorage.getItem('usuario'),
                                                   'correo': localStorage.getItem('emailuser')}) 
          });
          swal2
            .fire({
              title: "Ocurrio un error",
              text: err.error.errors[0],
              icon: "error",
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: false,
            })
            .then((res) => {
              if (res.value) {
                location.reload();
                this.load = false;
              }
            });
        }
      );
    }
  }

  armarRows(id, infoPrimerRow, infoSegundoRow, financiera = false) {
    this.ligaguardar = "";
    this.primerRow = [];
    this.segundoRow = [];
    this.detalles = [];
    this.ligaguardar =
      infoPrimerRow.numero_acuse + "/" + infoPrimerRow.numero_acuse + ".xls";
    this.primerRow.push(infoPrimerRow.epo);
    this.primerRow.push(infoPrimerRow.monto_descuento_mn);
    this.primerRow.push(infoPrimerRow.monto_intereses_mn);
    this.primerRow.push(infoPrimerRow.monto_operar_mn);
    this.primerRow.push(infoPrimerRow.monto_descuento_usd);
    this.primerRow.push(infoPrimerRow.monto_intereses_usd);
    this.primerRow.push(infoPrimerRow.monto_operar_usd);
    this.segundoRow.push(infoPrimerRow.numero_acuse);
    this.segundoRow.push(infoPrimerRow.fecha_carga);
    this.segundoRow.push(infoPrimerRow.hora_carga);
    this.segundoRow.push(infoPrimerRow.usuario_captura);
    // console.log(infoSegundoRow);
    this.detalles.push(infoSegundoRow);
    //console.log(this.detalles[0]);
    this.exportexcell(id, financiera);
  }

  enableconfirm() {
    this.confirma = !this.confirma;
  }

  recalculasinfiltro() {
    if (this.horalimite) {
      swal2
        .fire({
          title: "La hora límite para hacer solicitudes de factoraje es:",
          text: this.fechaHoyParametro,
          icon: "error",
          showConfirmButton: true,
          showCancelButton: false,
          allowOutsideClick: false,
        })
        .then((res) => {
          if (res.value) {
            location.reload();
          }
        });
    } else {
      if (this.selectedCars2.length === 0) {
        swal2.fire("Debe seleccionar al menos una factura", "", "info");
      } else {
        this.isActive = false;
        swal2.fire({
          title: "Cargando",
          allowOutsideClick: false,
        });
        swal2.showLoading();
        let total = 0;
        let fechaMayorFactura = "";
        let fechaMayorDueDate = "";

        const moneda: any = document.getElementById("moneda");

        const valormoneda = moneda.options[moneda.selectedIndex].value;
        // tslint:disable-next-line: forin
        for (const prop in this.selectedCars2) {
          total = total + parseFloat(this.selectedCars2[prop].total);
        }
        // Fecha de operación request date
        fechaMayorFactura = this.selectedCars2[0].invoice_date;
        for (const prop in this.selectedCars2) {
          if (fechaMayorFactura < this.selectedCars2[prop].invoice_date) {
            fechaMayorFactura = this.selectedCars2[prop].invoice_date;
          }
        }

        const fechafactura = fechaMayorFactura;
        //  console.log(fechafactura);

        // Fecha Factura used date
        const a = new Date(
          (document.getElementById("fechaoperacion") as HTMLInputElement).value
        );
        a.setMinutes(a.getMinutes() + a.getTimezoneOffset());
        let montha = "" + (a.getMonth() + 1);
        let daya = "" + a.getDate();
        const yeara = a.getFullYear();

        if (montha.length < 2) {
          montha = "0" + montha;
        }
        if (daya.length < 2) {
          daya = "0" + daya;
        }

        const fechaoperacion = [yeara, montha, daya].join("-");

        fechaMayorDueDate = this.selectedCars2[0].due_date;
        for (const prop in this.selectedCars2) {
          if (fechaMayorDueDate < this.selectedCars2[prop].due_date) {
            fechaMayorDueDate = this.selectedCars2[prop].due_date;
          }
        }
        this.idfunder = (
          document.getElementById("proveedor") as HTMLInputElement
        ).value;
        // TO DO: VALIDAR SI EN EL ARREGLO DE FACTURAS VIENE MAS DE 1 COMPANY DIFERENTE Y SI ES ASI ENTONCES CREAREMOS UNA FUNCION QUE GENERE UN FUNDING REQUEST POR CADA COMPANY_ID EN EL ARREGLO
        
        if (this.companyid != "noval"){
            this.companyid = this.companyid;
          }else{
            this.companyid = '';
            this.nombrecadena = '';
          }
        const paramssimul = {
          token: "",
          secret_key: "",
          simulation: true,
          invoices: [],
          funding_request: {
            funder_id: this.idfunder,
            company_id: this.companyid.toString(),
            user_id: this.idu,
            funding_request_date: this.fechaHoy,
            // funding_request_date: fechaoperacion,
            attached: "https://attached",
            currency: valormoneda,
          },
        };

        // tslint:disable-next-line: forin
        for (const prop in this.selectedCars2) {
          paramssimul.invoices[prop] = {
            id: this.selectedCars2[prop].id.toString(),
            percent: this.selectedCars2[prop].porcentaje,
          };
        }
        //  console.log(paramssimul);
        this.vienesinfiltro = true;
        try {
          this.nombrefondeador = (this.financieras.filter(p =>  p.id_funder == this.idfunder)[0].nombre_fondeador);
          this.mon = (valormoneda)
          this.fo = (fechaoperacion);
          if (this.companyid != "" ){
            this.nombrecadena = (this.cadenas.filter(p =>  p.id_cadena == this.companyid)[0].nombre_cadena);
          }else{
            this.nombrecadena = '';
          }

        } catch (error) {
          console.log(error);
        }
        
        this._fundersservice.getSimulacion(paramssimul).subscribe(
          (resp) => {
            this._fundersservice.getSimulacionparadetalle(paramssimul).subscribe(resp => {
              this.datostablapopup = [];
              for (const prop in this.selectedCars2) {
                for (const h in resp.data.relations.funding_request_invoices) {
                  if (this.selectedCars2[prop].id.toString() === resp.data.relations.funding_request_invoices[h].attributes.invoice_id.toString()) {
                    this.datostablapopup.push({
                      folio: this.selectedCars2[prop].invoice_folio,
                      uuid: this.selectedCars2[prop].uuid,
                      ext_rate: resp.data.relations.funding_request_invoices[h].attributes.ext_rate,
                      int_rate: resp.data.relations.funding_request_invoices[h].attributes.int_rate,
                      total_rate: resp.data.relations.funding_request_invoices[h].attributes.total_rate,
                      interests: resp.data.relations.funding_request_invoices[h].attributes.interests,
                      total_used: resp.data.relations.funding_request_invoices[h].attributes.total_used
                    })
                  }
                }
              }
            });
            swal2.close();
            let funder = this.financieras.find(
              (financiera) => financiera.id_funder === Number(this.idfunder)
            );
            if (funder.nombre_fondeador === 'BANCO INVEX') {
              this.muestraaviso = true;
            } else {
              this.muestraaviso = false;
            }
            this.simulacion = resp;
            const fecha1 = new Date(this.simulacion[0].used_date);
            const fecha2 = new Date(this.simulacion[0].due_date);
            const milisegundosdia = 24 * 60 * 60 * 1000;
            const milisegundostranscurridos = Math.abs(
              fecha1.getTime() - fecha2.getTime()
            );
            const diastranscurridos = Math.round(
              milisegundostranscurridos / milisegundosdia
            );
            this.simulacion[0].diastranscurridos = diastranscurridos;
            this.muestratablafirmantes = true;
            const totalformat = parseFloat(
              this.simulacion[0].total.replace(/,/g, "")
            );
            this.simulacion[0].total = totalformat
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            const totalusedformat = parseFloat(
              this.simulacion[0].total_used.replace(/,/g, "")
            );
            this.simulacion[0].total_used = totalusedformat
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            const interestsformat = parseFloat(
              this.simulacion[0].interests.replace(/,/g, "")
            );
            this.simulacion[0].interests = interestsformat
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            const netamountformat = parseFloat(
              this.simulacion[0].net_amount.replace(/,/g, "")
            );
            this.simulacion[0].net_amount = netamountformat
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
          (err) => {
            swal2.close();
            console.log(err);
            Bugsnag.notify(new Error('Funders -> Simulacion'), function (event) {
              event.severity = 'error'
              event.context = 'En crear solicitud fondeo'
              event.addMetadata('Informacion regresada', { err, paramssimul }) 
              event.addMetadata('Datos del usuario', { 'id': localStorage.getItem('id'),
                                                   'nombre': localStorage.getItem('usuario'),
                                                   'correo': localStorage.getItem('emailuser')}) 
            })
            swal2
              .fire({
                title: "Ocurrio un error",
                text: err.error.errors[0],
                icon: "error",
                showConfirmButton: true,
                showCancelButton: false,
                allowOutsideClick: false,
              })
              .then((res) => {
                if (res.value) {
                  location.reload();
                }
              });
          }
        );
      }
    }
  }

   recalculasinfiltromodomulticadena() {
    if (this.horalimite) {
      swal2
        .fire({
          title: "La hora límite para hacer solicitudes de factoraje es:",
          text: this.fechaHoyParametro,
          icon: "error",
          showConfirmButton: true,
          showCancelButton: false,
          allowOutsideClick: false,
        })
        .then((res) => {
          if (res.value) {
            location.reload();
          }
        });
    } else {
      if (this.selectedCars2.length === 0) {
        swal2.fire("Debe seleccionar al menos una factura", "", "info");
      } else {
        this.isActive = false;
        swal2.fire({
          title: "Cargando",
          allowOutsideClick: false,
        });
        swal2.showLoading();
        let total = 0;
        let fechaMayorFactura = "";
        let fechaMayorDueDate = "";

        const moneda: any = document.getElementById("moneda");

        const valormoneda = moneda.options[moneda.selectedIndex].value;
        // tslint:disable-next-line: forin
        for (const prop in this.selectedCars2) {
          total = total + parseFloat(this.selectedCars2[prop].total);
        }
        // Fecha de operación request date
        fechaMayorFactura = this.selectedCars2[0].invoice_date;
        for (const prop in this.selectedCars2) {
          if (fechaMayorFactura < this.selectedCars2[prop].invoice_date) {
            fechaMayorFactura = this.selectedCars2[prop].invoice_date;
          }
        }

        const fechafactura = fechaMayorFactura;
        //  console.log(fechafactura);

        // Fecha Factura used date
        const a = new Date(
          (document.getElementById("fechaoperacion") as HTMLInputElement).value
        );
        a.setMinutes(a.getMinutes() + a.getTimezoneOffset());
        let montha = "" + (a.getMonth() + 1);
        let daya = "" + a.getDate();
        const yeara = a.getFullYear();

        if (montha.length < 2) {
          montha = "0" + montha;
        }
        if (daya.length < 2) {
          daya = "0" + daya;
        }

        const fechaoperacion = [yeara, montha, daya].join("-");

        fechaMayorDueDate = this.selectedCars2[0].due_date;
        for (const prop in this.selectedCars2) {
          if (fechaMayorDueDate < this.selectedCars2[prop].due_date) {
            fechaMayorDueDate = this.selectedCars2[prop].due_date;
          }
        }
        this.idfunder = (
          document.getElementById("proveedor") as HTMLInputElement
        ).value;
        // TO DO: VALIDAR SI EN EL ARREGLO DE FACTURAS VIENE MAS DE 1 COMPANY DIFERENTE Y SI ES ASI ENTONCES CREAREMOS UNA FUNCION QUE GENERE UN FUNDING REQUEST POR CADA COMPANY_ID EN EL ARREGLO
        
        if (this.companyid != "noval"){
            this.companyid = this.companyid;
          }else{
            this.companyid = '';
            this.nombrecadena = '';
          }

        const paramssimul = {
          token: "",
          secret_key: "",
          simulation: true,
          invoices: [],
          funding_request: {
            funder_id: this.idfunder,
            company_id: this.companyid.toString(),
            user_id: this.idu,
            funding_request_date: this.fechaHoy,
            // funding_request_date: fechaoperacion,
            attached: "https://attached",
            currency: valormoneda,
          },
        };

        // tslint:disable-next-line: forin
        for (const prop in this.selectedCars2) {
          paramssimul.invoices[prop] = {
            id: this.selectedCars2[prop].id.toString(),
            percent: this.selectedCars2[prop].porcentaje,
          };
        }
        this.vienesinfiltro = true;
        try {
          this.nombrefondeador = (this.financieras.filter(p =>  p.id_funder == this.idfunder)[0].nombre_fondeador);
          this.mon = (valormoneda)
          this.fo = (fechaoperacion);
          if (this.companyid != "" ){
            this.nombrecadena = (this.cadenas.filter(p =>  p.id_cadena == this.companyid)[0].nombre_cadena);
          }else{
            this.nombrecadena = '';
          }

        } catch (error) {
          console.log(error);
        }
        // implementar un for segun los grupos de facturas https://stackoverflow.com/questions/64721885/how-to-make-multiple-http-requests-using-for-loop-typescript
        this._fundersservice.getSimulacion(paramssimul).subscribe(
          (resp) => {
            this._fundersservice.getSimulacionparadetalle(paramssimul).subscribe(resp => {
              this.datostablapopup = [];
              for (const prop in this.selectedCars2) {
                for (const h in resp.data.relations.funding_request_invoices) {
                  if (this.selectedCars2[prop].id.toString() === resp.data.relations.funding_request_invoices[h].attributes.invoice_id.toString()) {
                    this.datostablapopup.push({
                      folio: this.selectedCars2[prop].invoice_folio,
                      uuid: this.selectedCars2[prop].uuid,
                      ext_rate: resp.data.relations.funding_request_invoices[h].attributes.ext_rate,
                      int_rate: resp.data.relations.funding_request_invoices[h].attributes.int_rate,
                      total_rate: resp.data.relations.funding_request_invoices[h].attributes.total_rate,
                      interests: resp.data.relations.funding_request_invoices[h].attributes.interests,
                      total_used: resp.data.relations.funding_request_invoices[h].attributes.total_used
                    })
                  }
                }
              }
            });
            swal2.close();
            let funder = this.financieras.find(
              (financiera) => financiera.id_funder === Number(this.idfunder)
            );
            if (funder.nombre_fondeador === 'BANCO INVEX') {
              this.muestraaviso = true;
            } else {
              this.muestraaviso = false;
            }
            this.simulacion = resp;
            const fecha1 = new Date(this.simulacion[0].used_date);
            const fecha2 = new Date(this.simulacion[0].due_date);
            const milisegundosdia = 24 * 60 * 60 * 1000;
            const milisegundostranscurridos = Math.abs(
              fecha1.getTime() - fecha2.getTime()
            );
            const diastranscurridos = Math.round(
              milisegundostranscurridos / milisegundosdia
            );
            this.simulacion[0].diastranscurridos = diastranscurridos;
            this.muestratablafirmantes = true;
            const totalformat = parseFloat(
              this.simulacion[0].total.replace(/,/g, "")
            );
            this.simulacion[0].total = totalformat
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            const totalusedformat = parseFloat(
              this.simulacion[0].total_used.replace(/,/g, "")
            );
            this.simulacion[0].total_used = totalusedformat
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            const interestsformat = parseFloat(
              this.simulacion[0].interests.replace(/,/g, "")
            );
            this.simulacion[0].interests = interestsformat
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            const netamountformat = parseFloat(
              this.simulacion[0].net_amount.replace(/,/g, "")
            );
            this.simulacion[0].net_amount = netamountformat
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
          (err) => {
            swal2.close();
            console.log(err);
            Bugsnag.notify(new Error('Funders -> Simulacion'), function (event) {
              event.severity = 'error'
              event.context = 'En crear solicitud fondeo'
              event.addMetadata('Informacion regresada', { err, paramssimul }) 
              event.addMetadata('Datos del usuario', { 'id': localStorage.getItem('id'),
                                                   'nombre': localStorage.getItem('usuario'),
                                                   'correo': localStorage.getItem('emailuser')}) 
            })
            swal2
              .fire({
                title: "Ocurrio un error",
                text: err.error.errors[0],
                icon: "error",
                showConfirmButton: true,
                showCancelButton: false,
                allowOutsideClick: false,
              })
              .then((res) => {
                if (res.value) {
                  location.reload();
                }
              });
          }
        );
      }
    }
  }

  mandaAviso() {
    {
      if (this.selectedCars2.length === 0) {
        swal2.fire("Debe seleccionar al menos una factura", "", "info");
      } else {
        swal2.fire({
          title: "Cargando",
          allowOutsideClick: false,
        });
        swal2.showLoading();
        let total = 0;
        let fechaMayorFactura = "";
        let fechaMayorDueDate = "";
        const moneda: any = document.getElementById("moneda");
        const valormoneda = moneda.options[moneda.selectedIndex].value;
        // tslint:disable-next-line: forin
        for (const prop in this.selectedCars2) {
          total = total + parseFloat(this.selectedCars2[prop].total);
        }
        // Fecha de operación request date
        fechaMayorFactura = this.selectedCars2[0].invoice_date;
        for (const prop in this.selectedCars2) {
          if (fechaMayorFactura < this.selectedCars2[prop].invoice_date) {
            fechaMayorFactura = this.selectedCars2[prop].invoice_date;
          }
        }

        const fechafactura = fechaMayorFactura;
        //  console.log(fechafactura);

        // Fecha Factura used date
        const a = new Date(
          (document.getElementById("fechaoperacion") as HTMLInputElement).value
        );
        a.setMinutes(a.getMinutes() + a.getTimezoneOffset());
        let montha = "" + (a.getMonth() + 1);
        let daya = "" + a.getDate();
        const yeara = a.getFullYear();

        if (montha.length < 2) {
          montha = "0" + montha;
        }
        if (daya.length < 2) {
          daya = "0" + daya;
        }

        const fechaoperacion = [yeara, montha, daya].join("-");

        fechaMayorDueDate = this.selectedCars2[0].due_date;
        for (const prop in this.selectedCars2) {
          if (fechaMayorDueDate < this.selectedCars2[prop].due_date) {
            fechaMayorDueDate = this.selectedCars2[prop].due_date;
          }
        }
        this.idfunder = (
          document.getElementById("proveedor") as HTMLInputElement
        ).value;
        const paramssimul = {
          token: "",
          secret_key: "",
          simulation: "notifi",
          invoices: [],
          funding_request: {
            funder_id: this.idfunder,
            company_id: this.companyid.toString(),
            user_id: this.idu,
            funding_request_date: this.fechaHoy,
            // funding_request_date: fechaoperacion,
            attached: "https://attached",
            currency: valormoneda,
          },
        };

        // tslint:disable-next-line: forin
        for (const prop in this.selectedCars2) {
          paramssimul.invoices[prop] = {
            id: this.selectedCars2[prop].id.toString(),
            percent: this.selectedCars2[prop].porcentaje,
          };
        }
        this._fundersservice.getSimulacion(paramssimul).subscribe(
          (resp) => {
            swal2.fire('Notificacion enviada', '', 'info')
            console.log(resp);

          },
          (err) => {
            swal2.close();
            console.log(err);
            swal2
              .fire({
                title: "Ocurrio un error",
                text: err.error.errors[0],
                icon: "error",
                showConfirmButton: true,
                showCancelButton: false,
                allowOutsideClick: false,
              })
              .then((res) => {
                if (res.value) {
                  location.reload();
                }
              });
          }
        );
      }
    }
  }

  exportExcelInvexMonex(ids, detalles, usr) {
    const Excel = require("exceljs");
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet("Solicitud de Fondos");
    let header = [
      "No. Factura",
      "Imp. Neto",
      "Fec/Exp.",
      "Fec/Rev.",
      "Fec/Venc",
      "Tipo Docto",
      "Cedido",
      "% Anticipo",
      "UUID",
      "ID_RP",
      "Proveedor Relacionado",
      "RFC emisor",
      "RFC receptor",
    ];
    worksheet.addRow(header);
    detalles.forEach((d) => {
      worksheet.addRow([
        d.no_factura,
        parseFloat(d.imp_neto).toFixed(2),
        d.fecha_exp,
        d.fecha_rev,
        d.fecha_venc,
        d.tipo_docto,
        d.cedido,
        parseFloat(d.anticipo).toFixed(2),
        d.uuid,
        d.id_rp,
        d.proveedor_relacionado,
        d.rfc_emisor,
        d.rfc_receptor,
      ]);
    });
    worksheet.columns = [
      { header: "No. Factura", width: 17 },
      { header: "Imp. Neto", width: 17 },
      { header: "Fec/Exp.", width: 17 },
      { header: "Fec/Rev.", width: 17 },
      { header: "Fec/Venc", width: 17 },
      { header: "Tipo Docto", width: 15 },
      { header: "Cedido", width: 15 },
      { header: "% Anticipo", width: 17 },
      { header: "UUID", width: 37 },
      { header: "ID_RP", width: 17},
      { header: "Proveedor Relacionado", width: 21 },
      { header: "RFC emisor", width: 17 },
      { header: "RFC receptor", width: 17 },
    ];
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: ".xls" });
      const file = blob;
      // var FileSaver = require('file-saver');
      // FileSaver.saveAs(blob, `ejemplo.xlsx`);
      const filepath =
        detalles[0].file_name +
        "_" +
        usr +
        "/" +
        detalles[0].file_name +
        ".xls";
      this.fbEmail(filepath, file, ids, usr);
    });
  }

  exportExcelInvex(ids, detalles, usr) {
    const Excel = require("exceljs");
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet("Solicitud de Fondos");
    let header = [
      "No. Factura",
      "Imp. Neto",
      "Fec/Exp.",
      "Fec/Rev.",
      "Fec/Venc",
      "Tipo Docto",
      "Cedido",
      "% Anticipo",
      "UUID",
      "ID RP",
      "Proveedor Relacionado",
    ];
    worksheet.addRow(header);
    detalles.forEach((d) => {
      worksheet.addRow([
        d.no_factura,
        parseFloat(d.imp_neto).toFixed(2),
        d.fecha_exp,
        d.fecha_rev,
        d.fecha_venc,
        d.tipo_docto,
        d.cedido,
        parseFloat(d.anticipo).toFixed(2),
        d.uuid,
        d.id_rp,
        d.proveedor_relacionado,
      ]);
    });
    worksheet.columns = [
      { header: "No. Factura", width: 17 },
      { header: "Imp. Neto", width: 17 },
      { header: "Fec/Exp.", width: 17 },
      { header: "Fec/Rev.", width: 17 },
      { header: "Fec/Venc", width: 17 },
      { header: "Tipo Docto", width: 15 },
      { header: "Cedido", width: 15 },
      { header: "% Anticipo", width: 17 },
      { header: "UUID", width: 37 },
      { header: "ID RP", width: 17 },
      { header: "Proveedor Relacionado", width: 21 },
    ];
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: ".xls" });
      const file = blob;
      // var FileSaver = require('file-saver');
      // FileSaver.saveAs(blob, `ejemplo.xlsx`);
      const filepath =
        detalles[0].file_name +
        "_" +
        usr +
        "/" +
        detalles[0].file_name +
        ".xls";
      this.fbAtachedInvex(filepath, file, ids, usr);
    });
  }

  exportExcelBancoppel(ids, detalles, usr) {
    const Excel = require("exceljs");
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet("Solicitud de Fondos");
    let header = [
      "Cliente",
      "RFC del Cliente",
      "Proveedor",
      "RFC del Proveedor",
      "Núm de Documento",
      "Folio Fiscal (UUID)",
      "Fecha de Operación",
      "Fecha de Vto.",
      "Plazo",
      "Monto del Descuento",
      "Tasa de Descuento",
      "Monto de Interes",
      "Monto a Financiar",
      "Moneda",
      "Objeto Impuestos",
      "ISR_RetencionesDR_BaseDR",
      "ISR_RetencionesDR_ImpuestoDR",
      "ISR_RetencionesDR_TipoFactorDR",
      "ISR_RetencionesDR_TasaOCuotaDR_Valor",
      "ISR_RetencionesDR_ImporteDR",
      "IVA_RetencionesDR_BaseDR",
      "IVA_RetencionesDR_ImpuestoDR",
      "IVA_RetencionesDR_TipoFactorDR",
      "IVA_RetencionesDR_TasaOCuotaDR_Valor",
      "IVA_RetencionesDR_ImporteDR",
      "IVA_TrasladosDR_BaseDR",
      "IVA_TrasladosDR_ImpuestoDR",
      "IVA_TrasladosDR_TipoFactorDR",
      "IVA_TrasladosDR_TasaOCuotaDR_Valor",
      "IVA_TrasladosDR_ImporteDR",
      "IEPS_RetencionesDR_BaseDR",
      "IEPS_RetencionesDR_ImpuestoDR",
      "IEPS_RetencionesDR_TipoFactorDR",
      "IEPS_RetencionesDR_TasaOCuotaDR_Valor",
      "IEPS_RetencionesDR_ImporteDR",
      "IEPS_TrasladosDR_BaseDR",
      "IEPS_TrasladosDR_ImpuestoDR",
      "IEPS_TrasladosDR_TipoFactorDR",
      "IEPS_TrasladosDR_TasaOCuotaDR_Valor",
      "IEPS_TrasladosDR_ImporteDR",

    ];
    worksheet.addRow(header);
    detalles[0].forEach((d) => {
      worksheet.addRow([
        d.cliente,
        d.rfc_del_cliente,
        d.proveedor,
        d.rfc_del_proveedor,
        d.numero_de_documento,
        d.folio_fiscal,
        d.fecha_operacion,
        d.fecha_de_vto,
        d.plazo,
        d.monto_del_descuento,
        d.tasa_de_descuento,
        d.monto_de_interes,
        d.monto_a_financiar,
        d.moneda,
        d.objeto_impuestos,
        d.isr_retencionesdr_basedr,
        d.isr_retencionesdr_impuestodr,
        d.isr_retencionesdr_tipofactordr,
        d.isr_retencionesdr_tasaocuotadr_valor,
        d.isr_retencionesdr_importedr,
        d.iva_retencionesdr_basedr,
        d.iva_retencionesdr_impuestodr,
        d.iva_retencionesdr_tipofactordr,
        d.iva_retencionesdr_tasaocuotadr_valor,
        d.iva_retencionesdr_importedr,
        d.iva_trasladosdr_basedr,
        d.iva_trasladosdr_impuestodr,
        d.iva_trasladosdr_tipofactordr,
        d.iva_trasladosdr_tasaocuotadr_valor,
        d.iva_trasladosdr_importedr,
        d.ieps_retencionesdr_basedr,
        d.ieps_retencionesdr_impuestodr,
        d.ieps_retencionesdr_tipofactordr,
        d.ieps_retencionesdr_tasaocuotadr_valor,
        d.ieps_retencionesdr_importedr,
        d.ieps_trasladosdr_basedr,
        d.ieps_trasladosdr_impuestodr,
        d.ieps_trasladosdr_tipofactordr,
        d.ieps_trasladosdr_tasaocuotadr_valor,
        d.ieps_trasladosdr_importedr
      ]);
    });
    worksheet.columns = [

      { header: "Cliente", width: 17 },
      { header: "RFC del Cliente", width: 17 },
      { header: "Proveedor", width: 17 },
      { header: "RFC del Proveedor", width: 17 },
      { header: "Núm de Documento", width: 17 },
      { header: "Folio Fiscal (UUID)", width: 17 },
      { header: "Fecha de Operación", width: 17 },
      { header: "Fecha de Vto.", width: 17 },
      { header: "Plazo", width: 17 },
      { header: "Monto del Descuento", width: 17 },
      { header: "Tasa de Descuento", width: 17 },
      { header: "Monto de Interes", width: 17 },
      { header: "Monto a Financiar", width: 17 },
      { header: "Moneda", width: 17 },
      { header: "Objeto Impuestos", width: 17 },
      { header: "ISR_RetencionesDR_BaseDR", width: 17 },
      { header: "ISR_RetencionesDR_ImpuestoDR", width: 17 },
      { header: "ISR_RetencionesDR_TipoFactorDR", width: 17 },
      { header: "ISR_RetencionesDR_TasaOCuotaDR_Valor", width: 17 },
      { header: "ISR_RetencionesDR_ImporteDR", width: 17 },
      { header: "IVA_RetencionesDR_BaseDR", width: 17 },
      { header: "IVA_RetencionesDR_ImpuestoDR", width: 17 },
      { header: "IVA_RetencionesDR_TipoFactorDR", width: 17 },
      { header: "IVA_RetencionesDR_TasaOCuotaDR_Valor", width: 17 },
      { header: "IVA_RetencionesDR_ImporteDR", width: 17 },
      { header: "IVA_TrasladosDR_BaseDR", width: 17 },
      { header: "IVA_TrasladosDR_ImpuestoDR", width: 17 },
      { header: "IVA_TrasladosDR_TipoFactorDR", width: 17 },
      { header: "IVA_TrasladosDR_TasaOCuotaDR_Valor", width: 17 },
      { header: "IVA_TrasladosDR_ImporteDR", width: 17 },
      { header: "IEPS_RetencionesDR_BaseDR", width: 17 },
      { header: "IEPS_RetencionesDR_ImpuestoDR", width: 17 },
      { header: "IEPS_RetencionesDR_TipoFactorDR", width: 17 },
      { header: "IEPS_RetencionesDR_TasaOCuotaDR_Valor", width: 17 },
      { header: "IEPS_RetencionesDR_ImporteDR", width: 17 },
      { header: "IEPS_TrasladosDR_BaseDR", width: 17 },
      { header: "IEPS_TrasladosDR_ImpuestoDR", width: 17 },
      { header: "IEPS_TrasladosDR_TipoFactorDR", width: 17 },
      { header: "IEPS_TrasladosDR_TasaOCuotaDR_Valor", width: 17 },
      { header: "IEPS_TrasladosDR_ImporteDR", width: 17 }

    ];
    workbook.xlsx.writeBuffer().then(async (data) => {
      let blob = new Blob([data], { type: ".xls" });
      const file = blob;
      // var FileSaver = require('file-saver');
      // FileSaver.saveAs(blob, `ejemplo.xlsx`);
      const filepath =
        usr +
        "/" +
        detalles[0][0].file_name +
        ".xls";
      await this.fbAtachedBancoppel(filepath, file, ids, usr);
    });
  }

  async exportTxtBancoppelRao(ids, detalles, folio) {
    let cadena: string = "";
    let fileName = detalles[0][0].id;
    for await (const prop of detalles[0]) {
      cadena =
        cadena +
        prop.id +
        "|" +
        prop.numero +
                "|" +
        prop.numero_cliente +
                "|" +
        prop.numero_sucursal +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
        prop.numero_linea +
                "|" +
        prop.numero_producto +
                "|" +
        prop.tipo_cuenta_eje +
                "|" +
        prop.numero_cuenta_eje +
                "|" +
        prop.fecha_apertura +
                "|" +
        parseFloat(prop.monto_autorizado).toFixed(2) +
                "|" +
        prop.fecha_primer_pago +
                "|" +
        prop.numero_pagos +
                "|" +
        prop.periodicidad_pagos +
                "|" +
        prop.dia_pago_1 +
                "|" +
        prop.dia_pago_2 +
                "|" +
        prop.periodos_gracia_capital +
                "|" +
        prop.periodos_gracia_cap_int +
                "|" +
        prop.ind_renovacion +
                "|" +
        prop.ind_reestructura +
                "|" +
        prop.io_tipo_de_tasa +
                "|" +
        prop.io_tasa +
                "|" +
        prop.io_tv_tasa_referencia +
                "|" +
        prop.io_tv_puntos_tasa +
                "|" +
        prop.io_tv_factor_tasa +
                "|" +
        prop.io_dia_rev_1 +
                "|" +
        prop.io_dia_rev_2 +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
        prop.im_dia_rev_1 +
                "|" +
        prop.im_dia_rev_2 +
                "|" +
        prop.dia_pago_1_interes +
                "|" +
        prop.dia_pago_2_interes +
                "|" +
        prop.numero_pagos_interes +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
                ""  +
                "|" +
                ""  +
                "|"         
           ;
    }
    let blob = new Blob([cadena], { type: "text" });
    const file = blob;
    const filepath = fileName + "/" + fileName;
    const usr ='rao'
    // var FileSaver = require("file-saver");
    // FileSaver.saveAs(blob, `ejemplo.txt`);
    await this.fbAtachedBancoppel(filepath, file, ids,usr);
  }

  exportExcelInvexSup(ids, detalles, usr) {
    const Excel = require("exceljs");
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet("Solicitud de Fondos");
    let header = [
      "Fecha",
      "Número del Banco Receptor",
      "Tipo de Pago",
      "Importe",
      "Clave de Rastreo",
      "Nombre del Ordenante",
      "Tipo de Cuenta del Ordenante",
      "Número de Contrato del Ordenante",
      "RFC o CURP del Ordenante",
      "Nombre del Receptor",
      "Tipo de Cuenta del Receptor",
      "Número de Cuenta del Receptor",
      "RFC o CURP de Receptor",
      "Concepto de Pago del Ordenarte",
      "IVA",
      "Referencia Numérica del Ordenante",
      "Referencia Cobranza",
    ];
    worksheet.addRow(header);
    detalles.forEach((d) => {
      worksheet.addRow([
        d.fecha,
        d.no_banco_receptor,
        d.tipo_pago,
        parseFloat(d.importe).toFixed(2),
        d.clave_registro,
        d.nombre_ordenante,
        d.tipo_cuenta_ordenante,
        d.numero_contrato_ordenante,
        d.rfc_ordenante,
        d.nombre_receptor,
        d.tipo_cuenta_receptor,
        d.numero_cuenta_receptor,
        d.rfc_receptor,
        d.concepto_pago,
        d.iva,
        d.referencia_numerica_ordenante,
        d.referencia_cobranza,
      ]);
    });

    worksheet.columns = [
      { header: "Fecha", width: 15 },
      { header: "Número del Banco Receptor", width: 30 },
      { header: "Tipo de Pago", width: 17 },
      { header: "Importe", width: 15 },
      { header: "Clave de Rastreo", width: 25 },
      { header: "Nombre del Ordenante", width: 30 },
      { header: "Tipo de Cuenta del Ordenante", width: 30 },
      { header: "Número de Contrato del Ordenante", width: 32 },
      { header: "RFC o CURP del Ordenante", width: 27 },
      { header: "Nombre del Receptor", width: 27 },
      { header: "Tipo de Cuenta del Receptor", width: 30 },
      { header: "Número de Cuenta del Receptor", width: 30 },
      { header: "RFC o CURP de Receptor", width: 25 },
      { header: "Concepto de Pago del Ordenarte", width: 30 },
      { header: "IVA", width: 10 },
      { header: "Referencia Numérica del Ordenante", width: 32 },
      { header: "Referencia Cobranza", width: 25 },
    ];

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: ".xls" });
      const file = blob;
      // var FileSaver = require('file-saver');
      // FileSaver.saveAs(blob, `ejemplo.xlsx`);
      const filepath =
        detalles[0].file_name +
        "_" +
        usr +
        "/" +
        detalles[0].file_name +
        ".xls";
      this.fbAtachedInvex(filepath, file, ids, usr);
    });
  }

  fbAtachedInvex(filepath, file, ids, usr = "base") {
    const fileRef = this._firestorage.ref(filepath);
    this._firestorage.upload(filepath, file).then(() => {
      fileRef.getDownloadURL().subscribe((resp) => {
        console.log("up", resp);
        this.uploadURL = resp;
        const params = {
          token: "",
          secret_key: "",
        };
        if (usr === "anb") {
          params["extra2"] = this.uploadURL;
          console.log("extra 2");
        } else if (usr === "sup") {
          params["extra1"] = this.uploadURL;
          console.log("extra 1");
        } else {
          params["attached"] = this.uploadURL;
          console.log("attached");
        }
        this._fundersservice.agregaattached(ids, params).subscribe(
          (err) => {
            console.log(err);
          }
        );
      });
    });
  }

  fbAtachedBancoppel(filepath, file, ids, usr = "anb"): Promise<void> {
    const fileRef = this._firestorage.ref(filepath);
    return this._firestorage.upload(filepath, file).then(() => {
      return fileRef.getDownloadURL().toPromise().then((resp) => {
        const params = {
          token: "",
          secret_key: "",
        };
        if (usr === "fin") {
          params["extra2"] = resp;
        } else if (usr === "rao") {
          params["extra1"] = resp;
        } else {
          params["attached"] = resp;
        }
        return this._fundersservice.agregaattached(ids, params).toPromise();
      });
    });
  }

  /*fbAtachedBancoppel(filepath, file, ids, usr = "anb") {
    // console.log("fbAtachedBancoppel")
    // console.log(filepath)
    // console.log("usr")
    // console.log(usr)
    // console.log("ids")
    // console.log(ids)
    const fileRef = this._firestorage.ref(filepath);
    this._firestorage.upload(filepath, file).then(() => {
      fileRef.getDownloadURL().subscribe((resp) => {
        // console.log("up", resp);
        this.uploadURL = resp;
        const params = {
          token: "",
          secret_key: "",
        };
        if (usr === "fin") {
          params["extra2"] = this.uploadURL;
          // console.log("extra 2");
        } else if (usr === "rao") {
          params["extra1"] = this.uploadURL;
          // console.log("extra1");
        } else {
          params["attached"] = this.uploadURL;
          // console.log("attached");
        }
        this._fundersservice.agregaattached(ids, params).subscribe(
          (err) => {
            console.log(err);
          }
        );
      });
    });
  }*/

  fbEmail(filepath, file, ids, usr = "base") {
    const fileRef = this._firestorage.ref(filepath);
    this._firestorage.upload(filepath, file).then(() => {
      fileRef.getDownloadURL().subscribe((resp) => {
        console.log("up", resp);
        this.uploadURL = resp;
        const params = {
          token: "",
          secret_key: "",
        };
        if (usr === "anb") {
          params["extra2"] = this.uploadURL;
          console.log("extra 2");
        } else if (usr === "sup") {
          params["extra1"] = this.uploadURL;
          console.log("extra");
        } else {
          params["attached"] = this.uploadURL;
          console.log("attached");
        }
        this._fundersservice.agregaattached(ids, params).subscribe(
          () =>
            this._fundersservice
              .getEnviaMail(ids)
              .subscribe((resp) => console.log(resp)),
          (err) => {
            console.log(err);
          }

        );
      });
    });
  }

  exportexcell(ids, financiera) {
    // OJO AQUI //////
    //console.log(this.segundoRow);
    if (this.segundoRow[3] === null) {
      this.segundoRow[3] = "Factor GFC";
    }
    if (this.primerRow[0] === null) {
      this.primerRow[0] = 5080;
    }
    let formatoFecha;
    if (!financiera) {
      //  PARA LAS FECHAS DE UN ROW//////
      let fechacarga = this.segundoRow[1];
      // console.log(fechacarga);
      let fec = fechacarga.split("/");
      let horacarga = this.segundoRow[2].toString();
      //console.log(horacarga);
      if (horacarga.includes("pm")) {
        let hcarga = horacarga.substring(0, 8);
        //console.log(hcarga);
        hcarga = hcarga + " PM";
        //console.log(hcarga);
        this.segundoRow[2] = hcarga;
      } else if (horacarga.includes("am")) {
        let hcarga = horacarga.substring(0, 8);
        // console.log(hcarga);
        hcarga = hcarga + " AM";
        //console.log(hcarga);
        this.segundoRow[2] = hcarga;
      }
      formatoFecha = new Date(`${fec[2]}, ${fec[1]}, ${fec[0]} GMT-0000`);
      // FIN FECHAS DE UN ROW ///////////
    } else {
      formatoFecha = this.segundoRow[1];
    }
    let numberrow = 8;
    const Excel = require("exceljs");
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet("Movimientos_Descontados");
    let header = [
      "EPO",
      "Monto Descuento M.N.",
      "Monto de Intereses M.N.",
      "Monto a Operar M.N.",
      "Monto Descuento USD",
      "Monto de Intereses USD",
      "Monto a Operar USD",
    ];
    //  console.log(header);
    let headerRow = worksheet.addRow(header);
    worksheet.addRow(this.primerRow);
    worksheet.addRow([]);
    worksheet.addRow([
      "Número de Acuse",
      "Fecha de Carga",
      "Hora de Carga",
      "Usuario de Captura",
    ]);
    worksheet.addRow(this.segundoRow);
    worksheet.addRow([]);
    let header3 = [
      "No. Cliente SIRAC",
      "Proveedor",
      "Número de documento",
      "Fecha de Emision",
      "Fecha de Vencimiento",
      "Moneda",
      "Monto",
      "Monto Descuento",
      "Monto Interés",
      "Monto a Operar",
      "Tasa",
      "Plazo",
      "Folio",
      "No. Proveedor",
      "Porcentaje de Descuento",
      "Recurso en Garantia",
    ];
    worksheet.addRow(header3);
    // tslint:disable-next-line: forin
    this.detalles[0].forEach((detalle) => {
      // for (const prop in this.detalles) {
      let formatoFechaEmi;
      let formatoFechaVen;
      if (!financiera) {
        let fechaemision = detalle.fecha_emision;
        let fecemi = fechaemision.split("/");
        let fechavencimineto = detalle.fecha_vencimiento;
        let fecven = fechavencimineto.split("/");
        formatoFechaEmi = new Date(
          `${fecemi[2]}, ${fecemi[1]}, ${fecemi[0]} GMT-0000`
        );
        formatoFechaVen = new Date(
          `${fecven[2]}, ${fecven[1]}, ${fecven[0]} GMT-0000`
        );
      } else {
        formatoFechaEmi = detalle.fecha_emision;
        formatoFechaVen = detalle.fecha_vencimiento;
      }
      if (detalle.moneda === "MXN") {
        detalle.moneda = "MONEDA NACIONAL";
      }
      worksheet.addRow([
        detalle.sirac,
        detalle.proveedor,
        detalle.funding_invoice_group,
        detalle.fecha_emision,
        detalle.fecha_vencimiento,
        detalle.moneda,
        detalle.monto,
        detalle.monto_descuento,
        detalle.monto_intereses,
        detalle.monto_operar,
        detalle.tasa,
        detalle.plazo,
        detalle.folio,
        detalle.no_proveedor,
        detalle.porcentaje_descuento,
        detalle.recurso_garantia,
      ]);
      worksheet.getCell("A" + numberrow).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };
      worksheet.getCell("A" + numberrow).value = 1; //OJO AQUI///////////////////////
      worksheet.getCell("C" + numberrow).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };
      worksheet.getCell("C" + numberrow).value = parseFloat(
        worksheet.getCell("C" + numberrow).value
      );
      worksheet.getCell("D" + numberrow).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };
      worksheet.getCell("D" + numberrow).value = formatoFechaEmi;
      worksheet.getCell("E" + numberrow).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };
      worksheet.getCell("E" + numberrow).value = formatoFechaVen;
      worksheet.getCell("G" + numberrow).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };
      worksheet.getCell("G" + numberrow).value = parseFloat(
        worksheet.getCell("G" + numberrow).value
      );
      worksheet.getCell("H" + numberrow).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };
      worksheet.getCell("H" + numberrow).value = parseFloat(
        worksheet.getCell("H" + numberrow).value
      );
      worksheet.getCell("I" + numberrow).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };
      worksheet.getCell("I" + numberrow).value = parseFloat(
        worksheet.getCell("I" + numberrow).value
      );
      worksheet.getCell("J" + numberrow).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };
      worksheet.getCell("J" + numberrow).value = parseFloat(
        worksheet.getCell("J" + numberrow).value
      );
      worksheet.getCell("K" + numberrow).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };
      worksheet.getCell("K" + numberrow).value = parseFloat(
        worksheet.getCell("K" + numberrow).value
      );
      worksheet.getCell("L" + numberrow).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };
      worksheet.getCell("M" + numberrow).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };
      worksheet.getCell("N" + numberrow).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };
      worksheet.getCell("O" + numberrow).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };
      worksheet.getCell("O" + numberrow).value = parseFloat(
        worksheet.getCell("O" + numberrow).value
      );
      worksheet.getCell("P" + numberrow).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };
      numberrow = numberrow + 1;
    });
    // FORMATEAR CELDAS ///////////////////////////
    worksheet.getCell("A2").alignment = {
      vertical: "bottom",
      horizontal: "right",
    };
    worksheet.getCell("B2").alignment = {
      vertical: "bottom",
      horizontal: "right",
    };
    worksheet.getCell("C2").alignment = {
      vertical: "bottom",
      horizontal: "right",
    };
    worksheet.getCell("D2").alignment = {
      vertical: "bottom",
      horizontal: "right",
    };
    worksheet.getCell("E2").alignment = {
      vertical: "bottom",
      horizontal: "right",
    };
    worksheet.getCell("F2").alignment = {
      vertical: "bottom",
      horizontal: "right",
    };
    worksheet.getCell("G2").alignment = {
      vertical: "bottom",
      horizontal: "right",
    };
    worksheet.getCell("B5").alignment = {
      vertical: "bottom",
      horizontal: "right",
    };
    worksheet.getCell("B5").value = formatoFecha;
    worksheet.getCell("C5").alignment = {
      vertical: "bottom",
      horizontal: "right",
    };
    worksheet.getCell("B2").value = parseFloat(worksheet.getCell("B2").value);
    worksheet.getCell("C2").value = parseFloat(worksheet.getCell("C2").value);
    worksheet.getCell("D2").value = parseFloat(worksheet.getCell("D2").value);
    worksheet.getCell("E2").value = parseFloat(worksheet.getCell("E2").value);
    worksheet.getCell("F2").value = parseFloat(worksheet.getCell("F2").value);
    worksheet.getCell("G2").value = parseFloat(worksheet.getCell("G2").value);
    worksheet.columns = [
      { header: "EPO", width: 21 },
      { header: "Monto Descuento M.N.", width: 21 },
      { header: "Monto de Intereses M.N.", width: 22 },
      { header: "Monto a Operar M.N.", width: 21 },
      { header: "Monto Descuento USD", width: 21 },
      { header: "Monto de Intereses USD", width: 22 },
      { header: "Monto a Operar USD", width: 23 },
      { header: "", width: 16 },
      { header: "", width: 15 },
      { header: "", width: 14 },
      { header: "", width: 7 },
      { header: "", width: 7 },
      { header: "", width: 7 },
      { header: "", width: 15 },
      { header: "", width: 23 },
      { header: "", width: 19 },
    ];
    //////////////////////////////////////////////
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: ".xls" });
      const file = blob;
      const filepath = this.ligaguardar;

      // var FileSaver = require('file-saver');
      // FileSaver.saveAs(blob, `ejemplo.xlsx`);
      this.fbEmail(filepath, file, ids);
    });
  }

  anexoBsuplemento(ids, resp) {
    this._fundersservice.fr_layout_anexo_b(ids).subscribe(respanb => {
      let fecha_efectiva = respanb[0].fecha_efectiva.split('/');
      let fecha_contrato = respanb[0].fecha_contrato.split('/');
      switch (fecha_efectiva[1]) {
        case "01":
          fecha_efectiva[1] = 'Enero';
          break;
        case "02":
          fecha_efectiva[1] = 'Febrero';
          break;
        case "03":
          fecha_efectiva[1] = 'Marzo';
          break;
        case "04":
          fecha_efectiva[1] = 'Abril';
          break;
        case "05":
          fecha_efectiva[1] = 'Mayo';
          break;
        case "06":
          fecha_efectiva[1] = 'Junio';
          break;
        case "07":
          fecha_efectiva[1] = 'Julio';
          break;
        case "08":
          fecha_efectiva[1] = 'Agosto';
          break;
        case "09":
          fecha_efectiva[1] = 'Septiembre';
          break;
        case "10":
          fecha_efectiva[1] = 'Octubre';
          break;
        case "11":
          fecha_efectiva[1] = 'Noviembre';
          break;
        case "12":
          fecha_efectiva[1] = 'Diciembre';
          break;
      }
      switch (fecha_contrato[1]) {
        case "01":
          fecha_contrato[1] = 'Enero';
          break;
        case "02":
          fecha_contrato[1] = 'Febrero';
          break;
        case "03":
          fecha_contrato[1] = 'Marzo';
          break;
        case "04":
          fecha_contrato[1] = 'Abril';
          break;
        case "05":
          fecha_contrato[1] = 'Mayo';
          break;
        case "06":
          fecha_contrato[1] = 'Junio';
          break;
        case "07":
          fecha_contrato[1] = 'Julio';
          break;
        case "08":
          fecha_contrato[1] = 'Agosto';
          break;
        case "09":
          fecha_contrato[1] = 'Septiembre';
          break;
        case "10":
          fecha_contrato[1] = 'Octubre';
          break;
        case "11":
          fecha_contrato[1] = 'Noviembre';
          break;
        case "12":
          fecha_contrato[1] = 'Diciembre';
          break;
      }
      var dd = {
        styles: {
          table: {
            fontSize: 9
          }
        },
        pageMargins: [60, 60, 60, 60],
        content: [
          { text: `ANEXO "B"`, alignment: 'center', fontSize: 11 },
          { text: '\n' },
          { text: `FORMATO DE SUPLEMENTO`, alignment: 'center', fontSize: 11 },
          { text: '\n' },
          { text: `Suplemento al Contrato Marco de Factoraje Financiero, de fecha ${fecha_contrato[0]} de ${fecha_contrato[1]} de ${fecha_contrato[2]} (el “Contrato Marco de Factoraje Financiero”) celebrado entre BANCO INVEX, S.A., INSTITUCIÓN DE BANCA MÚLTIPLE, INVEX GRUPO FINANCIERO (el “Factorante”) y FACTOR GFC GLOBAL, S.A. DE C.V., SOFOM, E.N.R. (el “Factorado”). Este Suplemento es parte integrante del Contrato Marco de Factoraje Financiero.`, alignment: 'justify', fontSize: 11 },
          { text: '\n' },
          { text: `Los términos que no se definen en el presente Suplemento tendrán el significado que se les atribuye en el Contrato Marco de Factoraje Financiero. `, alignment: 'justify', fontSize: 11 },
          { text: '\n' },
          { text: `El Factorado en este acto declara, a través de su representante legal, que (i) celebró el Contrato Marco de Factoraje Financiero, (ii) todas y cada una de sus declaraciones contenidas en el Contrato Marco de Factoraje Financiero son verdaderas, correctas y precisas en la fecha de celebración del presente Suplemento, y (iii) se encuentra en cumplimiento total y puntual de todas y cada una de sus obligaciones conforme al Contrato Marco de Factoraje Financiero.`, alignment: 'justify', fontSize: 11 },
          { text: '\n' },
          { text: `Conforme a los términos del Contrato Marco de Factoraje Financiero, el Factorado transmitió, vendió y cedió irrevocablemente al Factorante, quien adquirió por medio de las Notificaciones del Factorante respectivas los Derechos de Crédito que se detallan en el presente Suplemento, sin reserva ni limitación alguna, los cuales incluyen todos los derechos accesorios a dichos Derechos de Crédito. `, alignment: 'justify', fontSize: 11 },
          { text: '\n' },
          {
            style: 'tabla',
            table: {
              widths: [50, 51, 50, 51, 50, 51, 50, 51],
              body: [
                [{ text: `DOCUMENTOS SOLICITADOS A FACTOR GLOBAL`, alignment: 'justify', fontSize: 9 }, { text: `DOC`, alignment: 'justify', fontSize: 9 }, { text: `FECHA DE VENCIMIENTO`, alignment: 'justify', fontSize: 9 }, { text: `FECHA DE TRANSACCION`, alignment: 'justify', fontSize: 9 }, { text: `FECHA EFECTIVA`, alignment: 'justify', fontSize: 9 }, { text: `IMPORTE`, alignment: 'justify', fontSize: 9 }, { text: `MONEDA`, alignment: 'justify', fontSize: 9 }, { text: `DIAS FINANCIADOS`, alignment: 'justify', fontSize: 9 }],
              ]
            }
          },
          this.table(respanb, ['doc_sol', 'doc_if', 'fecha_venc', 'fecha_transaccion', 'fecha_efectiva', 'importe', 'moneda', 'dias_financiados']),
          { text: '\n' },
          { text: `LEIDO QUE FUE POR LAS PARTES EL PRESENTE SUPLEMENTO, SE FIRMA POR DUPLICADO EL ${fecha_efectiva[0]} DE ${fecha_efectiva[1].toUpperCase()} DE ${fecha_efectiva[2]}, ANTE LOS TESTIGOS AL CALCE MENCIONADOS, CONSERVANDO UN EJEMPLAR CADA UNA DE LAS PARTES.`, alignment: 'justify', fontSize: 11 },

          [{
            style: 'tabla',

            table: {
              widths: ['*', '*'],
              body: [
                [{ text: '\n' }, {}],
                [{ text: ` EL FACTORANTE\n\n\n________________________________________\nBANCO INVEX, S.A., INSTITUCIÓN DE BANCA MÚLTIPLE, INVEX GRUPO FINANCIERO POR CONDUCTO DE SUS REPRESENTANTES LEGALES\nDANIEL IBARRA HERNÁNDEZ, SANTIAGO VALENZUELA GONZÁLEZ`, alignment: 'justify' }, {
                  text: ` EL FACTORADO\n\n\n________________________________________\n${respanb[0].nombre_financiera}
                POR CONDUCTO DE SU REPRESENTANTE`, alignment: 'justify'
                }],
                // EL SR. ${respanb[0].representante_financiera.toUpperCase()}

              ]
            },
            layout: 'noBorders'
            , colSpan: 5
          }, {}, {}, {}, {}]
        ]
      }
      const filepath =
        resp[0].file_name +
        "_" +
        'anb' +
        "/" +
        resp[0].file_name;
      // pdfMake.createPdf(dd).open();
      const pdfDocGenerator = pdfMake.createPdf(dd);
      pdfDocGenerator.getBlob((blob) => {
        this.fbAtachedInvex(filepath, blob, ids, 'anb')
      });


    })

  }

  pruebaUsr(usr = 'base') {
    console.log(usr);
  }

  buildTableBody(data, columns) {
    var body = [];
    //body.push(columns);

    data.forEach(function (row) {
      var dataRow = [];

      columns.forEach(function (column) {
        dataRow.push(row[column].toString());
      })

      body.push(dataRow);
    });

    return body;
  }

  table(data, columns) {
    return {
      style: 'table',
      table: {
        // widths: [64,65,65,64,64,65,64,65],
        widths: [50, 51, 50, 51, 50, 51, 50, 51],
        // headerRows: 1,
        dontBreakRows: true,
        body: this.buildTableBody(data, columns)
      }
    };
  }

  get_chains(){
        this._pagosservice.getCadenas().subscribe((resp) => {
      this.cadenas = resp;
      // tslint:disable-next-line: forin
      for (const prop in this.cadenas) {
        this.cadenaso.push(this.cadenas[prop].nombre_cadena);
      }
      this.cadenaso.sort();
    });
  }

}
