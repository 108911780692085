import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { FacturasService, ParametrosGeneralesService, ReportesService } from 'src/app/services/service.index';
import Swal from 'sweetalert2';
import swal2 from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-revision-facturas-finantah',
  templateUrl: './revision-facturas-finantah.component.html',
  styleUrls: ['./revision-facturas-finantah.component.css']
})
export class RevisionFacturasFinantahComponent implements OnInit {

  facturas = [];
  cargado = false;
  cols = [];
  reporte = [];
  aceptadas = [];
  rechazadas = [];
  editables = [];
  listo = false;
  individualInvoicesMode = '';
  indices = [];

  constructor(private facturasService: FacturasService,
    private reportesService: ReportesService,
    private pgService: ParametrosGeneralesService,
    public router: Router) { }

  ngOnInit() {
    this.facturas = [];
    this.reporte = [];
    this.aceptadas = [];
    this.rechazadas = [];
    this.listo = false;

    this.facturasService.getInvoicesFinantah(localStorage.getItem('id')).subscribe(resp => {
      this.facturas = resp;
      this.facturas.forEach(f => {
        let [fechaVencimiento, fechaOperacion] = this.formatearFecha(f.fecha_vencimiento);
        f.fecha_vencimiento = fechaVencimiento;
      });
      this.cargado = true;
    })

    this.pgService.getGeneralParam('INDIVIDUAL_INVOICES_MODE').subscribe(resp => {
      switch(resp['value']) {
        case 'MONTOS':
          this.individualInvoicesMode = 'MONTOS';
          this.cols = [
            { field: 'folio_factura', header: 'Número de factura' },
            { field: 'rfc_receptor', header: 'RFC receptor' },
            { field: 'receptor', header: 'Receptor' },//----------------------
            { field: 'total_factura', header: 'Total' },
            { field: 'fecha_emision', header: 'Fecha de factura' },
            { field: 'fecha_vencimiento', header: 'Fecha de pago' },
            { field: 'tasa', header: 'Tasa' },
            { field: 'comision', header: 'Comisión' },
            { field: 'pdf', header: 'PDF' },
          ];
          break;
        case 'FECHAS':
          this.individualInvoicesMode = 'FECHAS';
          this.cols = [
            { field: 'folio_factura', header: 'Número de factura' },
            { field: 'rfc_receptor', header: 'RFC receptor' },
            { field: 'receptor', header: 'Receptor' },//----------------------
            { field: 'total_factura', header: 'Total' },
            { field: 'fecha_emision', header: 'Fecha de factura' },
            { field: 'fecha_vencimiento', header: 'Fecha de vencimiento' },
            { field: 'pdf', header: 'PDF' },
          ];
          break;
        case 'TOTAL':
          this.individualInvoicesMode = 'TOTAL';
          this.cols = [
            { field: 'folio_factura', header: 'Número de factura' },
            { field: 'rfc_receptor', header: 'RFC receptor' },
            { field: 'receptor', header: 'Receptor' },//----------------------
            { field: 'total_factura', header: 'Total' },
            { field: 'fecha_emision', header: 'Fecha de factura' },
            { field: 'fecha_vencimiento', header: 'Fecha de vencimiento' },
            { field: 'tasa', header: 'Tasa' },
            { field: 'comision', header: 'Comisión' },
            { field: 'pdf', header: 'PDF' },
          ];
          swal2.fire('Error', 'Aún no hay funcionalidad para el valor TOTAL del parametro "INDIVIDUAL_INVOICES_MODE"', 'info');
          break;
        default:
          swal2.fire('Error', 'No se ha configurado el modo de facturación individual, parámetro general "INDIVIDUAL_INVOICES_MODE"', 'info');
          this.router.navigate(['/dashboard']);
      }
      if(resp['value'] != null){
        this.individualInvoicesMode = resp['value'];
      }else{
        swal2.fire('Error', 'No se ha configurado el modo de facturación individual, parámetro general "INDIVIDUAL_INVOICES_MODE"', 'info');
        this.router.navigate(['/dashboard']);
      }
    })
  }

  almacenarAceptada(rowData){
    this.aceptadas.push(rowData);
    let i = this.facturas.findIndex(f => f.id == rowData.id);
    this.facturas.splice(i, 1);
    if(this.facturas.length == 0){
      this.procesar();
    }
  }

  almacenarRechazada(rowData){
    Swal.fire({
      title: 'Describe la razón del rechazo',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if(result.value && result.value != ''){
        rowData['nota_rechazo'] = result.value;
        this.rechazadas.push(rowData);
        let i = this.facturas.findIndex(f => f.id == rowData.id);
        this.facturas.splice(i, 1);
        if(this.facturas.length == 0){
          this.procesar();
        }
      }else{
        swal2.fire('Error', 'Debe teclear una razón válida', 'info');
      }
    })
  }

  procesar(){
    if(this.aceptadas.length > 0){
      this.aceptar();
    }
    
    if(this.rechazadas.length > 0){
      this.rechazar();
    }

    if(this.aceptadas.length == 0 && this.rechazadas.length == 0){
      swal2.fire('Error', 'No se ha seleccionado ninguna factura', 'info');
    }

  }

  async aceptar(){
    swal2.showLoading();
    for await (let a of this.editables) {
      var params = {};
      switch(this.individualInvoicesMode){
        case 'MONTOS':
          params = {invoice_id: a.id, rate: a.tasa, fee: a.comision};
          break;
        case 'FECHAS':
          let [fechaVencimiento, fechaOperacion] = this.formatearFecha(a.fecha_vencimiento);
          params = {invoice_id: a.id, due_date: fechaVencimiento, used_date: fechaOperacion};
          break;
        case 'TOTAL':
          swal2.fire('Error', 'Aún no hay funcionalidad para el valor TOTAL del parametro "INDIVIDUAL_INVOICES_MODE"', 'info');
          break;
        default:
          swal2.fire('Error', 'No se ha configurado el modo de facturación individual, parámetro general "INDIVIDUAL_INVOICES_MODE"', 'info');
          this.router.navigate(['/dashboard']);
      }
      let response = await this.procesaAceptadas(params);
      if (response.msg === "OK") {
      } else if (response.msg === "Error") {
        console.log(a, response);
      }
    }
    swal2.close();
    this.ngOnInit();
  }

  agregarEditable(rowData, i){
    if(!this.indices.includes(i)){
      this.indices.push(i);
      this.editables.push(rowData);
    }
  }

  agregarIndices(i, rowData){
    if(this.indices.includes(i)){
      this.indices.splice(this.indices.indexOf(i), 1);
      this.editables.splice(this.editables.indexOf(rowData), 1);
    }else{
      this.indices.push(i);
      this.editables.push(rowData);
    }
  }

  formatearFecha(fecha){
    let d = new Date(fecha);
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset())
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    let fecha_vencimiento = [year, month, day].join('-');

    let dOperacion = moment(new Date(fecha)).subtract(2, 'days').toDate();
    dOperacion.setMinutes(dOperacion.getMinutes() + dOperacion.getTimezoneOffset())
    let monthOperacion = '' + (dOperacion.getMonth() + 1);
    let dayOperacion = '' + dOperacion.getDate();
    const yearOperacion = dOperacion.getFullYear();

    if (monthOperacion.length < 2) {
        monthOperacion = '0' + monthOperacion;
    }
    if (dayOperacion.length < 2) {
        dayOperacion = '0' + dayOperacion;
    }
    let fechaOperacion = [yearOperacion, monthOperacion, dayOperacion].join('-');
    return [fecha_vencimiento, fechaOperacion];
  }

  async procesaAceptadas(params){
    try {
      let r = '';
      switch(this.individualInvoicesMode){
        case 'MONTOS':
          r = await (await this.facturasService.updateRateFee(params)).toPromise();
          break;
        case 'FECHAS':
          r = await (await this.facturasService.updateInvoices(params.invoice_id, {due_date: params.due_date, used_date: params.used_date, status: 'PENDIENTE'})).toPromise();
          break;
        case 'TOTAL':
          swal2.fire('Error', 'Aún no hay funcionalidad para el valor TOTAL del parametro "INDIVIDUAL_INVOICES_MODE"', 'info');
          return;
          break;
        default:
          swal2.fire('Error', 'No se ha configurado el modo de facturación individual, parámetro general "INDIVIDUAL_INVOICES_MODE"', 'info');
          this.router.navigate(['/dashboard']);
      }
      let resp = {
        status: true,
        resp: r,
        msg: "OK",
        nombre: params.invoice_id,
      };
      return resp;
    } catch (error) {
      let resp = {
        status: false,
        resp: error,
        msg: "Error",
        nombre: params.invoice_id,
      };
      return resp;
    }
  }

  rechazar(){
    let params = {};
    let invoices = [];
    this.rechazadas.forEach(r => {
      invoices.push({id: r.id, note: r.nota_rechazo})
    });
    params['invoices'] = invoices;
    params['company_id'] = (document.getElementById('company') as HTMLInputElement).value;
    this.facturasService.rejectInvoices(params).subscribe(resp => {
      swal2.fire('Éxito', 'Facturas rechazadas correctamente', 'info');
      this.ngOnInit();
    }, err => {
      console.log(err);
      swal2.fire('Error', 'Contacte al administrador', 'info');
    });
    /*
    this.facturasService.deleteInvoice(rowData.id, {notes: result.value}).subscribe(resp => {
          swal2.fire('Éxito', 'Factura rechazada', 'success');
          this.ngOnInit();
        }, err => {
          console.log(err)
          swal2.fire('Atención', err.error.errors[0], 'info');
        }) 
     */

  }

}
