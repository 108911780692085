import { Component, OnInit } from '@angular/core';
import { PagosService } from '../../../services/pagos/pagos.service';
import { FacturasService } from '../../../services/facturas/facturas.service';
import { ContribuyentesService } from '../../../services/contribuyentes/contribuyentes.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-estado-cuenta-cadena',
  templateUrl: './estado-cuenta-cadena.component.html',
  styles: []
})
export class EstadoCuentaCadenaComponent implements OnInit {

  uid = localStorage.getItem('id');
  company_id = '';
  facturas = [];
  cols = [
    {field: 'id_factura', header: 'Invoice Id', moneda: false},
    {field: 'folio_factura', header: 'Invoice Folio', moneda: false},
    {field: 'folio_solicitud', header: 'Application Folio', moneda: false},
    {field: 'folio_solicitud_fondeo', header: 'Fonding Application Folio', moneda: false},
    {field: 'uuid_factura_descontada', header: 'Discounted Invoice UUID', moneda: false},
    {field: 'estatus', header: 'Status', moneda: false},
    {field: 'fecha_vencimiento', header: 'Expiration Date', moneda: false},
    {field: 'disponible', header: 'Available', moneda: true},
    {field: 'receptor', header: 'Receiver', moneda: false},
    {field: 'moneda', header: 'Currency', moneda: false},
    {field: 'intereses', header: 'Interests', moneda: false},
    {field: 'total_factura', header: 'Total Invoice', moneda: true},
    {field: 'monto_operado', header: 'Traded Amount', moneda: true},
    // {field: 'monto_neto', header: 'Net Amount', moneda: true},
    // {field: 'monto_pago', header: 'Payment Amount', moneda: false},
    // {field: 'porcentaje_operado', header: 'Traded Percent', moneda: false},
    // {field: 'sobretasa', header: 'Surcharge', moneda: true},
    // {field: 'tasa_interbancaria', header: 'Interbank Rate', moneda: true},
    // {field: 'tasa_total', header: 'Total Rate', moneda: true},
    // {field: 'emisor', header: 'Sender', moneda: false},
    // {field: 'costo_financiero', header: 'Financial Cost', moneda: true},
    // {field: 'dia_pago_cadena', header: 'Company Pay Day', moneda: false},
    // {field: 'dias_al_vencimiento', header: 'Remaining Expiration Days', moneda: false},
    // {field: 'dias_descuento', header: 'Discount Days', moneda: true},
    // {field: 'fecha_carga', header: 'Loading Date', moneda: false},
    // {field: 'fecha_cobranza', header: 'Collection Date', moneda: false},
    // {field: 'fecha_emision', header: 'Issue Date', moneda: false},
    // {field: 'fecha_operacion', header: 'Operation Date', moneda: false},
    // {field: 'comision_cadena', header: 'Company Commission', moneda: true},
  ];
  companyCustomers = [];
  totalFacturasMXN = 0;
  totalOperadoMXN = 0;
  totalFacturasUSD = 0;
  totalOperadoUSD = 0;
  mostrar;

  constructor(private pagosService: PagosService,
              private facturasService: FacturasService,
              private contribuyentesService: ContribuyentesService) { }

  ngOnInit() {
    this.pagosService.getCompanyUser(this.uid).subscribe(resp => {
      this.company_id = resp[0].company_id;
      this.facturasService.getCompanyAccountStatus(this.company_id).subscribe(resp => {
        this.facturas = [];
        resp.forEach(e => {
          if(e.monto_operado != null){
            e.monto_operado = parseFloat(e.monto_operado.replace(',', ''));
          }else{
            e.monto_operado = 0;
          }
          if(e.total_factura != null){
            e.total_factura = parseFloat(e.total_factura.replace(',', ''));
          }else{
            e.total_factura = 0;
          }
          if(e.moneda == 'DOLARES'){
            e.moneda = 'USD';
          }else if(e.moneda = 'PESOS'){
            e.moneda = 'MXN';
          }
          if(e.folio_solicitud == null){
            e.folio_solicitud = 'N/A';
          }
          this.facturas.push(e);
          this.calcular();
        });
      })
      
      this.contribuyentesService.getclientescadena(this.company_id).subscribe(resp => {
        this.companyCustomers = resp;
      })
    });
  }

  exportexcel() {
    /* table id is passed over here */
    const element = document.getElementById('tablafacturas');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Estado de cuenta.xlsx');
  }

  calcular(){
    setTimeout(() => {
      
     const element = document.getElementById('tablafacturas');
    let tags = element.getElementsByTagName('th');
    const arrTags = Array.from(tags);
    let total = arrTags.findIndex(tag => tag.innerText === 'Total Invoice ');
    let operado = arrTags.findIndex(tag => tag.innerText === 'Traded Amount ');
    let currency = arrTags.findIndex(tag => tag.innerText === 'Currency ');

    let celdas = element.getElementsByTagName('td');
    this.totalFacturasMXN = 0;
    this.totalOperadoMXN = 0;
    this.totalFacturasUSD = 0;
    this.totalOperadoUSD = 0;

    for(let i = 0; i < celdas.length; i+= (operado + 1)){
      if(celdas[i + currency].innerText == 'MXN'){
        this.totalFacturasMXN += parseFloat((celdas[i + total].innerText).replace(',', ''));
        this.totalOperadoMXN += parseFloat((celdas[i + operado].innerText).replace(',', ''));
      }if(celdas[i + currency].innerText == 'USD'){
        this.totalFacturasUSD += parseFloat((celdas[i + total].innerText).replace(',', ''));
        this.totalOperadoUSD += parseFloat((celdas[i + operado].innerText).replace(',', ''));
      }
    }
  }, 500);
  }

}
