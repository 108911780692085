import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Rutas
import { APP_ROUTES } from './app.routes';

// Modulos
import { PagesModule } from './pages/pages.module';

// temporal
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Servicios
import { ServiceModule } from './services/service.module';

// Componentes
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { LoginBaseComponent } from './loginbase/loginbase.component';
import { LoginRyComponent } from './loginry/loginry.component';
import { LoginTriComponent } from './logintrinitas/logintri.component' 
import { ResetpwdComponent } from './login/resetpwd.component';
import { ResetpwdBaseComponent } from './loginbase/resetpwdbase.component';
import { ResetpwdryComponent } from './loginry/resetpwdry.component';
import { ResetpwdTriComponent } from './logintrinitas/resetpwdtri.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// bugsnag y rutas

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginBaseComponent,
    LoginRyComponent,
    ResetpwdComponent,
    ResetpwdBaseComponent,
    ResetpwdryComponent,
    LoginTriComponent,
    ResetpwdTriComponent
  ],
  imports: [
    BrowserModule,
    APP_ROUTES,
    PagesModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
