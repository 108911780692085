import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { ContribuyentesService, OptionsService, MantenimientoContribuyentesService } from '../../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-contribuyente',
  templateUrl: './contribuyente.component.html',
  styleUrls: ['../mantcontmain.component.css']
})
export class ContribuyenteComponent implements OnInit {
  @Input() eventEmitter: EventEmitter<string>;
  message: string;
  showModal = true;
  CURP = false;
  fisica = false;
  moral = false;
  correoFisica = false;
  correoMoral = false;
  correoMoralEmpresa = false;
  RFCFisica = false;
  RFCMoral = false;
  personamoral: any[] = [];
  personafisica: any[] = [];
  contribuyente: any[] = [];
  idc;
  muestrafielmoral: boolean;
  muestrafielfisica: boolean;
  banknum: any[];
  tipoidentificacion: any[] = [];
  maritalestatus: any[] = [];
  regimenfiscalfisica: any[] = [];
  regimenfiscalmoral: any[] = [];
  tgenero: any[] = [];
  constructor(
               public _contribuyentesService: ContribuyentesService,
               public _mantenimientocontservice: MantenimientoContribuyentesService,
               public _optionsservice: OptionsService,
               public router: Router,
               public http: HttpClient ) { }

  ngOnInit() {
    this.subscribeToParentEmitter();
  }

  subscribeToParentEmitter(): void { 
    this.eventEmitter.subscribe(d => {
    this.idc = d.idc;
    this._contribuyentesService.getPersonBank().subscribe( resp => {this.banknum = resp; this.banknum.push('No');} );
    this._contribuyentesService.getTipoIdentificacionFisica().subscribe( resp => { this.tipoidentificacion = resp; } );
    this._contribuyentesService.getMaritalStatusFisica().subscribe( resp => { this.maritalestatus = resp; } );
    this._contribuyentesService.getPersonFiscalRegime().subscribe( resp => { this.regimenfiscalfisica = resp; } );
    this._contribuyentesService.getLegalEntityFiscalRegime().subscribe( resp => { this.regimenfiscalmoral = resp; } );
    this._contribuyentesService.getPersonGender().subscribe( resp => this.tgenero = resp );
    this._mantenimientocontservice.getContribuyented( this.idc ).subscribe
    ( resp => {this.contribuyente = resp; //console.log(resp);
               if ( this.contribuyente[0].legal_entity_id === null ) {
                this.moral = false;
                this.fisica = true;
                this._mantenimientocontservice.getPersonaFisica(this.contribuyente[0].person_id).subscribe( resp3 => { this.personafisica = resp3; //console.log(resp3);
                                                                                                                       if (this.personafisica[0].fiel === true) {
                    this.muestrafielfisica = true;
                  } else {
                    this.muestrafielfisica = false;
                  }
                                                                                                                        } );
              } else if ( this.contribuyente[0].person_id === null ) {
               this.moral = true;
               this.fisica = false;
               this._mantenimientocontservice.getPersonaMoral(this.contribuyente[0].legal_entity_id).subscribe( resp2 => {this.personamoral = resp2; //console.log(this.personamoral)
                                                                                                                          if (this.personamoral[0].fiel === true) {
                                                                                                                            this.muestrafielmoral = true;
                                                                                                                          } else {
                                                                                                                            this.muestrafielmoral = false;
                                                                                                                          }
                                                                                                                           } );
              }
               swal2.close();
    } );
    })
}

modificapr() {
  this._contribuyentesService.getPersonBank().subscribe( resp => {this.banknum = resp; this.banknum.push('No');} );
    this._contribuyentesService.getTipoIdentificacionFisica().subscribe( resp => { this.tipoidentificacion = resp; } );
    this._contribuyentesService.getMaritalStatusFisica().subscribe( resp => { this.maritalestatus = resp; } );
    this._contribuyentesService.getPersonFiscalRegime().subscribe( resp => { this.regimenfiscalfisica = resp; } );
    this._contribuyentesService.getLegalEntityFiscalRegime().subscribe( resp => { this.regimenfiscalmoral = resp; } );
    this._contribuyentesService.getPersonGender().subscribe( resp => this.tgenero = resp );
    this._mantenimientocontservice.getContribuyented( this.idc ).subscribe
    ( resp => {this.contribuyente = resp; //console.log(resp);
               if ( this.contribuyente[0].legal_entity_id === null ) {
                this.moral = false;
                this.fisica = true;
                this._mantenimientocontservice.getPersonaFisica(this.contribuyente[0].person_id).subscribe( resp3 => { this.personafisica = resp3; //console.log(resp3);
                                                                                                                       if (this.personafisica[0].fiel === true) {
                    this.muestrafielfisica = true;
                  } else {
                    this.muestrafielfisica = false;
                  }
                                                                                                                        } );
              } else if ( this.contribuyente[0].person_id === null ) {
               this.moral = true;
               this.fisica = false;
               this._mantenimientocontservice.getPersonaMoral(this.contribuyente[0].legal_entity_id).subscribe( resp2 => {this.personamoral = resp2; //console.log(this.personamoral)
                                                                                                                          if (this.personamoral[0].fiel === true) {
                                                                                                                            this.muestrafielmoral = true;
                                                                                                                          } else {
                                                                                                                            this.muestrafielmoral = false;
                                                                                                                          }
                                                                                                                           } );
              }
               swal2.close();
    } );
}

// Funcion para validar la CURP
validaCurp() {
  const regex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

  const curp = (document.getElementById('CURP') as HTMLInputElement).value;
  const resultado = regex.test(curp);

  if ( curp.length > 0 ) {
    if ( resultado === false ) {
      document.getElementById('CURP').focus();
      this.CURP = true;
    } else {
      this.CURP = false;
    }
    } else {
      this.CURP = false;
    }

  }

  // Funcion para validar el email electronico persona fisica
  validaEmail() {
  const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const correoFisica = (document.getElementById('correofisica') as HTMLInputElement).value;
  const resultado = regex.test(correoFisica);

  if ( correoFisica.length > 0 ) {
  if ( resultado === false ) {
    document.getElementById('correofisica').focus();
    this.correoFisica = true;
  } else {
    this.correoFisica = false;
  }
  } else {
    this.correoFisica = false;
  }

  }

  // Funcion para validar el email electronico persona moral
  validaEmailmoral() {
    const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const correoMoral = (document.getElementById('correomoral') as HTMLInputElement).value;
    const resultado = regex.test(correoMoral);

    if ( correoMoral.length > 0 ) {
    if ( resultado === false ) {
      document.getElementById('correomoral').focus();
      this.correoMoral = true;
    } else {
      this.correoMoral = false;
    }
    } else {
      this.correoMoral = false;
    }

    }

    // Funcion para validar el email electronico persona moral empresa
  validaEmailmoralEmpresa() {
    const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const correoMoral = (document.getElementById('correomoralempresa') as HTMLInputElement).value;
    const resultado = regex.test(correoMoral);

    if ( correoMoral.length > 0 ) {
    if ( resultado === false ) {
      document.getElementById('correomoralempresa').focus();
      this.correoMoralEmpresa = true;
    } else {
      this.correoMoralEmpresa = false;
    }
    } else {
      this.correoMoralEmpresa = false;
    }

    }

  // Funcion para validar el RFC, recibe fisica o moral dependiendo de la persona
  validaRFC(persona: string) {


    if (persona === 'fisica') {
      const regex = /^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}/;
      const RFCFisica = (document.getElementById('rfcFisica') as HTMLInputElement).value;
      const resultado = regex.test(RFCFisica);

      if (RFCFisica.length > 0) {
        if ( resultado === false ) {
          document.getElementById('rfcFisica').focus();
          this.RFCFisica = true;
        } else {
          this.RFCFisica = false;
        }
    } else {
      this.RFCFisica = false;
    }
    }

    if (persona === 'moral') {
      const regex = /^[A-Z]{3}[0-9]{6}[A-Z0-9]{3}/;
      const RFCMoral = (document.getElementById('rfcmoral') as HTMLInputElement).value;
      const resultado = regex.test(RFCMoral);

      if (RFCMoral.length > 0) {
        if ( resultado === false ) {
          document.getElementById('rfcmoral').focus();
          this.RFCMoral = true;
        } else {
          this.RFCMoral = false;
        }
    } else {
      this.RFCMoral = false;
    }
    }

  }

  registrarcontribuyentemoral() {
    swal2.showLoading();
    let extra1s = '';
    let extra2s = '';
    let extra3s = '';
    const idm = this.personamoral[0].id;

    // Obtener el elemento por el id
    const fielmoral: any = document.getElementById('fielmoral');


    // Obtener el valor de la opción seleccionada
    let valorfielmoral = fielmoral.options[fielmoral.selectedIndex].value;

    if (valorfielmoral === 'SI') {
      valorfielmoral = true;
    } else if (valorfielmoral === 'NO') {
      valorfielmoral = false;
    } else {
      valorfielmoral = '';
    }

    if (valorfielmoral === true) {
      extra1s = (document.getElementById('apikeymoral')as HTMLInputElement).value;
      extra2s = (document.getElementById('tokenmoral')as HTMLInputElement).value;
    //  extra3s = (document.getElementById('authorizationmoral')as HTMLInputElement).value;
    } else {
      extra1s = '';
      extra2s = '';
      extra3s = '';
    }

    const datapm = {
      token: '',
      secret_key: '',
      fiscal_regime: (document.getElementById('rfiscalmoral')as HTMLInputElement).value,
      rfc: (document.getElementById('rfcmoral')as HTMLInputElement).value.trim(),
      rug: (document.getElementById('rug')as HTMLInputElement).value,
      business_name: (document.getElementById('nombremoral')as HTMLInputElement).value,
      phone: (document.getElementById('telfijomoral')as HTMLInputElement).value.trim(),
      mobile: (document.getElementById('telmovilmoral')as HTMLInputElement).value.trim(),
      email: (document.getElementById('correomoral')as HTMLInputElement).value.trim(),
      business_email: (document.getElementById('correomoralempresa')as HTMLInputElement).value.trim(),
      main_activity: (document.getElementById('actividadprincipal')as HTMLInputElement).value,
      fiel: valorfielmoral,
      extra1: extra1s,
      extra2: extra2s,
    //  extra3: extra3s
    };

    //console.log(this.contribuyente[0].extra3);
    let fxp = null;
    if (this.contribuyente[0].extra3 != null) {
      fxp = (document.getElementById('fxpmoral')as HTMLInputElement).value;
    }

    const contribuyentepm = {
      token: '',
      secret_key: '',
      contributor_type: 'PERSONA MORAL',
      bank: (document.getElementById('bancomoral') as HTMLInputElement).value.trim(),
      account_number: (document.getElementById('cuentabancariamoral')as HTMLInputElement).value,
      clabe: (document.getElementById('CLABEmoral')as HTMLInputElement).value,
      usd_bank: (document.getElementById('bancomoraldolares') as HTMLInputElement).value.trim(),
      usd_account_number: (document.getElementById('cuentabancariamoraldolares')as HTMLInputElement).value,
      usd_clabe: (document.getElementById('CLABEmoraldolares')as HTMLInputElement).value,
      extra1: (document.getElementById('clavelineamoral')as HTMLInputElement).value,
      extra3: fxp
    };
    // console.log(contribuyentepm);
    this._mantenimientocontservice.actualizaLegalEntity(idm, datapm).subscribe( resp => { swal2.close();
                                                                                          this._mantenimientocontservice.actualizaContribuyente(this.idc, contribuyentepm).subscribe( () => {
                                                                                            swal2.fire({
                                                                                              title: 'Los datos se modificaron',
                                                                                              text: 'Con exito',
                                                                                              icon: 'success',
                                                                                              showConfirmButton: true,
                                                                                              showCancelButton: false,
                                                                                              allowOutsideClick: false
                                                                                            }). then ( res => {
                                                                                              if ( res.value ) {
                                                                                                this.modificapr();
                                                                                              }
                                                                                            } );
                                                                                          }, (err) => {
                                                                                            swal2.close();
                                                                                            console.log(err);
                                                                                            swal2.fire({
                                                                                              title: 'Ocurrio un error',
                                                                                              text: '',
                                                                                              icon: 'error',
                                                                                              showConfirmButton: true,
                                                                                              showCancelButton: false,
                                                                                              allowOutsideClick: false
                                                                                            }). then ( res => {
                                                                                              if ( res.value ) {
                                                                                                this.modificapr();
                                                                                              }
                                                                                            } );
                                                                                           } );
      } );

  }

  registrarcontribuyentefisica() {
    swal2.showLoading();
    let extra1s = '';
    let extra2s = '';
    let extra3s = '';
    const idm = this.personafisica[0].id;
    // Obtener el elemento por el id
    const fielfisica: any = document.getElementById('fielfisica');
    const generofisica: any = document.getElementById('generofisica');

    // Obtener el valor de la opción seleccionada
    let valorfielfisica = fielfisica.options[fielfisica.selectedIndex].value;
    const valorgenerofisica = generofisica.options[generofisica.selectedIndex].value;

    if (valorfielfisica === 'SI') {
      valorfielfisica = true;
    } else if (valorfielfisica === 'NO') {
      valorfielfisica = false;
    } else {
      valorfielfisica = '';
    }

    const d = new Date((document.getElementById('fnacimiento')as HTMLInputElement).value);
    d.setMinutes( d.getMinutes() + d.getTimezoneOffset() );
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    const fechanac = [year, month, day].join('-');

    if (valorfielfisica === true) {
      extra1s = (document.getElementById('apikeyfisica')as HTMLInputElement).value;
      extra2s = (document.getElementById('tokenfisica')as HTMLInputElement).value;
    //  extra3s = (document.getElementById('authorizationfisica')as HTMLInputElement).value;
    } else {
      extra1s = '';
      extra2s = '';
      extra3s = '';
    }

    const paramspf = {
      token: '',
      secret_key: '',
      fiscal_regime: (document.getElementById('rfiscalfisica') as HTMLInputElement).value,
      rfc: (document.getElementById('rfcFisica')as HTMLInputElement).value,
      curp: (document.getElementById('CURP')as HTMLInputElement).value.trim(),
      imss: (document.getElementById('IMSS')as HTMLInputElement).value,
      first_name: (document.getElementById('nombrefisica')as HTMLInputElement).value,
      last_name: (document.getElementById('apellidop')as HTMLInputElement).value.trim(),
      second_last_name: (document.getElementById('apellidom')as HTMLInputElement).value,
      gender: valorgenerofisica,
      nationality: (document.getElementById('nationality')as HTMLInputElement).value.trim(),
      birth_country: (document.getElementById('pnacimiento')as HTMLInputElement).value,
      birthplace: (document.getElementById('lnacimiento')as HTMLInputElement).value,
      birthdate: fechanac,
      martial_status: (document.getElementById('estadocivil')as HTMLInputElement).value,
      id_type: (document.getElementById('tidentificacion')as HTMLInputElement).value,
      identification: (document.getElementById('nidentificacion')as HTMLInputElement).value,
      phone: (document.getElementById('telfijofisica')as HTMLInputElement).value,
      mobile: (document.getElementById('telmovilfisica')as HTMLInputElement).value,
      email: (document.getElementById('correofisica')as HTMLInputElement).value.trim(),
      fiel: valorfielfisica,
      extra1: extra1s,
      extra2: extra2s,
    //  extra3: extra3s
    };

    //console.log(this.contribuyente[0].extra3);
    let fxp = null;
    if (this.contribuyente[0].extra3 != null) {
      fxp = (document.getElementById('fxpfisica')as HTMLInputElement).value;
    }

    const contribuyentepf = {
      token: '',
      secret_key: '',
      contributor_type: 'PERSONA FISICA',
      bank: (document.getElementById('bancofisica') as HTMLInputElement).value.trim(),
      account_number: (document.getElementById('cuentabancariafisica')as HTMLInputElement).value,
      clabe: (document.getElementById('CLABEfisica')as HTMLInputElement).value,
      usd_bank: (document.getElementById('bancofisicadolares') as HTMLInputElement).value.trim(),
      usd_account_number: (document.getElementById('cuentabancariafisicadolares')as HTMLInputElement).value,
      usd_clabe: (document.getElementById('CLABEfisicadolares')as HTMLInputElement).value,
      extra1: (document.getElementById('clavelineafisica')as HTMLInputElement).value,
      extra3: fxp
    };

   // console.log(contribuyentepf, paramspf);

    this._mantenimientocontservice.actualizaPerson(idm, paramspf).subscribe( resp => { swal2.close();
                                                                                       this._mantenimientocontservice.actualizaContribuyente(this.idc, contribuyentepf).subscribe( resp2 => {
                                                                                                                                                                                              swal2.fire({
                                                                                          title: 'Los datos se modificaron',
                                                                                          text: 'Con exito',
                                                                                          icon: 'success',
                                                                                          showConfirmButton: true,
                                                                                          showCancelButton: false,
                                                                                          allowOutsideClick: false
                                                                                        }). then ( res => {
                                                                                          if ( res.value ) {
                                                                                            this.modificapr();
                                                                                          }
                                                                                        } );
                                                                                            // window.location.reload();
      }, (err) => {
        swal2.close();
        console.log(err);
        swal2.fire({
          title: 'Ocurrio un error',
          text: '',
          icon: 'error',
          showConfirmButton: true,
          showCancelButton: false,
          allowOutsideClick: false
        }). then ( res => {
          if ( res.value ) {
            this.modificapr();
          }
        } );
       } );
} );

  }

  getFielMoral() {
    const fielmoral: any = document.getElementById('fielmoral');
    // Obtener el valor de la opción seleccionada
    const valorfielmoral = fielmoral.options[fielmoral.selectedIndex].value;
    if(valorfielmoral === 'SI') {
      this.muestrafielmoral = true;
    } else {
      this.muestrafielmoral = false;
    }
  }

  getFielFisica() {
    const fielfisica: any = document.getElementById('fielfisica');
    // Obtener el valor de la opción seleccionada
    const valorfielfisica = fielfisica.options[fielfisica.selectedIndex].value;

    if(valorfielfisica === 'SI') {
      this.muestrafielfisica = true;
    } else {
      this.muestrafielfisica = false;
    }
  }

}