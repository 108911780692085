import { Component, OnInit, Input } from '@angular/core';
import { ReportesService, OptionsService, FacturasService, AltaSolicitudesService } from '../../services/service.index';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Swal from 'sweetalert2';
declare var $;


@Component({
  selector: 'app-facturas',
  templateUrl: './facturas.component.html',
  styles: []
})
export class FacturasComponent implements OnInit {
  factoraje_type = 'SIN_RECURSO';
  proveedor = 'Emisor';
  cadena = 'Receptor';

  constructor( public _reportesservice: ReportesService,
               public router: Router,
               public _optionsservice: OptionsService,
               public http: HttpClient,
               private facturasService: FacturasService,
               private solicitudesService: AltaSolicitudesService) { }

  token = localStorage.getItem('token');
  doc = new jsPDF();
  facturas: any[] = [];
  originalFacturas: any[] = [];
  usuario: string;
  cols: any[];
  colspdf: any[];
  selectedFac: any[];
  fileName = 'ListaDeFacturas.xlsx';
  selectedColumnsp: any[];
  selectedColumnspdf: any[];
  exportColumns: any[];

  _selectedColumns: any[];
  totalOperadoMXN = 0;
  totalFacturasMXN = 0;
  totalOperadoUSD = 0;
  totalFacturasUSD = 0;
  isExporting: boolean = false;

  ngOnInit() {

    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
 });
    swal2.showLoading();

    this._reportesservice.getReporteFacturas()
      .subscribe(resp => {
        this.facturas = resp;
        this.originalFacturas = JSON.parse(JSON.stringify(this.facturas));
        this.facturas.forEach(factura => {
          if(factura.moneda === 'PESOS'){
            this.totalFacturasMXN += parseFloat(factura.total_factura.replace(',', ''));
            if(factura.monto_operado){
              this.totalOperadoMXN += parseFloat(factura.monto_operado.replace(',', ''));
            }
          }else if(factura.moneda === 'DOLARES'){
            this.totalFacturasUSD += parseFloat(factura.total_factura.replace(',', ''));
            if(factura.monto_operado){
              this.totalOperadoUSD += parseFloat(factura.monto_operado.replace(',', ''));
            }
          }
        });
        swal2.close();
      } , (err) => {swal2.fire({title: 'Ocurrio un error al cargar la información', allowOutsideClick: false })} );

    //ESTE PARAMETRO (FACTORAJE_TYPE) SOLO SE USA PARA FINANTAH POR LO QUE NO SE REQUIERE CREAR EN FACTORAJE DE FACTOR  
    this.solicitudesService.getGeneralParam('FACTORAJE_TYPE').subscribe(resp => {
      this.factoraje_type = resp['value'];
      switch(this.factoraje_type){
        case 'SIN_RECURSO':
          this.proveedor = 'Emisor';
          this.cadena = 'Receptor';
          break;
        case 'CON_RECURSO':
          this.proveedor = 'Receptor';
          this.cadena = 'Emisor';
          break;
        default:
          this.factoraje_type = 'SIN_RECURSO';
          this.proveedor = 'Emisor';
          this.cadena = 'Receptor';
      }
      
    });

    this.cols = [

      { field:  'id_factura', header: 'ID'},
      { field:  'folio_factura', header: 'Folio factura' },
      { field:  'folio_solicitud', header: 'Folio solicitud'},
      { field:  'folio_solicitud_fondeo', header: 'Folio Solicitud Fondeo'},
      { field:  'uuid_factura_descontada', header: 'UUID'},
      { field:  'emisor', header: this.proveedor},
      { field:  'receptor', header: this.cadena},
      { field:  'moneda', header: 'Moneda'},
      { field:  'fecha_operacion', header: 'Fecha de operación'},
      { field:  'fecha_cobranza', header: 'Fecha de cobranza'},
      { field:  'fecha_vencimiento', header: 'Fecha de vencimiento'},
      { field:  'fecha_emision', header: 'Fecha de emision'},
      { field:  'fecha_carga', header: 'Fecha de carga'},
      { field:  'estatus', header: 'Estatus'},
      { field:  'total_factura', header: 'Total factura' },
      { field:  'porcentaje_operado', header: 'Porcentaje operado' },
      { field:  'monto_operado', header: 'Total'},
      { field:  'disponible', header: 'Disponible' },
      { field:  'intereses', header: 'Intereses'},
      { field:  'tasa_interbancaria', header: 'Tasa interbancaria' },
      { field:  'sobretasa', header: 'Sobretasa' },
      { field:  'tasa_total', header: 'Tasa total' },
      { field:  'comision', header: 'Comisión Total ' },
      { field:  'costo_financiero', header: 'Costo financiero' },
      { field:  'dias_descuento', header: 'Días descuento' },
      { field:  'monto_neto', header: 'Monto neto' },
      { field:  'tasa_comision', header: 'Porcentaje de Comisión' },
      { field:  'dia_pago_cadena', header: 'Día de pago empresa' },
      { field:  'monto_pago', header: 'Monto pago'},
      { field:  'dias_al_vencimiento', header: 'Días al Vencimiento'}
    ];

    this._selectedColumns = this.cols;
    this.colspdf = [

      { field:  'folio_solicitud', header: 'Folio Solicitud'},
      { field:  'folio_solicitud_fondeo', header: 'Folio Solicitud Fondeo'},
      { field:  'folio_factura', header: 'Folio Factura' },
      { field:  'uuid_factura_descontada', header: 'UUID'},
      { field:  'estatus', header: 'Estatus'},
      { field:  'fecha_emision', header: 'Fecha de emisión'},
      { field:  'fecha_carga', header: 'Fecha de carga'},
      { field:  'fecha_operacion', header: 'Fecha de operación'},
      { field:  'fecha_vencimiento', header: 'Fecha de vencimiento'},
      { field:  'moneda', header: 'Moneda'},
      { field:  'monto_operado', header: 'Total'},
      { field:  'intereses', header: 'Intereses'},
      { field:  'receptor', header: 'Empresa'},
      { field:  'emisor', header: 'Proveedor'},
      { field:  'tasa_comision', header: 'Comisión Cadena'},
      { field:  'dia_pago_cadena', header: 'Día de pago empresa'},
      { field:  'dias_al_vencimiento', header: 'Días al Vencimiento'}
];
    this.selectedColumnsp = this.cols;
    this.exportColumns = this.colspdf.map(col => ({title: col.header, dataKey: col.field}));


  }

  getAcceso(url) {
    let tieneacceso = false;
    this._optionsservice.getOptionsxUsuario(localStorage.getItem('id')).subscribe(resp2 => {
      for (const j in resp2 ) {
          if ( resp2[j].url === url ) {
            tieneacceso = true;
            break;
          }
        }
      if (!tieneacceso) {
        this.router.navigate(['/accesodenegado']);
      }
    });
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
}

set selectedColumns(val: any[]) {
  // restore original order
  this._selectedColumns = this.cols.filter(col => val.includes(col));
}

cambiarEstatus(rowData){
  console.log('rowData', rowData.id_factura);

  var params = {};
  var invoice = {status: 'PENDIENTE'}

  params['invoice'] = invoice;

  Swal.fire({
    title: 'Confirmación',
    text: "¿Desea actualizar el status a 'PENDIENTE'?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, actualizar!',
    cancelButtonText: 'Cancelar'
  }).then((result) => {
    if (result.value) {
      this.facturasService.updateInvoices(rowData.id_factura, params).subscribe(resp => {
        console.log(resp);
        swal2.fire('Éxito', 'Status actualizado correctamente', 'success');
        this.ngOnInit();
      }, err => {
        console.log(err);
        swal2.fire('Atención', 'Algo salió mal', 'info');
      })
    }
  })

}

  exportexcel() {
    const readOpts = {
      cellText:false, 
      cellDates:true,
    };
     /* table id is passed over here */
     const element = document.getElementById('tablaFacturas');
     const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, readOpts);
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     /* save to file */
     XLSX.writeFile(wb, this.fileName, readOpts);
  }

  exportExcelTodas() {
    swal2.fire({
      title: 'Generando documento...',
      allowOutsideClick: false });
      swal2.showLoading();

    // Activa la tabla secundaria
    this.isExporting = true;

    setTimeout(() => {
        const readOpts = {
            cellText: false,
            cellDates: true,
        };

        // Selecciona la tabla completa en el DOM
        const element = document.getElementById('tablaFacturasTodas');
        if (element) {
            const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, readOpts);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, this.fileName, readOpts);
        }

        // Ocultar la alerta de carga y desactivar la tabla secundaria
        swal2.close();
        this.isExporting = false;
    }, 0);
}

  exportpdf() {

   import('jspdf').then( jsPDF => {
    import('jspdf-autotable').then(x => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(this.exportColumns, this.facturas);
        doc.save('ListaFacturas.pdf');
    });
}); 

  }

  change(){
    const element = document.getElementById('tablaFacturas');
    let tags = element.getElementsByTagName('th');
    const arrTags = Array.from(tags);
    let idColumnaMoneda = arrTags.findIndex(tag => tag.innerText === 'Moneda ');

    let celdas = element.getElementsByTagName('td');
    this.totalFacturasMXN = 0;
    this.totalFacturasUSD = 0;
    this.totalOperadoMXN = 0;
    this.totalOperadoUSD = 0;

    let j = idColumnaMoneda;
    let idColumna = arrTags.findIndex(tag => tag.innerText === 'Total ');
    for(let i = idColumna; i < celdas.length; i+= idColumna){
      if(celdas[j].innerText === 'PESOS'){
        if(!isNaN(parseFloat(celdas[i].innerText.replace(',', '')))){
          this.totalOperadoMXN += parseFloat(celdas[i].innerText.replace(',', ''));
        }
      }else if(celdas[j].innerText === 'DOLARES'){
        if(!isNaN(parseFloat(celdas[i].innerText.replace(',', '')))){
          this.totalOperadoUSD += parseFloat(celdas[i].innerText.replace(',', ''));
        }
      }
      i += (this._selectedColumns.length-idColumna);
      j += (this._selectedColumns.length-idColumnaMoneda) + idColumnaMoneda;
    }

    j = idColumnaMoneda;
    idColumna = arrTags.findIndex(tag => tag.innerText === 'Total factura ');
    for(let i = idColumna; i < celdas.length; i+= idColumna){
      if(celdas[j].innerText === 'PESOS'){
        this.totalFacturasMXN += parseFloat(celdas[i].innerText.replace(',', ''));
      }else if(celdas[j].innerText === 'DOLARES'){
        this.totalFacturasUSD += parseFloat(celdas[i].innerText.replace(',', ''));
      }
      i += (this._selectedColumns.length-idColumna);
      j += (this._selectedColumns.length-idColumnaMoneda) + idColumnaMoneda;
    }
  }

}
