import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Usuario2 } from 'src/app/models/usuario.model';
import { FundersService, UsuarioService } from 'src/app/services/service.index';
import Swal from 'sweetalert2';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-funder-tags',
  templateUrl: './funder-tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class FunderTagsComponent implements OnInit {

  @Output() childEvent = new EventEmitter();
  tags = new FormGroup({
    value1: new FormControl(),
    value2: new FormControl(),
    value3: new FormControl(),
    value4: new FormControl(),
    value5: new FormControl(),
    value6: new FormControl(),
    value7: new FormControl(),
    value8: new FormControl(),
    value9: new FormControl(),
    value10: new FormControl(),
    value11: new FormControl(),
    value12: new FormControl(),
    value13: new FormControl(),
    value14: new FormControl(),
    value15: new FormControl(),
    value16: new FormControl(),
    value17: new FormControl(),
    value18: new FormControl(),
    value19: new FormControl(),
    value20: new FormControl(),
  })

  counterArray = new Array(1);
  fondeadores = [];
  funder_id = 0;


  constructor(
    public _usuarioService: UsuarioService,
    public router: Router,
    private _fundersservice: FundersService
  ) { }

  ngOnInit() {
    this._fundersservice.getfundersfinancial().subscribe((resp) => {
      this.fondeadores = resp;
    });
  }

  cerraroregusu() {
    this.childEvent.emit({message: 'cerrarcreausr'})
  }

  agregarTag(){
    if(this.counterArray.length == 20){
      swal2.fire('Atención', 'Se ha alcanzado el número máximo de etiquetas', 'info');
      return;
    }
    this.counterArray = new Array(this.counterArray.length + 1)
  }

  registrarTags(){
    if(this.funder_id == 0){
      swal2.fire('Atención', 'Debes seleccionar un fondeador válido', 'info');
      return;
    }
    var params = {
      funder_id: this.funder_id,
      funder_tags: this.tags.value
    }
    this._fundersservice.creaFunderTags(params).subscribe(resp => {
      swal2.fire('Éxito', 'Etiquetas guardadas correctamente', 'success');
      window.location.reload();
    }, err => {
      console.log(err);
      try{
        swal2.fire('Atención', err.error.error, 'info');
      }catch{
        swal2.fire('Atención', 'Algo salió mal', 'info');
      }
    })
  }

}
