import { Component, Input, OnInit } from '@angular/core';
import { OptionsService, ContribuyentesService, StpService } from '../../services/service.index';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import swal2 from 'sweetalert2';
import { ParametrosGeneralesService } from '../../services/parametrosgenerales/parametrosgenerales.service';

declare var $;

@Component({
  selector: 'app-stpaccounts',
  templateUrl: './stpaccounts.component.html',
  styles: []
})
export class StpAccountsComponent implements OnInit {
  cuenta_ordenante: any;

  constructor(public router: Router,
              public _stpservice: StpService,
              private _contribuyentesService: ContribuyentesService,
              private parametrosService: ParametrosGeneralesService
              ) {}

  saldo = 0;
  cuentaConcentradora = 646180352100000000;
  cols: any[];
  contribuyentes: any[];
  _selectedColumns: any[];

  ngOnInit() {
    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
 });
 swal2.showLoading();
    // this.consulta_saldo_stp();
    this._contribuyentesService.getContribuyentesMain().subscribe( resp => {
      this.contribuyentes = resp; 
      swal2.close(); 
    } );

    this.cols = [

      { field: 'rfc_contribuyente', header: 'RFC' },
      { field: 'nombre', header: 'Nombre' },
      { field: 'correo', header: 'Correo' },
      { field: 'crearStp', header: 'Crear STP' }

  ];

  this._selectedColumns = this.cols;
    console.log("On init")

    this.parametrosService.getGeneralParam('CUENTA_ORIGEN_STP').subscribe(resp => {
      this.cuenta_ordenante = resp['value'];
    })

  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
}

set selectedColumns(val: any[]) {
  // restore original order
  this._selectedColumns = this.cols.filter(col => val.includes(col));
}

  traer_nueva_cuenta_stp(id_contribuyente){
    this._stpservice.generateAccountStp().subscribe(resp => {
      console.log(resp);
      console.log('nueva cuenta stp', id_contribuyente, resp['cuentaClabe']);
      this.crear_nueva_cuenta_clabe_stp(id_contribuyente, resp['cuentaClabe']);
    })
  }
  
  consulta_saldo_stp(){
    // console.log("consulta_saldo_stp")
    const params = {
      empresa: 'FLIEBEN3',
      cuentaOrdenante: this.cuenta_ordenante
    };
    this._stpservice.getStpAccountBalanceStp(params)
    .subscribe(resp => {
      try{
        this.saldo = resp['response']['respuesta']['saldo'];
        swal2.close();
      }catch{
        swal2.fire('Atención', 'Hubo un problema en la consulta de saldo', 'info');
        return
      }
      
    } , err => {
      console.log(err)
      swal2.fire({title: 'Ocurrio un error al cargar la informacion', allowOutsideClick: false })} );
  }

  crear_nueva_cuenta_clabe_stp(id_contribuyente, cuentaClabe){
    const params = {
      contributor_id: id_contribuyente,
      cuenta_concentradora: this.cuenta_ordenante,
      cuenta_clabe: cuentaClabe
    };
    this._stpservice.createStpAccount(params)
    .subscribe(resp=> {
      console.log("resp", resp);
      swal2.fire('Éxito', 'Cuenta generada con éxito', 'success');
    }, err => {
      console.log(err);
      swal2.fire('Atención', 'Hubo un problema', 'info');
    })
  }




}









