import { Component, OnInit, Input } from '@angular/core';
import { ReportesService, OptionsService } from '../../services/service.index';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';
declare var $;


@Component({
  selector: 'app-payment_cfdis',
  templateUrl: './payment_cfdis.component.html',
  styles: []
})
export class PaymentCfdisComponent implements OnInit {

  constructor( public _reportesservice: ReportesService,
               public router: Router,
               public _optionsservice: OptionsService,
               public http: HttpClient) { }

  token = localStorage.getItem('token');
  doc = new jsPDF();
  facturas: any[] = [];
  usuario: string;
  cols: any[];
  colspdf: any[];
  selectedFac: any[];
  fileName = 'ListaDeCFDIS.xlsx';
  selectedColumnsp: any[];
  selectedColumnspdf: any[];
  exportColumns: any[];
  valores = {
    pago: '', desde:'', hasta:''
  }
  _selectedColumns: any[];

  ngOnInit() {
    

    this.cols = [

    //  { field:  'id_factura', header: 'ID'}, folio solicitud y folio fondeo
    { field: 'numero_transaccion', header: 'Número pago'},
    { field: 'fecha_pago', header: 'Fecha pago'},
    { field: 'serie_factura', header: 'Serie factura'},
    { field: 'folio_factura', header: 'Folio factura'},
    { field: 'folio_solicitud', header: 'Folio solicitud'},
    { field: 'folio_fondeo', header: 'Folio fondeo'},
    { field: 'fondeador', header: 'Fondeador'},
    { field: 'uuid_factura', header: 'UUID factura'},
    { field: 'proveedor', header: 'Proveedor'},
    { field: 'cadena', header: 'Empresa'},
    { field: 'moneda', header: 'Moneda'},
    { field: 'total', header: 'Total'},
    { field: 'total_operado', header: 'Total operado'},
    { field: 'uuid_cfdi', header: 'UUID cfdi'},
    { field: 'xml_cfdi', header: 'XML cfdi'},
    { field: 'pdf_cfdi', header: 'PDF cfdi'},
  ];

    this._selectedColumns = this.cols;
    this.colspdf = [

    //  { field:  'id_factura', header: 'ID'},
    { field: 'serie_factura', header: 'Serie factura'},
    { field: 'folio_factura', header: 'Folio factura'},
    { field: 'folio_solicitud', header: 'Folio Solicitud'},
    { field: 'uuid_factura', header: 'UUID factura'},
    { field: 'proveedor', header: 'Proveedor'},
    { field: 'cadena', header: 'Empresa'},
    { field: 'moneda', header: 'Moneda'},
    { field: 'uuid_cfdi_intereses', header: 'UUID cfdi intereses'},
    { field: 'xml_cfdi_intereses', header: 'XML cfdi intereses'},
    { field: 'pdf_cfdi_intereses', header: 'PDF cfdi intereses'},
    { field: 'uuid_cfdi_complemento_proveedor', header: 'UUID cfdi complemento proveedor'},
    { field: 'xml_cfdi_complemento_proveedor', header: 'XML cfdi complemento proveedor'},
    { field: 'pdf_cfdi_complemento_proveedor', header: 'PDF cfdi complemento proveedor'},
    { field: 'uuid_cfdi_complemento_cadena', header: 'UUID cfdi complemento empresa'},
    { field: 'xml_cfdi_complemento_cadena', header: 'XML cfdi complemento empresa'},
    { field: 'pdf_cfdi_complemento_cadena', header: 'PDF cfdi complemento empresa'},
];
    this.selectedColumnsp = this.cols;
    this.exportColumns = this.colspdf.map(col => ({title: col.header, dataKey: col.field}));

  }

  consultar() {
    swal2.showLoading()
    if (this.valores.pago === '' || this.valores.desde === '' || this.valores.hasta === '') {
      swal2.fire("Todos los valores son requeridos", '', 'info')
    } else {
      this._reportesservice.getPaymentsCFDIS(this.valores).subscribe(resp => {
        if (resp.length > 0) {
          console.log(resp)
          this.facturas = resp;
          swal2.close();
        } else {
          swal2.fire("No se encontro información con los datos proporcionados",'','info');
        }
} );
    }
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
}

set selectedColumns(val: any[]) {
  // restore original order
  this._selectedColumns = this.cols.filter(col => val.includes(col));
}


  exportexcel() {
     /* table id is passed over here */
     const element = document.getElementById('tablaFacturas');
     const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     /* save to file */
     XLSX.writeFile(wb, this.fileName);
  }


  exportpdf() {

   import('jspdf').then( jsPDF => {
    import('jspdf-autotable').then(x => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(this.exportColumns, this.facturas);
        doc.save('ListaDeCFDIS.pdf');
    });
}); 

  }

}
