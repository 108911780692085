import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { error } from 'console';
import { AwsService } from 'src/app/services/awsser/aws.service';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-mantenimiento-proveedor',
  templateUrl: './mantenimiento-proveedor.component.html',
  styleUrls: ['./mantenimiento-proveedor.component.css']
})
export class MantenimientoProveedorComponent implements OnInit {
  @Input() eventEmitter: EventEmitter<Object>;
  @Output() childEvent = new EventEmitter();
  idu = '';
  contributor_documents = [];
  titulosSecciones = [];
  secciones = {};

  constructor(private awsService: AwsService) { }

  ngOnInit() {
    this.subscribeToParentEmitter();
  }

  subscribeToParentEmitter(): void { 
    this.eventEmitter.subscribe((d: { contid: string; }) => {
      this.idu = d.contid;
      this.awsService.getContributorDocuments(this.idu).subscribe(resp => {
        if(resp['data'].length > 0){
          resp['data'].forEach(d => {
            this.contributor_documents.push(d.attributes);
            this.contributor_documents.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
            if(!this.titulosSecciones.includes(d.attributes.section)){
              this.titulosSecciones.push(d.attributes.section); 
              this.titulosSecciones.sort((a, b) => parseFloat(a) - parseFloat(b));
              this.secciones[d.attributes.section] = [] 
            }
            this.secciones[d.attributes.section].push(d.attributes)
            this.secciones[d.attributes.section].sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
          });
  
          // PARA MIS PRUEBAS
          /* var p = resp['data'].find(r => r.attributes.id == 403)
          this.contributor_documents.push(p.attributes);
          resp['data'].forEach(d => {
            if(this.contributor_documents.length < 4 && d.attributes.url != null){
              this.contributor_documents.push(d.attributes);
            }
          });
          var p = resp['data'].find(r => r.attributes.name == 'Identificacion oficial INE')
          this.contributor_documents.push(p.attributes);
          console.log(this.contributor_documents); */
        }else {
          this.cerrarEdicion();
        }
      })
    })
  }

  cerrarEdicion() {
    this.childEvent.emit({message: 'cerrarcreausr'})
  }

  async fileBrowseHandler( e, doc, accion ) {
    switch(accion){
      case 'carga':
        await this.callUpload(e[0], this.idu, doc.name, doc.id);
        break;
      case 'actualiza':
        await this.eliminaActual(doc.url);
        await this.callUpload(e[0], this.idu, doc.name, doc.id);
        break;
      default:
        break;
    }
  }

  async callUpload(file, idu, doc, idd) {
     const r = await this.awsService.uploadFile(file, idu).then(resp => {
      this.awsService.patchUrl(this.idu, idd, resp.Location).subscribe(r => {
        swal2.fire('Éxito', 'Archivo cargado correctamente');
        var position = this.contributor_documents.indexOf(this.contributor_documents.find(d => d.id == idd));
        var item = this.contributor_documents[position];
        item.url = resp.Location;
        this.contributor_documents[position] = item;
        this.ngOnInit();
      }, err => {
        console.log(err);
        swal2.fire('Atención', 'Algo salió mal', 'info');
      })
     }).catch(err => {
      console.log('Error al subir a AWS', err);
      swal2.fire('Atención', 'Algo salió mal', 'info');
     })
  }

  async eliminaActual(url){
    let nameFile = (url.split("/").pop()).replace(/%20/g,' ');
    let rd = await this.awsService.delteContributorFile(this.idu,url).then(r => {
      console.log(r);
    }).catch(error => {
      console.log(error)
    })
  }

  
}
