import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { AltaSolicitudesService, OptionsService, MiFielService, ReportesPDFService } from '../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { forkJoin, Observable, throwError } from 'rxjs';
import { finalize } from 'rxjs/operators';
import swal2, { SweetAlertResult } from 'sweetalert2';
import * as firebase from 'firebase/app';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
declare var $;
import { retryWhen, delay, scan } from 'rxjs/operators';



@Component({
  selector: 'app-estatussolicitudes',
  templateUrl: './estatussolicitudes.component.html',
  styleUrls: ['./estatussolicitudes.component.css']
})
export class EstatusSolicitudesComponent implements OnInit {
  accesoupdateatta = false;
  showModalatta: boolean;
  uploadForm: FormGroup;
  showModalidsol: boolean;
  url: any;
  file: any;
  datosdetalles: any[] = [];
  selectedSol: any[] = [];
  cols: any[] = [];
  solicitudes: any[] = [];
  estatussolicitudes: any[] = [];
  usuarios: any[] = [];
  idu: string;
  documentonulo: boolean;
  uploadURL: Observable<string>;
  uploadProgress: Observable<number>;
  showModal: boolean;
  showmodalds: boolean;
  idsolicitud: string;
  foliosolicitud: string;
  carpeta: string;
  nombrearchivosubido: string;
  trinitas_notifications = '';
  //NuevaTabla ///
  sortColumn: string = ''; // Columna actual de ordenación
  sortDirection: 'asc' | 'desc' = 'asc'; // Dirección de ordenación
  bancoppeldash = false;
  SolFonHisEssol: any[] = [];
  SolFonHisEsDifsol: any[] = [];
  ClienxCadena: any[] = [];
  items: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 10;
  searchTerm: string = '';
  maxVisiblePages: number = 8; // Número máximo de páginas visibles
  searchTerms = {
      folio: '',
      fecha_operacion:'',
      fecha_vencimiento:'',
      total_operar:'',
      moneda:'',
      usuario:'',
      status:'',
      cadena:'',
      proovedor:''
    };
    selectedRows = new Set<any>(); // Almacena las filas seleccionadas
    allData = []; // Todos los datos disponibles (incluidos los de otras páginas).
    /////////////

  constructor(private _formBuilder: FormBuilder,
              public router: Router,
              private route: ActivatedRoute,
              public _optionsservice: OptionsService,
              public _mifiel: MiFielService,
              public reportespdf: ReportesPDFService,
              private formBuilder: FormBuilder, private httpClient: HttpClient, 
              private _firestorage: AngularFireStorage,
              public _solicitudesservice: AltaSolicitudesService) {}

              show(datarow) {
                this.showModal = true; // Show-Hide Modal Check
               // console.log(datarow);
                this.idsolicitud = datarow.id;
                this.foliosolicitud = datarow.folio;
              }
              hide() {
                this.showModal = false;
                this.idsolicitud = '';
                this.foliosolicitud = '';
                this.ngOnInit();
              }

  ngOnInit() {
    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
      });
    swal2.showLoading();
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
    this.selectedSol = [];
    this.idu = localStorage.getItem('id');
    this.showModalidsol = false;
    this.accesoupdateatta = false;
    let correoparaupdate = localStorage.getItem('emailuser');
    let idu = localStorage.getItem('id');
    this._solicitudesservice.getPermisosUsuario(idu).subscribe( resp => {
    if (resp[0] === 'SI') {
      this._solicitudesservice.getPermisoParaAtta().subscribe(resp2 => {
      if (resp2[0].includes(correoparaupdate)) {
        this.accesoupdateatta = true
      }
      })
    }
    } )
    this._solicitudesservice.getGeneralParam('TRINITAS_NOTIFICATIONS').subscribe(param => {
      if(typeof(param['value']) == 'string'){
        this.trinitas_notifications = param['value'];
      }
    })
   // forkjoin
     // Función para manejar reintentos con tiempo de espera
     const retryDelay = 2000; // 2 segundos de espera entre reintentos
     const maxRetries = 3;    // Máximo de 3 reintentos
     const retryWithDelay = () => retryWhen(errors =>
  errors.pipe(
    scan((retryCount, error) => {
      if (retryCount >= maxRetries) {
        throw error; // Lanza el error después del número máximo de reintentos
      }
      console.warn(`Reintento #${retryCount + 1} después de ${retryDelay} ms`);
      return retryCount + 1;
    }, 0),
    delay(retryDelay)
  )
);
    const observable1 = this._solicitudesservice.getSolicitudesxusuario(this.idu).pipe(retryWithDelay()) as Observable<any[]>;
    const observable2 = this._solicitudesservice.getUsuariosFinanciero().pipe(retryWithDelay()) as Observable<any[]>;
    const observable3 = this._solicitudesservice.getEstatusFacturas().pipe(retryWithDelay()) as Observable<any[]>;
   forkJoin([observable1, observable2, observable3]).subscribe(async ([res1, res2, res3]) => {
    this.solicitudes = res1;
    this.usuarios = res2;
    this.estatussolicitudes = res3;
      const estatus: any = document.getElementById('estatus');
      estatus.options[estatus.selectedIndex].value = 0;
      swal2.close();
   }, (err) => {
     console.log(err);
     swal2.close();
     swal2.fire({
       title: 'Ocurrio un error al consultar la información',
       icon: 'info',
       allowOutsideClick: false
     }). then ( res => {
       if ( res.value ) {
         this.router.navigate(['/dashboard']);
       }
     } );
   });

  ///
    this.cols = [

    { field: 'fecha_factura', header: 'Fecha Factura' },
    { field: 'fecha_operacion', header: 'Fecha de operación' },
    { field: 'fecha_vencimiento', header: 'Fecha de vencimiento' },
    { field: 'total_operar', header: 'Total operado' },
    { field: 'moneda', header: 'Moneda' },
    { field: 'usuario', header: 'Usuario' },
    { field: 'status', header: 'Estatus' },
    { field: 'cadena', header: 'Empresa' },
    { field: 'proovedor', header: 'Proveedor' },
   // { field: 'attached', header: 'Documento de Cesión de derechos' }
  ];
    
  }

  showatt() {
    this.showModalatta = true; // Show-Hide Modal Check
  }
  hideatt() {
    this.showModalatta = false;
  }

  showattidsol() {
    this.showModalidsol = true; // Show-Hide Modal Check
  }
  hideattidsol() {
    this.showModalidsol = false;
  }

  hideds() {
    this.showmodalds = false;
  }
   
   imageChoice(theEventFromHtml) {
    this.file = theEventFromHtml.target.files[0]

  }

  mustradetallessolicitud(id, event) {
    event.stopPropagation(); // Detener la propagación del clic
    this.showmodalds = true;
    this._solicitudesservice.getdetallessolicitudes(id).subscribe( resp => {this.datosdetalles = resp  } 
    ,(err) => {
      swal2.fire(
        'Ocurrio un error al consultar los detalles',
        '',
        'error'
        );
    })
  }

  async guardarCambios() {
    const estatusPermitidos = [
        'PENDIENTE',
        'ESPERA FIRMA DIGITAL',
        'FIRMADO DIGITALMENTE',
        'APROBADA'
    ];
    let estatusMod = '';
    const selectedRowsArray = Array.from(this.selectedRows);
    this.selectedSol = selectedRowsArray;
    let idu = (document.getElementById('usuario') as HTMLInputElement).value;
    let estatusval: any = (document.getElementById('estatus') as HTMLInputElement).value;

    if (this.selectedSol.length === 1) {
        estatusMod = this.selectedSol[0].status.trim().toUpperCase();
    }
    if (this.selectedSol.length === 0) {
        swal2.fire('Debe seleccionar al menos una solicitud', '', 'info');
        return;
    } else if (estatusval === '0' && idu === '') {
        swal2.fire('Debe seleccionar un estatus o un usuario', '', 'info');
        return;
    } else if (estatusval === 'CANCELADA' && this.selectedSol.length > 1) {
        swal2.fire('Solo puede seleccionar una solicitud a la vez si el estatus es "CANCELADA"', '', 'info');
        return;
    } else if (estatusval === 'CANCELADA' && this.selectedSol.length === 1 && !estatusPermitidos.includes(estatusMod)) {
        swal2.fire(
            'Solo se pueden cancelar solicitudes que estén en los estatus: PENDIENTE, ESPERA FIRMA DIGITAL, FIRMADO DIGITALMENTE o APROBADA',
            '',
            'info'
        );
        return;
    }

    let params = {
        token: '',
        secret_key: '',
        request: {}
    };

    let flag = true;
    if (estatusval === 'CANCELADA') {
        await swal2.fire({
            text: "¿Desea que las facturas vuelvan a estar disponibles para descuento?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            showCloseButton: true,
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                params['invoices_available'] = 'true';
            } else if (result.dismiss == swal2.DismissReason.cancel) {
                params['invoices_available'] = 'false';
            } else if (result.dismiss == swal2.DismissReason.close) {
                flag = false;
            }
        });
    }

    if (!flag) return;

    const resultados = [];
    try {
      swal2.fire({
        title: 'Procesando cambios',
        text: 'Por favor, espere...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          swal2.showLoading();
        },
      });

        for (const prop in this.selectedSol) {
            const ids = this.selectedSol[prop].id;

            if (estatusval !== '0' && idu !== '') {
                params['request']['user_id'] = idu;
                params['request']['status'] = estatusval;
            } else if (estatusval !== '0' && idu === '') {
                params['request']['status'] = estatusval;
            } else if (estatusval === '0' && idu !== '') {
                params['request']['user_id'] = idu;
            }

            try {
                const resultado = await this.updateSolicitudes(ids, { ...params });
                resultados.push({ id: ids, exito: true, resultado });
            } catch (error) {
                console.error(`Error al procesar solicitud con ID ${ids}:`, error);
                resultados.push({ id: ids, exito: false, error });
            }
            await new Promise(resolve => setTimeout(resolve, 500));
        }

        const exitosas = resultados.filter(r => r.exito).length;
        const fallidas = resultados.filter(r => !r.exito).length;

        if (fallidas > 0) {
            swal2.fire({
                title: 'Resultado',
                html: `Se procesaron correctamente ${exitosas} solicitudes.<br>Hubo errores con ${fallidas} solicitudes.`,
                icon: 'warning',
                showConfirmButton: true,
                allowOutsideClick: false
            });
        } else {
            swal2.fire({
                title: 'Modificación de Solicitudes',
                text: 'Exitosa',
                icon: 'success',
                showConfirmButton: true,
                allowOutsideClick: false
            }).then(res => {
                if (res.value) {
                    location.reload();
                }
            });
        }
    } catch (error) {
        console.error('Error al procesar las solicitudes:', error);
        swal2.fire(
            'Error',
            'Hubo un problema al modificar las solicitudes. Por favor, inténtelo nuevamente.',
            'error'
        );
    }
}

  async updateSolicitudes(ids, params){
    if(params['request']['status'] == 'CANCELADA' && this.trinitas_notifications == 'SI'){
      // Bucle para seguir pidiendo la razón hasta que el usuario ingrese algo
      let result: SweetAlertResult;
      do {
        // Mostrar el modal de entrada
        result = await swal2.fire({
          title: "Teclea el motivo de la cancelación",
          input: "text",
          showCancelButton: false,
          confirmButtonText: "Enviar",
          allowOutsideClick: () => !swal2.isLoading(),
        });
      
        // Si el valor está vacío o no existe, muestra un mensaje de advertencia
        if (!result.value) {
          await swal2.fire({
            title: "Atención",
            text: 'Debe teclear una razón para la cancelación',
            icon: 'info',
            confirmButtonText: 'OK'
          });
        }
      } while (!result.value);
      swal2.showLoading();
      // Después de que el usuario ingresa algo, asignar el valora los parama
      params['request']['flow_comments'] = result.value;
    }
    return this._solicitudesservice.updateSolicitudes(ids, params).toPromise(); // Convertir observable a promesa
   //await new Promise((resolve) => setTimeout(resolve, 3000)); //linea para pruebas
   
  }

  actualizaattach() {

    if (this.selectedSol.length < 1) {
      swal2.fire(
        'Debe seleccionar al menos una solicitud',
        '',
        'error'
        );
    } else {
      // tslint:disable-next-line: forin
      for (const prop in this.selectedSol) {

        const ids = this.selectedSol[prop].id;

        const params = {
          token: '',
          secret_key: '',
          attached: this.selectedSol[prop].attached
      };

        this._solicitudesservice.updateSolicitudes(ids, params).subscribe( );
      }
      // location.reload();
      swal2.fire({
        title: 'Los documentos seleccionados se actualizaron',
        text: 'Con exito',
        icon: 'success',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {

        if ( res.value ) {
          window.location.reload();
        }

      } );
    }
  }

  subirdoc( sol ) {
    const file = sol.target.files[0];
    this.carpeta = this.foliosolicitud;
    this.nombrearchivosubido = this.foliosolicitud;
    const filepath = `${this.foliosolicitud}/${this.foliosolicitud}`;
    const fileRef = this._firestorage.ref(filepath);
    const task = this._firestorage.upload(filepath, file);
    this.uploadProgress = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => this.uploadURL = fileRef.getDownloadURL())
    ).subscribe();
   // console.log(this.uploadURL);
  }

  subirurl(uploadURL) {
    const params = {
      token: '',
      secret_key: '',
      attached: ''
  };
    let urll = '';
    const storage = firebase.storage();
    storage.ref(`${this.carpeta}/${this.nombrearchivosubido}`).getDownloadURL()
    .then((url) => {
    // Do something with the URL ...
   // console.log(url);
    params.attached = url;
   // console.log(params);
   // console.log(this.idsolicitud);
    this._solicitudesservice.updateSolicitudes(this.idsolicitud, params).subscribe( resp => { swal2.fire({
      title: 'El archivo se guardo',
      text: 'Con exito',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {

      if ( res.value ) {
        window.location.reload();
      }

    } ); }, (err) => {
      console.log(err);
      swal2.fire({
        title: 'Ocurrio un error',
        text: err.error.errors[0],
        icon: 'error',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {
        if ( res.value ) {
          location.reload();
        }
      } );
     } );
  });
   // console.log(urll);
  }

  borraArchivo(rowData) {

    swal2.fire({
      title: 'Desea borrar el archivo?',
        text: rowData.folio,
        icon: 'question',
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        const params = {
          token: '',
          secret_key: '',
          attached: ''
      };
        // Create a reference to the file to delete
        const storage = firebase.storage();
       // console.log(rowData.folio);
       // storage.ref(`SF1601017342/`).delete();
        const task = this._firestorage.storage.ref(`${rowData.folio}/`);
        task.listAll().then(function(result) {
          result.items.forEach(function(referencia) {
            // And finally display them
           // this.displayImage(referencia)
           referencia.delete();
    
        });
         });
    
        params.attached = null;
        this._solicitudesservice.updateSolicitudes(rowData.id, params).subscribe( resp => { swal2.fire({
            title: 'El archivo se borro',
            text: 'Con exito',
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {
      
            if ( res.value ) {
              window.location.reload();
            }
      
          } ); }, (err) => {
            console.log(err);
            swal2.fire({
              title: 'Ocurrio un error',
              text: err.error.errors[0],
              icon: 'error',
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: false
            }). then ( res => {
              if ( res.value ) {
                location.reload();
              }
            } );
           } );
      }
    });

  }

  upload(event) {

    const file = event.target.files[0];

    const randomId = Math.random().toString(36).substring(2);
    const filepath = `facturas/${event.target.files[0].name}`;
    const fileRef = this._firestorage.ref(filepath);
    const task = this._firestorage.upload(filepath, file);
    this.uploadProgress = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => this.uploadURL = fileRef.getDownloadURL())
    ).subscribe();
  }

  confirmaattached() {
    let ids: any = (document.getElementById('idsol')as HTMLInputElement).value;
    let atta: any = (document.getElementById('att')as HTMLInputElement).value;
    if (ids === '' || atta === '') {
      swal2.fire(
        'No puede dejar un campo en blanco',
        '',
        'warning'
      ).then ( () => { this.accesoupdateatta = false; this.ngOnInit()});
    } else {
      swal2.fire({
        title: 'Desea confirmar los datos',
        text: '',
        icon: 'question',
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false
      }). then ( res => {
        if ( res.value ) {
          swal2.fire({
            title: 'Procesando los datos',
            text:  '',
            icon: 'info',
            showConfirmButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            onOpen: () => {
              swal2.showLoading();
            }
          })
          this.reportespdf.subirdocFactordirectointerfaz( ids, atta ).subscribe( resp => {console.log(resp)
          swal2.fire(
            'Se procesaron los datos correctamente',
            'favor de abrir la consola para ver la respuesta sin cerrar este mensaje',
            'success'
          ). then ( () => this.ngOnInit() )
          } , (err) => (console.log(err),
          swal2.fire(
            'Hubo un error al procesar los datos',
            'favor de abrir la consola para ver la respuesta sin cerrar este mensaje',
            'warning'
          ). then ( () => this.ngOnInit() )
          ) )
          
        } else {
          this.ngOnInit();
        }
      } );
    }
  }

  reporteporid() {
    let idsolicitud = (document.getElementById('idsolicitud')as HTMLInputElement).value;
    let firmantes = [];
    let firmantesreporte = [];
    let firmantesreportenombres = '';
    let facturasReporte = [];
     this.reportespdf.getinfosolicitudxid(idsolicitud).subscribe(respsol => {
      this._solicitudesservice.getCadenaProveedor(respsol.data.attributes.user_id).subscribe( respprov => {
        if ( respprov.length > 0 ) {
          const data = {
            nombrecadena: respprov[0].cadena,
            nombreproveedor: respprov[0].proveedor,
            companyid: respprov[0].company_id,
            supplierid: respprov[0].supplier_id
          }
          for ( const prop in respsol.data.relations.invoices ) {
            facturasReporte[prop] = [data.nombreproveedor, respsol.data.attributes.folio, respsol.data.relations.invoices[prop].attributes.total_used, respsol.data.relations.invoices[prop].attributes.entry_date, respsol.data.relations.invoices[prop].attributes.due_date];
          } 
          if (respprov[0].supplier_id !== 0) {                                                                    
            this._solicitudesservice.getSignatories(respprov[0].supplier_id).subscribe(resp2 => { firmantes = resp2;
              // tslint:disable-next-line: forin
              for (const prop in firmantes) {
                firmantesreporte[prop] = firmantes[prop].firmante;
              }
              firmantesreportenombres = firmantesreporte.join(' / ');
              const  data2 = {
                nombrecadena: respprov[0].cadena,
                startdatesuplier: '',
                direccioncompany: '',
                nombreproveedor: respprov[0].proveedor,
                firmantesreportenombres: firmantesreportenombres,
                } 
              this.reportespdf.cesion_derechos_factor_async(facturasReporte, respsol.data.attributes.id, respsol.data.attributes.folio, data2 );
            });
          } else {
            this._solicitudesservice.getSignatoriesw(respprov[0].company_id).subscribe(resp2 => { firmantes = resp2;
              // tslint:disable-next-line: forin
              for (const prop in firmantes) {
                firmantesreporte[prop] = firmantes[prop].firmante;
              }
              firmantesreportenombres = firmantesreporte.join(' / ');
              const  data2 = {
                nombrecadena: respprov[0].cadena,
                startdatesuplier: '',
                direccioncompany: '',
                nombreproveedor: respprov[0].proveedor,
                firmantesreportenombres: firmantesreportenombres,
                }
                this.reportespdf.cesion_derechos_factor_async(facturasReporte, respsol.data.attributes.id, respsol.data.attributes.folio, data2 );
          });
          }
        } else {
          swal2.fire('No se encontro una empresa configurada')
          .then( () => this.ngOnInit() );
        }
      } )
      }, (err) => {console.log(err)
      swal2.fire('Ocurrio un error o no existe el ID capturado')
      .then( () => this.ngOnInit() )
      } )
    }
//Nueva tabla//
pgone() {
  this.currentPage = 1;
}

/*get filteredDataq(): any[] {
  return this.SolFonHisEsDifsol.filter(item =>
    (item.folio_factura && item.folio_factura.toLowerCase().includes(this.searchTerm.toLowerCase())) || '' ||
    (item.folio_solicitud && item.folio_solicitud.toLowerCase().includes(this.searchTerm.toLowerCase())) || '' ||
    (item.fecha && item.fecha.toLowerCase().includes(this.searchTerm.toLowerCase())) || '' ||
    (item.estatus && item.estatus.toLowerCase().includes(this.searchTerm.toLowerCase())) || '' ||
    (item.monto_operado && item.monto_operado.toLowerCase().includes(this.searchTerm.toLowerCase())) || ''
  );
}*/

get filteredDataq(): any[] {
  const filtered = this.solicitudes.filter(item => //return
    (!this.searchTerms.folio || (item.folio && item.folio.toLowerCase().includes(this.searchTerms.folio.toLowerCase()))) &&
    (!this.searchTerms.fecha_operacion || (item.fecha_operacion && item.fecha_operacion.toLowerCase().includes(this.searchTerms.fecha_operacion.toLowerCase()))) &&
    (!this.searchTerms.fecha_vencimiento || (item.fecha_vencimiento && item.fecha_vencimiento.toLowerCase().includes(this.searchTerms.fecha_vencimiento.toLowerCase()))) &&
    (!this.searchTerms.total_operar || (item.total_operar && item.total_operar.toLowerCase().includes(this.searchTerms.total_operar.toLowerCase()))) &&
    (!this.searchTerms.moneda || (item.moneda && item.moneda.toString().toLowerCase().includes(this.searchTerms.moneda.toLowerCase()))) &&
    (!this.searchTerms.usuario || (item.usuario && item.usuario.toString().toLowerCase().includes(this.searchTerms.usuario.toLowerCase()))) &&
    (!this.searchTerms.status || (item.status && item.status.toString().toLowerCase().includes(this.searchTerms.status.toLowerCase()))) &&
    (!this.searchTerms.cadena || (item.cadena && item.cadena.toString().toLowerCase().includes(this.searchTerms.cadena.toLowerCase()))) &&
    (!this.searchTerms.proovedor || (item.proovedor && item.proovedor.toString().toLowerCase().includes(this.searchTerms.proovedor.toLowerCase())))
  );
  return filtered.sort((a, b) => this.compare(a, b, this.sortColumn, this.sortDirection));
}

get dataToShow(): any[] {
  const startIndex = (this.currentPage - 1) * this.itemsPerPage;
  return this.filteredDataq.slice(startIndex, startIndex + this.itemsPerPage);
}

get totalPages(): number {
  return Math.ceil(this.filteredDataq.length / this.itemsPerPage);
}

get pages(): number[] {
  const totalPages = this.totalPages;

  if (totalPages <= this.maxVisiblePages) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  const pages = [];
  const halfRange = Math.floor(this.maxVisiblePages / 2);

  let startPage = Math.max(1, this.currentPage - halfRange);
  let endPage = Math.min(totalPages, startPage + this.maxVisiblePages - 1);

  if (endPage === totalPages) {
    startPage = totalPages - this.maxVisiblePages + 1;
  }
  if (startPage === 1) {
    endPage = this.maxVisiblePages;
  }

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return pages;
}

// Métodos de navegación
goToPage(page: number): void {
  if (page >= 1 && page <= this.totalPages) {
    this.currentPage = page;
  }
}

previousPage(): void {
  if (this.currentPage > 1) {
    this.currentPage--;
  }
}

nextPage(): void {
  if (this.currentPage < this.totalPages) {
    this.currentPage++;
  }
}

goToEllipsisPage(position: 'first' | 'last'): void {
  if (position === 'first') {
    this.currentPage = 1;
  } else if (position === 'last') {
    this.currentPage = this.totalPages;
  }
}

exportExcelFil(tab: string) {
  let fullData: any[]
  fullData = this.filteredDataq;
  const readOpts = {
      cellText: false, 
      cellDates: true,
  };
 // Generar los datos personalizados para exportación
 const dataToExport = fullData.map(item => ({
  'Folio': item.folio || '',
  'Fecha de operacion': item.fecha_operacion || '',
  'Fecha de vencimiento': item.fecha_vencimiento || '',
  'Total operado': item.total_operar || '',
  'Moneda': item.moneda || '',
  'Usuario': item.usuario || '',
  'Estatus': item.status || '',
  'Empresa': item.cadena || '',
  'Proveedor': item.proovedor || '',
  'Cesion': item.attached || '',
  'Firma': item.extra1 || ''
}));
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport, readOpts);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, 'solicitudes.xlsx', readOpts);
}

 formatFecha(fecha: { split: (arg0: string) => [any, any, any]; }) {
  if (!fecha) return '';
  const [year, month, day] = fecha.split('-');
  return `${day}/${month}/${year}`;
}

removeCommas(monto: { toString: () => string; }) {
  if (!monto) return '';
  return monto.toString().replace(/,/g, ''); // Elimina todas las comas
}

/*reporfac(idsf: any) {
  this.fundersService.getfundingrequest(idsf).subscribe(resp => {
    this.datosdetalles = resp['data'].relations.funding_request_invoices;
    this.showmodalds = true;
  })
}

hideds() {
  this.showmodalds = false;
}*/

exportExcelcxc() {
  let fileName = 'Data.xlsx';
  /* table id is passed over here */
  const element = document.getElementById('CXC');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  /* save to file */
  XLSX.writeFile(wb, fileName);
}

sortData(column: string): void {
  // Cambia la dirección de ordenación si se selecciona la misma columna, de lo contrario reinicia a ascendente
  if (this.sortColumn === column) {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
  } else {
    this.sortColumn = column;
    this.sortDirection = 'asc';
  }
}

private compare(a: any, b: any, column: string, direction: 'asc' | 'desc'): number {
  if (!column) return 0;

  let valueA = a[column];
  let valueB = b[column];

  // Lógica personalizada para la columna de íconos
  if (column === 'statusIcon') {
    valueA = a.status === 'ESPERA FIRMA DIGITAL' ? 0 : 1; // Prioridad para mdi-close-circle
    valueB = b.status === 'ESPERA FIRMA DIGITAL' ? 0 : 1;
  }

  let comparison = 0;
  if (valueA > valueB) {
    comparison = 1;
  } else if (valueA < valueB) {
    comparison = -1;
  }

  return direction === 'asc' ? comparison : -comparison;
}

//seleccionar filas//
toggleRowSelection(row: any): void {
  if (this.selectedRows.has(row)) {
    this.selectedRows.delete(row);
  } else {
    this.selectedRows.add(row);
  }
}

// Devuelve true si la fila está seleccionada.
isRowSelected(row: any): boolean {
  return this.selectedRows.has(row);
}

// Selecciona todas las filas.

selectAllRows(): void {
  if (this.selectedRows.size === this.filteredDataq.length) {
    // Si ya están todos seleccionados, desmarcarlos
    this.selectedRows.clear();
  } else {
    // Si no, seleccionar todos
    this.allData = this.filteredDataq; // Sincronizar los datos
    this.allData.forEach(row => this.selectedRows.add(row));
  }
}

// Procesa las filas seleccionadas.
processSelectedRows(): void {
  const selectedRowsArray = Array.from(this.selectedRows);
  console.log('Filas seleccionadas:', selectedRowsArray);
}

get isAllSelected(): boolean {
  return this.selectedRows.size === this.filteredDataq.length;
}
/////////////////////////////
}







