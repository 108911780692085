import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { UsuarioService, ListasService } from '../../services/service.index';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Usuario3 } from '../../models/usuario.model';
import { Router } from '@angular/router';
import swal2 from 'sweetalert2';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-editarusuario',
  templateUrl: './editarusuario.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class EditarUsuarioComponent implements OnInit {
  @Input() eventEmitter: EventEmitter<Object>;
  forma: FormGroup;
  formaRc: FormGroup;
  resp: any;
  listaEstatus: any;
  genero: any[];
  showModal: boolean;
  idu = '';
  constructor( public _usuarioService: UsuarioService,
               public http: HttpClient,
               private route: ActivatedRoute,
               private lista: ListasService,
               public router: Router ) { }

  ngOnInit() {
    this.subscribeToParentEmitter();
    this.forma = new FormGroup({
      nombre: new FormControl( null),
      correo: new FormControl( null),
      password: new FormControl( null),
      puesto: new FormControl( null ),
      genero: new FormControl( null ),
      estatus: new FormControl( null ),
    });

    this.formaRc = new FormGroup({
      passwordRc: new FormControl( null , Validators.required ),
      password2Rc: new FormControl( null , Validators.required ),
    }, { validators: this.sonIguales( 'passwordRc', 'password2Rc' )  });
  }

  subscribeToParentEmitter(): void { 
    this.eventEmitter.subscribe((d: { contid: string; }) => {
      this.idu = d.contid;
      const observable1 = this._usuarioService.getUsuario( this.idu );
      const observable2 = this._usuarioService.getUserGender();
      const observable3 = this._usuarioService.getUserStatus();
        forkJoin([observable1, observable2, observable3]).subscribe(async ([usuario, generos, status]) => {
          this.resp = usuario;
          this.genero = generos;
          this.listaEstatus = status;
        }, () => {
          swal2.fire({
            title: 'Ocurrio un error al consultar los datos',
            text: '',
            icon: 'error',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {
            if ( res.value ) {
              
            }
          } );
        });
    })
  }

  hagocambio() {
    const observable1 = this._usuarioService.getUsuario( this.idu );
      const observable2 = this._usuarioService.getUserGender();
      const observable3 = this._usuarioService.getUserStatus();
        forkJoin([observable1, observable2, observable3]).subscribe(async ([usuario, generos, status]) => {
          this.resp = usuario;
          this.genero = generos;
          this.listaEstatus = status;
          swal2.close();
        }, () => {
          swal2.close();
          swal2.fire({
            title: 'Ocurrio un error al consultar los datos',
            text: '',
            icon: 'error',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {
            if ( res.value ) {
              
            }
          } );
        });
  }

  guardarCambios(item: { id: string; name: string; email: string; job: string; gender: string; status: string; }) {
    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: true
 });
    swal2.showLoading();

    const usuario = new Usuario3(
      item.id,
      item.name,
      item.email,
      item.job,
      item.gender,
      item.status
    );
    if ( usuario.nombre.length === 0 || usuario.email.length === 0 || usuario.puesto.length === 0) {
      swal2.fire('Todos los campos son obligatorios','','info');
      return;
    }

    this._usuarioService.actualizaUsuario(usuario).subscribe( () => {
          swal2.close();
          swal2.fire({
            title: 'El usuario',
            text: 'fue Modificado con exito',
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {

            if ( res.value ) {
              this.hagocambio();
            }

          } );

        }, (err) => {
          swal2.close();
          console.log(err);
          swal2.fire({
            title: 'Ocurrio un error',
            text: '',
            icon: 'error',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {
            if ( res.value ) {
              this.ngOnInit();
            }
          } );
         } );


  }

show() {
    this.formaRc.reset();
    this.showModal = true;    
  }
hide() {
    this.showModal = false;
  }

sonIguales( campo1: string, campo2: string ) {

    return ( group: FormGroup ) => {

      let pass1 = group.controls[campo1].value;
      let pass2 = group.controls[campo2].value;

      if ( pass1 === pass2 ) {
        return null;
      }

      return {
        sonIguales: true
      };

    };

  }

resetpassword() {

    if ( this.formaRc.invalid ) {
      return;
    }

    const params = {
      token: '',
      secret_key: '',
      password: this.formaRc.value.passwordRc
    };
    this._usuarioService.actualizaUsuarioRcPc( this.idu, params )
              .subscribe( resp => {// this.router.navigate(['/login']);
                swal2.fire({
                  title: 'Cambio de contraseña exitoso',
                  text: '',
                  icon: 'success',
                  showConfirmButton: true,
                  showCancelButton: false,
                  allowOutsideClick: false
                }). then ( res => {
      
                  if ( res.value ) {
                    this.hagocambio();
                  }
      
                } );
              this.hide();
            }, (err) => { console.log(err);
                          swal2.fire(
                                        'Error al cambiar contraseña',
                                        'Error',
                                        'error'
                                     );
                                     this.hagocambio();
                          this.hide();
                                  }
            );


  }

  borraUsuario( user: any ) {
    swal2.fire({
      title: 'Desea Eliminar al usuario',
      text: user.name + '?',
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false
    }). then ( resp => {
      if ( resp.value) {

        this._usuarioService.borrarUsuario( user ).subscribe( () => {

          swal2.fire({
            title: 'El usuario' + user.name,
            text: 'fue eliminado con exito',
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {

            if ( res.value ) {
              window.location.reload();
            }

          } );

        }, (err) => {
        //  console.log(err);
          console.clear();
          swal2.fire({
            title: 'Ocurrio un error',
            text: '',
            icon: 'error',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {
            if ( res.value ) {
              window.location.reload();
            }
          } );
         } );

      }
    });

  }
}
