import { Injectable } from '@angular/core';
import { Usuario, Usuario2 } from '../../models/usuario.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';

@Injectable()
export class SharedService {
  usuario: Usuario;
  token: string;
  usuario2: Usuario2;
  idUsuario: string;
  options: any[] = [];

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    this.cargarStorage();
   }

   cargarStorage() {

    if ( localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.usuario = JSON.parse( localStorage.getItem('usuario') );
    } else {
      this.token = '';
      this.usuario = null;
    }

  }

  getGitVersion() {

    const url = environment.URL_SERVICIOS.replace('/api/v1', '') + '/get_git_version';


    return this.http.get( url).pipe(
              map( (resp: any) => {
                return resp;
              }));
  }

}
