import { Component, EventEmitter, OnInit } from '@angular/core';
import { FundersService } from 'src/app/services/service.index';

@Component({
  selector: 'app-lista-funder-tags',
  templateUrl: './lista-funder-tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class ListaFunderTagsComponent implements OnInit {
  // funder_tags = [];
  fondeadores = [];
  items: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 10;
  searchTerm: string = '';
  filaSeleccionada: number | null = null;
  ventanaValues = false;
  funderValuesEmitter = new EventEmitter<Object>();

  constructor(private _fundersservice: FundersService) { }

  ngOnInit() {
    this.items = [];
    this._fundersservice.getfundersfinancial().subscribe((resp) => {
      this.fondeadores = resp;
      this._fundersservice.getFunderTags().subscribe(resp => {
        resp['data'].forEach(ft => {
          ft.attributes.fondeador = this.fondeadores.find(f => f.id_funder == ft.attributes.funder_id).nombre_fondeador;
          this.items.push(ft.attributes);
        });
      })
    });

  }

  ngOnDestroy(): void { 
    this.funderValuesEmitter.unsubscribe();

  } 

  get dataToShow(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.filteredData.slice(startIndex, startIndex + this.itemsPerPage);
  }

  get filteredData(): any[] {

    return this.items.filter(item =>
      item.fondeador.toLowerCase().includes(this.searchTerm.toLowerCase()) 
    );
  }

  pgone() {
    this.currentPage = 1;
    this.filaSeleccionada = -1;
  }

  seleccionarFila(index: number, currentPage: number, itemsPerPage: number) {
    this.filaSeleccionada = index + (currentPage - 1) * itemsPerPage;
  }

  get totalPages(): number {
    return Math.ceil(this.filteredData.length / this.itemsPerPage);
  }

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  asignarValues(item){
    this.ventanaValues = true;
    setTimeout(() => {
      this.funderValuesEmitter.emit({item});
    }, 100);   
  }

  cerrarValues(event) {
    this.ventanaValues = false;
    this.ngOnInit();
  }

}
