import { Component, OnInit, Input } from '@angular/core';
import { ReportesService, OptionsService, FacturasService, AltaSolicitudesService } from '../../services/service.index';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Swal from 'sweetalert2';
import { forkJoin, timer } from 'rxjs';
import { delayWhen, retryWhen, scan } from 'rxjs/operators';
declare var $;


@Component({
  selector: 'app-facturasalt',
  templateUrl: './facturasalt.component.html',
  styleUrls: ['./facturas.component.css']
})
export class FacturasaltComponent implements OnInit {
  factoraje_type = 'SIN_RECURSO';
  proveedor = 'Emisor';
  cadena = 'Receptor';

  constructor( public _reportesservice: ReportesService,
               public router: Router,
               public _optionsservice: OptionsService,
               public http: HttpClient,
               private facturasService: FacturasService,
               private solicitudesService: AltaSolicitudesService) { }

  token = localStorage.getItem('token');
  doc = new jsPDF();
  facturas: any[] = [];
  usuario: string;
  cols: any[];
  colspdf: any[];
  selectedFac: any[];
  fileName = 'ListaDeFacturas.xlsx';
  selectedColumnsp: any[];
  selectedColumnspdf: any[];
  exportColumns: any[];

  _selectedColumns: any[];
  totalOperadoMXN = 0;
  totalFacturasMXN = 0;
  totalOperadoUSD = 0;
  totalFacturasUSD = 0;
  isExporting: boolean = false;

    //NuevaTabla ///
    sortColumn: string = ''; // Columna actual de ordenación
    sortDirection: 'asc' | 'desc' = 'asc'; // Dirección de ordenación
    items: any[] = [];
    currentPage: number = 1;
    itemsPerPage: number = 10;
    searchTerm: string = '';
    maxVisiblePages: number = 8; // Número máximo de páginas visibles
    searchTerms = {
      id_factura: '',
      folio_factura: '',
      folio_solicitud: '',
      folio_solicitud_fondeo: '',
      uuid_factura_descontada: '',
      emisor: '',
      receptor: '',
      customer_credit_name:'',
      moneda: '',
      fecha_operacion: '',
      fecha_cobranza: '',
      fecha_vencimiento: '',
      fecha_emision: '',
      fecha_carga: '',
      estatus: '',
      total_factura: '',
      porcentaje_operado: '',
      monto_operado: '',
      disponible: '',
      intereses: '',
      tasa_interbancaria: '',
      sobretasa: '',
      tasa_total: '',
      comision: '',
      costo_financiero: '',
      dias_descuento: '',
      monto_neto: '',
      tasa_comision: '',
      dia_pago_cadena: '',
      monto_pago: '',
      dias_al_vencimiento: ''
      };
      selectedRows = new Set<any>(); // Almacena las filas seleccionadas
      allData = []; // Todos los datos disponibles (incluidos los de otras páginas).
      selectedIdsf: string | null = null;
      isExpanded: boolean = false; // Controla si la tabla está expandida o colapsada
      originalFecha: string = '';
      originalFechaVencimiento: string = '';
      isEditing: boolean = false; // Estado para controlar si se está editando un campo
      currentEditingItem: any = null;
      isAdmin: boolean = false;
      visibleColumns: string[] = ['folio_factura', 'folio_solicitud', 
        'folio_solicitud_fondeo','uuid_factura_descontada', 'moneda', 'fecha_cobranza', 'fecha_vencimiento'
      ]; // Columnas visibles en el modo colapsado
      /////////////

  ngOnInit() {
    
    if (!(this.facturas && this.facturas.length)) {
      setTimeout(() => {
        swal2.fire({
          title: 'Cargando',
          allowOutsideClick: true
        });
        swal2.showLoading();
      }, 500);
    }
    setTimeout(() => {
    const observable1 = this.solicitudesService.getGeneralParam('USUARIO_ADMINISTRADOR');
    const observable2 = this._reportesservice.getReporteFacturas();
    const observable3 = this.solicitudesService.getGeneralParam('FACTORAJE_TYPE');
      forkJoin([observable1, observable2, observable3]).pipe(
        retryWhen(errors => 
          errors.pipe(
            scan((retryCount, err) => {
              if (retryCount >= 2) {
                throw err;
              }
              console.warn(`Reintentando... Intento ${retryCount + 1}`);
              return retryCount + 1;
            }, 0),
            delayWhen(() => timer(1000))
          )
        )
      ).subscribe({
        next: ([res1, res2, res3]) => {
          if (localStorage.getItem('emailuser')) {
            if (localStorage.getItem('emailuser') === res1['value']) {
              this.isAdmin = true;
            }
          }
    
          this.factoraje_type = res3['value'];
          switch(this.factoraje_type){
            case 'SIN_RECURSO':
              this.proveedor = 'Emisor';
              this.cadena = 'Receptor';
              break;
            case 'CON_RECURSO':
              this.proveedor = 'Receptor';
              this.cadena = 'Emisor';
              break;
            default:
              this.factoraje_type = 'SIN_RECURSO';
              this.proveedor = 'Emisor';
              this.cadena = 'Receptor';
          }
    
          this.facturas = res2;
          this.calcularTotales();
        },
        error: (err) => {
          console.error('Error:', err);
          swal2.fire({
            title: 'Ocurrió un error al cargar la información',
            icon: 'error',
            allowOutsideClick: false,
          });
        },
        complete: () => {
          swal2.close();
        }
      });
    }, 1000);
    
  
    /*this.solicitudesService.getGeneralParam('USUARIO_ADMINISTRADOR').subscribe(resp => {
      console.log(resp)
      if (localStorage.getItem('emailuser')) {
        if (localStorage.getItem('emailuser') === resp['value']) {
          this.isAdmin = true;
        }
      }
    })
    this._reportesservice.getReporteFacturas()
      .subscribe(resp => {
        console.log(resp);
        this.facturas = resp;
       // this.facturas[0].estatus = 'No Encontrado';
        this.calcularTotales();
        swal2.close();
      } , (err) => {swal2.fire({title: 'Ocurrio un error al cargar la información', allowOutsideClick: false })} );


    //ESTE PARAMETRO (FACTORAJE_TYPE) SOLO SE USA PARA FINANTAH POR LO QUE NO SE REQUIERE CREAR EN FACTORAJE DE FACTOR  
    this.solicitudesService.getGeneralParam('FACTORAJE_TYPE').subscribe(resp => {
      this.factoraje_type = resp['value'];
      switch(this.factoraje_type){
        case 'SIN_RECURSO':
          this.proveedor = 'Emisor';
          this.cadena = 'Receptor';
          break;
        case 'CON_RECURSO':
          this.proveedor = 'Receptor';
          this.cadena = 'Emisor';
          break;
        default:
          this.factoraje_type = 'SIN_RECURSO';
          this.proveedor = 'Emisor';
          this.cadena = 'Receptor';
      }
      
    });*/

    this.cols = [

      { field:  'id_factura', header: 'ID'},
      { field:  'folio_factura', header: 'Folio factura' },
      { field:  'folio_solicitud', header: 'Folio solicitud'},
      { field:  'folio_solicitud_fondeo', header: 'Folio Solicitud Fondeo'},
      { field:  'uuid_factura_descontada', header: 'UUID'},
      { field:  'emisor', header: this.proveedor},
      { field:  'receptor', header: this.cadena},
      { field:  'cliente', header: 'customer_credit_name' },
      { field:  'moneda', header: 'Moneda'},
      { field:  'fecha_operacion', header: 'Fecha de operación'},
      { field:  'fecha_cobranza', header: 'Fecha de cobranza'},
      { field:  'fecha_vencimiento', header: 'Fecha de vencimiento'},
      { field:  'fecha_emision', header: 'Fecha de emision'},
      { field:  'fecha_carga', header: 'Fecha de carga'},
      { field:  'estatus', header: 'Estatus'},
      { field:  'total_factura', header: 'Total factura' },
      { field:  'porcentaje_operado', header: 'Porcentaje operado' },
      { field:  'monto_operado', header: 'Total'},
      { field:  'disponible', header: 'Disponible' },
      { field:  'intereses', header: 'Intereses'},
      { field:  'tasa_interbancaria', header: 'Tasa interbancaria' },
      { field:  'sobretasa', header: 'Sobretasa' },
      { field:  'tasa_total', header: 'Tasa total' },
      { field:  'comision', header: 'Comisión Total ' },
      { field:  'costo_financiero', header: 'Costo financiero' },
      { field:  'dias_descuento', header: 'Días descuento' },
      { field:  'monto_neto', header: 'Monto neto' },
      { field:  'tasa_comision', header: 'Porcentaje de Comisión' },
      { field:  'dia_pago_cadena', header: 'Día de pago empresa' },
      { field:  'monto_pago', header: 'Monto pago'},
      { field:  'dias_al_vencimiento', header: 'Días al Vencimiento'}
    ];

    this._selectedColumns = this.cols;
    this.colspdf = [

      { field:  'folio_solicitud', header: 'Folio Solicitud'},
      { field:  'folio_solicitud_fondeo', header: 'Folio Solicitud Fondeo'},
      { field:  'folio_factura', header: 'Folio Factura' },
      { field:  'uuid_factura_descontada', header: 'UUID'},
      { field:  'estatus', header: 'Estatus'},
      { field:  'fecha_emision', header: 'Fecha de emisión'},
      { field:  'fecha_carga', header: 'Fecha de carga'},
      { field:  'fecha_operacion', header: 'Fecha de operación'},
      { field:  'fecha_vencimiento', header: 'Fecha de vencimiento'},
      { field:  'moneda', header: 'Moneda'},
      { field:  'monto_operado', header: 'Total'},
      { field:  'intereses', header: 'Intereses'},
      { field:  'receptor', header: 'Empresa'},
      { field:  'emisor', header: 'Proveedor'},
      { field:  'tasa_comision', header: 'Comisión Cadena'},
      { field:  'dia_pago_cadena', header: 'Día de pago empresa'},
      { field:  'dias_al_vencimiento', header: 'Días al Vencimiento'}
];
    this.selectedColumnsp = this.cols;
    this.exportColumns = this.colspdf.map(col => ({title: col.header, dataKey: col.field}));


  }

  trackByFn(index: number, item: any): any {
    return item.id_factura; // Usa un identificador único para cada elemento
  }

  toggleTable(): void {
    this.isExpanded = !this.isExpanded;
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
}

set selectedColumns(val: any[]) {
  // restore original order
  this._selectedColumns = this.cols.filter(col => val.includes(col));
}

cambiarEstatus(rowData){

  var params = {};
  var invoice = {status: 'PENDIENTE'}

  params['invoice'] = invoice;

  Swal.fire({
    title: 'Confirmación',
    text: "¿Desea actualizar el status a 'PENDIENTE'?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, actualizar!',
    cancelButtonText: 'Cancelar'
  }).then((result) => {
    if (result.value) {
      this.facturasService.updateInvoices(rowData.id_factura, params).subscribe(resp => {
        swal2.fire('Éxito', 'Status actualizado correctamente', 'success');
        this.ngOnInit();
      }, err => {
        swal2.fire('Atención', 'Algo salió mal', 'info');
      })
    }
  })

}

  exportpdf() {

   import('jspdf').then( jsPDF => {
    import('jspdf-autotable').then(x => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(this.exportColumns, this.facturas);
        doc.save('ListaFacturas.pdf');
    });
}); 

  }

  change(){
    const element = document.getElementById('tablaFacturas');
    let tags = element.getElementsByTagName('th');
    const arrTags = Array.from(tags);
    let idColumnaMoneda = arrTags.findIndex(tag => tag.innerText === 'Moneda ');

    let celdas = element.getElementsByTagName('td');
    this.totalFacturasMXN = 0;
    this.totalFacturasUSD = 0;
    this.totalOperadoMXN = 0;
    this.totalOperadoUSD = 0;

    let j = idColumnaMoneda;
    let idColumna = arrTags.findIndex(tag => tag.innerText === 'Total ');
    for(let i = idColumna; i < celdas.length; i+= idColumna){
      if(celdas[j].innerText === 'PESOS'){
        if(!isNaN(parseFloat(celdas[i].innerText.replace(',', '')))){
          this.totalOperadoMXN += parseFloat(celdas[i].innerText.replace(',', ''));
        }
      }else if(celdas[j].innerText === 'DOLARES'){
        if(!isNaN(parseFloat(celdas[i].innerText.replace(',', '')))){
          this.totalOperadoUSD += parseFloat(celdas[i].innerText.replace(',', ''));
        }
      }
      i += (this._selectedColumns.length-idColumna);
      j += (this._selectedColumns.length-idColumnaMoneda) + idColumnaMoneda;
    }

    j = idColumnaMoneda;
    idColumna = arrTags.findIndex(tag => tag.innerText === 'Total factura ');
    for(let i = idColumna; i < celdas.length; i+= idColumna){
      if(celdas[j].innerText === 'PESOS'){
        this.totalFacturasMXN += parseFloat(celdas[i].innerText.replace(',', ''));
      }else if(celdas[j].innerText === 'DOLARES'){
        this.totalFacturasUSD += parseFloat(celdas[i].innerText.replace(',', ''));
      }
      i += (this._selectedColumns.length-idColumna);
      j += (this._selectedColumns.length-idColumnaMoneda) + idColumnaMoneda;
    }
  }

  //nueva version///
  calcularTotales() {
    let totalFacturasMXN = 0;
    let totalFacturasUSD = 0;
    let totalOperadoMXN = 0;
    let totalOperadoUSD = 0;
    this.filteredDataq.forEach(factura => {
        // Validar que los campos existan y convertir cadenas con separador de coma a números
        const totalFactura = factura.total_factura ? parseFloat(factura.total_factura.replace(/,/g, '')) || 0 : 0;
        const montoOperado = factura.monto_operado ? parseFloat(factura.monto_operado.replace(/,/g, '')) || 0 : 0;

        if (factura.moneda === 'PESOS') {
            totalFacturasMXN += totalFactura;
            totalOperadoMXN += montoOperado;
        } else if (factura.moneda === 'DOLARES') {
            totalFacturasUSD += totalFactura;
            totalOperadoUSD += montoOperado;
        }
    });

    // Asignar los resultados a las variables de clase
    this.totalFacturasMXN = totalFacturasMXN;
    this.totalFacturasUSD = totalFacturasUSD;
    this.totalOperadoMXN = totalOperadoMXN;
    this.totalOperadoUSD = totalOperadoUSD;
}
  pgone() {
    this.currentPage = 1;
  }

  get filteredDataq(): any[] {
    const filtered = this.facturas.filter(item => {
      // Saltar filtrado si está en modo edición
      if (this.isEditing) {
        return true;
      }
  
      // Filtros existentes
      return (
        (!this.searchTerms.id_factura || (item.id_factura && item.id_factura.toString().toLowerCase().includes(this.searchTerms.id_factura.toLowerCase()))) &&
        (!this.searchTerms.folio_factura || (item.folio_factura && item.folio_factura.toLowerCase().includes(this.searchTerms.folio_factura.toLowerCase()))) &&
      (!this.searchTerms.folio_solicitud || (this.searchTerms.folio_solicitud === 'null' && item.folio_solicitud === null) || (item.folio_solicitud && item.folio_solicitud.toLowerCase().includes(this.searchTerms.folio_solicitud.toLowerCase()))) &&
      (!this.searchTerms.folio_solicitud_fondeo || (item.folio_solicitud_fondeo && item.folio_solicitud_fondeo.toLowerCase().includes(this.searchTerms.folio_solicitud_fondeo.toLowerCase()))) &&
      (!this.searchTerms.uuid_factura_descontada || (item.uuid_factura_descontada && item.uuid_factura_descontada.toLowerCase().includes(this.searchTerms.uuid_factura_descontada.toLowerCase()))) &&
      (!this.searchTerms.emisor || (item.emisor && item.emisor.toString().toLowerCase().includes(this.searchTerms.emisor.toLowerCase()))) &&
      (!this.searchTerms.receptor || (item.receptor && item.receptor.toString().toLowerCase().includes(this.searchTerms.receptor.toLowerCase()))) &&
      (!this.searchTerms.customer_credit_name || (item.customer_credit_name && item.customer_credit_name.toString().toLowerCase().includes(this.searchTerms.customer_credit_name.toLowerCase()))) &&
      (!this.searchTerms.moneda || (item.moneda && item.moneda.toString().toLowerCase().includes(this.searchTerms.moneda.toLowerCase()))) &&
      (!this.searchTerms.fecha_operacion || (item.fecha_operacion && item.fecha_operacion.toString().toLowerCase().includes(this.searchTerms.fecha_operacion.toLowerCase()))) &&
      (!this.searchTerms.fecha_cobranza || (item.fecha_cobranza && item.fecha_cobranza.toString().toLowerCase().includes(this.searchTerms.fecha_cobranza.toLowerCase()))) &&
      (!this.searchTerms.fecha_vencimiento || (item.fecha_vencimiento && item.fecha_vencimiento.toString().toLowerCase().includes(this.searchTerms.fecha_vencimiento.toLowerCase()))) &&
      (!this.searchTerms.fecha_emision || (item.fecha_emision && item.fecha_emision.toLowerCase().includes(this.searchTerms.fecha_emision.toLowerCase()))) &&
      (!this.searchTerms.fecha_carga || (item.fecha_carga && item.fecha_carga.toLowerCase().includes(this.searchTerms.fecha_carga.toLowerCase()))) &&
      (!this.searchTerms.estatus || (item.estatus && item.estatus.toLowerCase().includes(this.searchTerms.estatus.toLowerCase()))) &&
      (!this.searchTerms.total_factura || (item.total_factura && item.total_factura.toLowerCase().includes(this.searchTerms.total_factura.toLowerCase()))) &&
      (!this.searchTerms.porcentaje_operado || (item.porcentaje_operado && item.porcentaje_operado.toString().toLowerCase().includes(this.searchTerms.porcentaje_operado.toLowerCase()))) &&
      (!this.searchTerms.monto_operado || (item.monto_operado && item.monto_operado.toString().toLowerCase().includes(this.searchTerms.monto_operado.toLowerCase()))) &&
      (!this.searchTerms.disponible || (item.disponible && item.disponible.toString().toLowerCase().includes(this.searchTerms.disponible.toLowerCase()))) &&
      (!this.searchTerms.intereses || (item.intereses && item.intereses.toString().toLowerCase().includes(this.searchTerms.intereses.toLowerCase()))) &&
      (!this.searchTerms.tasa_interbancaria || (item.tasa_interbancaria && item.tasa_interbancaria.toString().toLowerCase().includes(this.searchTerms.tasa_interbancaria.toLowerCase()))) &&
      (!this.searchTerms.sobretasa || (item.sobretasa && item.sobretasa.toString().toLowerCase().includes(this.searchTerms.sobretasa.toLowerCase()))) &&
      (!this.searchTerms.tasa_total || (item.tasa_total && item.tasa_total.toLowerCase().includes(this.searchTerms.tasa_total.toLowerCase()))) &&
      (!this.searchTerms.comision || (item.comision && item.comision.toLowerCase().includes(this.searchTerms.comision.toLowerCase()))) &&
      (!this.searchTerms.costo_financiero || (item.costo_financiero && item.costo_financiero.toLowerCase().includes(this.searchTerms.costo_financiero.toLowerCase()))) &&
      (!this.searchTerms.dias_descuento || (item.dias_descuento && item.dias_descuento.toLowerCase().includes(this.searchTerms.dias_descuento.toLowerCase()))) &&
      (!this.searchTerms.monto_neto || (item.monto_neto && item.monto_neto.toString().toLowerCase().includes(this.searchTerms.monto_neto.toLowerCase()))) &&
      (!this.searchTerms.tasa_comision || (item.tasa_comision && item.tasa_comision.toString().toLowerCase().includes(this.searchTerms.tasa_comision.toLowerCase()))) &&
      (!this.searchTerms.dia_pago_cadena || (item.dia_pago_cadena && item.dia_pago_cadena.toString().toLowerCase().includes(this.searchTerms.dia_pago_cadena.toLowerCase()))) &&
      (!this.searchTerms.monto_pago || (item.monto_pago && item.monto_pago.toString().toLowerCase().includes(this.searchTerms.monto_pago.toLowerCase()))) &&
      (!this.searchTerms.dias_al_vencimiento || (item.dias_al_vencimiento && item.dias_al_vencimiento.toString().toLowerCase().includes(this.searchTerms.dias_al_vencimiento.toLowerCase()))) &&
        // Resto de los filtros
        true
      );
    });
  
    return filtered.sort((a, b) => this.compare(a, b, this.sortColumn, this.sortDirection));
  }
  
  get filteredDataqq(): any[] {
    
    const filtered = this.facturas.filter(item => 
      
      (!this.searchTerms.id_factura || (item.id_factura && item.id_factura.toString().toLowerCase().includes(this.searchTerms.id_factura.toLowerCase()))) &&
      (!this.searchTerms.folio_factura || (item.folio_factura && item.folio_factura.toLowerCase().includes(this.searchTerms.folio_factura.toLowerCase()))) &&
      (!this.searchTerms.folio_solicitud || (this.searchTerms.folio_solicitud === 'null' && item.folio_solicitud === null) || (item.folio_solicitud && item.folio_solicitud.toLowerCase().includes(this.searchTerms.folio_solicitud.toLowerCase()))) &&
      (!this.searchTerms.folio_solicitud_fondeo || (item.folio_solicitud_fondeo && item.folio_solicitud_fondeo.toLowerCase().includes(this.searchTerms.folio_solicitud_fondeo.toLowerCase()))) &&
      (!this.searchTerms.uuid_factura_descontada || (item.uuid_factura_descontada && item.uuid_factura_descontada.toLowerCase().includes(this.searchTerms.uuid_factura_descontada.toLowerCase()))) &&
      (!this.searchTerms.emisor || (item.emisor && item.emisor.toString().toLowerCase().includes(this.searchTerms.emisor.toLowerCase()))) &&
      (!this.searchTerms.receptor || (item.receptor && item.receptor.toString().toLowerCase().includes(this.searchTerms.receptor.toLowerCase()))) &&
      (!this.searchTerms.customer_credit_name || (item.customer_credit_name && item.customer_credit_name.toString().toLowerCase().includes(this.searchTerms.customer_credit_name.toLowerCase()))) &&
      (!this.searchTerms.moneda || (item.moneda && item.moneda.toString().toLowerCase().includes(this.searchTerms.moneda.toLowerCase()))) &&
      (!this.searchTerms.fecha_operacion || (item.fecha_operacion && item.fecha_operacion.toString().toLowerCase().includes(this.searchTerms.fecha_operacion.toLowerCase()))) &&
      (!this.searchTerms.fecha_cobranza || (item.fecha_cobranza && item.fecha_cobranza.toString().toLowerCase().includes(this.searchTerms.fecha_cobranza.toLowerCase()))) &&
      (!this.searchTerms.fecha_vencimiento || (item.fecha_vencimiento && item.fecha_vencimiento.toString().toLowerCase().includes(this.searchTerms.fecha_vencimiento.toLowerCase()))) &&
      (!this.searchTerms.fecha_emision || (item.fecha_emision && item.fecha_emision.toLowerCase().includes(this.searchTerms.fecha_emision.toLowerCase()))) &&
      (!this.searchTerms.fecha_carga || (item.fecha_carga && item.fecha_carga.toLowerCase().includes(this.searchTerms.fecha_carga.toLowerCase()))) &&
      (!this.searchTerms.estatus || (item.estatus && item.estatus.toLowerCase().includes(this.searchTerms.estatus.toLowerCase()))) &&
      (!this.searchTerms.total_factura || (item.total_factura && item.total_factura.toLowerCase().includes(this.searchTerms.total_factura.toLowerCase()))) &&
      (!this.searchTerms.porcentaje_operado || (item.porcentaje_operado && item.porcentaje_operado.toString().toLowerCase().includes(this.searchTerms.porcentaje_operado.toLowerCase()))) &&
      (!this.searchTerms.monto_operado || (item.monto_operado && item.monto_operado.toString().toLowerCase().includes(this.searchTerms.monto_operado.toLowerCase()))) &&
      (!this.searchTerms.disponible || (item.disponible && item.disponible.toString().toLowerCase().includes(this.searchTerms.disponible.toLowerCase()))) &&
      (!this.searchTerms.intereses || (item.intereses && item.intereses.toString().toLowerCase().includes(this.searchTerms.intereses.toLowerCase()))) &&
      (!this.searchTerms.tasa_interbancaria || (item.tasa_interbancaria && item.tasa_interbancaria.toString().toLowerCase().includes(this.searchTerms.tasa_interbancaria.toLowerCase()))) &&
      (!this.searchTerms.sobretasa || (item.sobretasa && item.sobretasa.toString().toLowerCase().includes(this.searchTerms.sobretasa.toLowerCase()))) &&
      (!this.searchTerms.tasa_total || (item.tasa_total && item.tasa_total.toLowerCase().includes(this.searchTerms.tasa_total.toLowerCase()))) &&
      (!this.searchTerms.comision || (item.comision && item.comision.toLowerCase().includes(this.searchTerms.comision.toLowerCase()))) &&
      (!this.searchTerms.costo_financiero || (item.costo_financiero && item.costo_financiero.toLowerCase().includes(this.searchTerms.costo_financiero.toLowerCase()))) &&
      (!this.searchTerms.dias_descuento || (item.dias_descuento && item.dias_descuento.toLowerCase().includes(this.searchTerms.dias_descuento.toLowerCase()))) &&
      (!this.searchTerms.monto_neto || (item.monto_neto && item.monto_neto.toString().toLowerCase().includes(this.searchTerms.monto_neto.toLowerCase()))) &&
      (!this.searchTerms.tasa_comision || (item.tasa_comision && item.tasa_comision.toString().toLowerCase().includes(this.searchTerms.tasa_comision.toLowerCase()))) &&
      (!this.searchTerms.dia_pago_cadena || (item.dia_pago_cadena && item.dia_pago_cadena.toString().toLowerCase().includes(this.searchTerms.dia_pago_cadena.toLowerCase()))) &&
      (!this.searchTerms.monto_pago || (item.monto_pago && item.monto_pago.toString().toLowerCase().includes(this.searchTerms.monto_pago.toLowerCase()))) &&
      (!this.searchTerms.dias_al_vencimiento || (item.dias_al_vencimiento && item.dias_al_vencimiento.toString().toLowerCase().includes(this.searchTerms.dias_al_vencimiento.toLowerCase())))
    );
    
    return filtered.sort((a, b) => this.compare(a, b, this.sortColumn, this.sortDirection));
    
  }
  
  get dataToShow(): any[] {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      return this.filteredDataq.slice(startIndex, startIndex + this.itemsPerPage);
    
  }
  
  get totalPages(): number {
    return Math.ceil(this.filteredDataq.length / this.itemsPerPage);
  }
  
  get pages(): number[] {
    const totalPages = this.totalPages;
  
    if (totalPages <= this.maxVisiblePages) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }
  
    const pages = [];
    const halfRange = Math.floor(this.maxVisiblePages / 2);
  
    let startPage = Math.max(1, this.currentPage - halfRange);
    let endPage = Math.min(totalPages, startPage + this.maxVisiblePages - 1);
  
    if (endPage === totalPages) {
      startPage = totalPages - this.maxVisiblePages + 1;
    }
    if (startPage === 1) {
      endPage = this.maxVisiblePages;
    }
  
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
  
    return pages;
  }
  
  // Métodos de navegación
  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }
  
  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
  
  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }
  
  goToEllipsisPage(position: 'first' | 'last'): void {
    if (position === 'first') {
      this.currentPage = 1;
    } else if (position === 'last') {
      this.currentPage = this.totalPages;
    }
  }
  
  exportExcelFil() {
    let fullData: any[];
    fullData = this.filteredDataq;
    const readOpts = {
      cellText: false,
      cellDates: true,
    };
  
    // Generar los datos personalizados para exportación
    const dataToExport = fullData.map(item => ({
      'ID': item.id_factura || '',
      'Folio factura': item.folio_factura || '',
      'Folio solicitud': item.folio_solicitud || '',
      'Folio Solicitud Fondeo': item.folio_solicitud_fondeo || '',
      'UUID': item.uuid_factura_descontada || '',
      [this.proveedor]: item.emisor || '',
      [this.cadena]: item.receptor || '',
      'Cliente': item.customer_credit_name || '',
      'Moneda': item.moneda || '',
      'Fecha de operación': this.convertToDate(item.fecha_operacion),
      'Fecha de cobranza': this.convertToDate(item.fecha_cobranza),
      'Fecha de vencimiento': this.convertToDate(item.fecha_vencimiento),
      'Fecha de emision': this.convertToDate(item.fecha_emision) || '',
      'Fecha de carga': this.convertToDate(item.fecha_carga) || '',
      'Estatus': item.estatus || '',
      'Total factura': this.removeCommas(item.total_factura) || '',
      'Porcentaje operado': this.removeCommas(item.porcentaje_operado) || '',
      'Total': this.removeCommas(item.monto_operado) || '',
      'Disponible': this.removeCommas(item.disponible) || '',
      'Intereses': this.removeCommas(item.intereses) || '',
      'Tasa interbancaria': this.removeCommas(item.tasa_interbancaria) || '',
      'Sobretasa': this.removeCommas(item.sobretasa) || '',
      'Tasa total': this.removeCommas(item.tasa_total) || '',
      'Comisión Total': this.removeCommas(item.comision) || '',
      'Costo financiero': this.removeCommas(item.costo_financiero) || '',
      'Días descuento': this.removeCommas(item.dias_descuento) || '',
      'Monto neto': this.removeCommas(item.monto_neto) || '',
      'Porcentaje de Comisión': item.tasa_comision || '',
      'Día de pago empresa': this.convertToDate(item.dia_pago_cadena) || '',
      'Monto pago': this.removeCommas(item.monto_pago) || '',
      'Días al Vencimiento': item.dias_al_vencimiento || ''
    }));
  
    // Crear el WorkSheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport, readOpts);
  
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'solicitudes.xlsx', readOpts);
  }

  convertToDate(fecha: string): Date | null {
    if (!fecha) return null;
    if (fecha.includes('-')) {
      const parts = fecha.split('-');
      if (parts.length === 3) {
        return new Date(+parts[0], +parts[1] - 1, +parts[2]);
      }
    }
    if (fecha.includes('/')) {
      const parts = fecha.split('/');
      if (parts.length === 3) {
        return new Date(+parts[0], +parts[1] - 1, +parts[2]);
      }
    }
    return null;
  }
  
  

  removeCommas(monto: { toString: () => string; }): number | null {
    if (!monto) return null; // Retorna null si no hay monto
    const montoSinComas = monto.toString().replace(/,/g, ''); // Elimina todas las comas
    return parseFloat(montoSinComas); // Convierte a número
  }
  
  /*removeCommas(monto: { toString: () => string; }) {
    if (!monto) return '';
    return monto.toString().replace(/,/g, ''); // Elimina todas las comas
  }
    
  */
  
  sortData(column: string): void {
    if (this.isEditing) {
     // console.log('No se puede ordenar mientras seas administrador.');
      return;
  }
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
  
  }
  
  private compare(a: any, b: any, column: string, direction: 'asc' | 'desc'): number {
    if (!column) return 0;

    let valueA = a[column];
    let valueB = b[column];
  
    // Lógica personalizada para la columna de íconos
    if (column === 'statusIcon') {
      valueA = a.status === 'ESPERA FIRMA DIGITAL' ? 0 : 1; // Prioridad para mdi-close-circle
      valueB = b.status === 'ESPERA FIRMA DIGITAL' ? 0 : 1;
    }
  
    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
  
    return direction === 'asc' ? comparison : -comparison;
  }
  
  enableEdit(item: any): void {
    item.editingFecha = true;
    item.showError = false; // Oculta mensajes de error al entrar en modo de edición
    this.originalFecha = item.fecha_cobranza; // Guarda la fecha original
  }

  saveEdit(item: any): void {
    if (!this.isValidDate(item.fecha_cobranza)) {
      item.isValidDate = false;
      item.showError = true; // Muestra mensaje de error
      return;
    }

    item.isValidDate = true;
    item.showError = false;
    item.editingFecha = false;
    this.facturasService.updateInvoices(item.id_factura, {invoice: {used_date: item.fecha_cobranza}}).subscribe(
      res => {
        swal2.fire({
          title: 'Éxito',
          text: 'Fecha cobranza modificada correctamente',
          icon: 'info'
        }).then(() => {
          // Aquí se ejecuta ngOnInit después de que el usuario hace clic en "OK"
          this.ngOnInit();
        });
      },
      err => {
        console.log(err);
        swal2.fire({
          title: 'Atención',
          text: err.error.errors[0],
          icon: 'info'
        });
      }
    );
  }

  cancelEdit(item: any): void {
    item.editingFecha = false;
    item.fecha_cobranza = this.originalFecha; // Restaura la fecha original
    item.showError = false; // Oculta mensaje de error al cancelar
  }

  enableEditFechaVencimiento(item: any): void {
   // this.startEditing();
    item.editingFechaVencimiento = true;
    item.showErrorVencimiento = false; // Oculta errores al habilitar la edición
    this.originalFechaVencimiento = item.fecha_vencimiento; // Guarda el valor original
  }

  saveEditFechaVencimiento(item: any): void {
    if (!this.isValidDate(item.fecha_vencimiento)) {
      item.isValidFechaVencimiento = false;
      item.showErrorVencimiento = true; // Muestra mensaje de error
      return;
    }

    item.isValidFechaVencimiento = true;
    item.showErrorVencimiento = false;
    item.editingFechaVencimiento = false;
   // this.stopEditing();
    this.facturasService.updateInvoices(item.id_factura, {invoice: {due_date: item.fecha_vencimiento}}).subscribe(
      res => {
        swal2.fire({
          title: 'Éxito',
          text: 'Fecha vencimiento modificada correctamente',
          icon: 'info'
        }).then(() => {
          // Aquí se ejecuta ngOnInit después de que el usuario hace clic en "OK"
          this.ngOnInit();
        });
      },
      err => {
        console.log(err);
        swal2.fire({
          title: 'Atención',
          text: err.error.errors[0],
          icon: 'info'
        });
      }
    );
    
  }

  cancelEditFechaVencimiento(item: any): void {
   // this.stopEditing();
    item.editingFechaVencimiento = false;
    item.fecha_vencimiento = this.originalFechaVencimiento; // Restaura el valor original
    item.showErrorVencimiento = false; // Oculta el mensaje de error
  }

  isValidDate(dateString: string): boolean {
    const regex = /^(?:19|20)\d{2}\/(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])$/;
    if (!regex.test(dateString)) {
      return false;
    }
  
    const [year, month, day] = dateString.split('/').map(Number);
    const date = new Date(year, month - 1, day);
  
    // Verifica que la fecha generada sea válida
    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  }

  borraFactura(item: any): void {
    
    const tieneFolioSolicitud = item.folio_solicitud && item.folio_solicitud.length > 0;
    const tieneFolioSolicitudFondeo = item.folio_solicitud_fondeo && item.folio_solicitud_fondeo.length > 0;
    const estatusPendiente = item.estatus && item.estatus.toUpperCase() === 'PENDIENTE';

    if (!estatusPendiente || tieneFolioSolicitud || tieneFolioSolicitudFondeo) {
        Swal.fire({
            icon: 'error',
            title: 'No se puede eliminar la factura',
            text: 'Solo se puede eliminar una factura si está en estatus PENDIENTE y no tiene folio de solicitud ni folio de solicitud de fondeo.',
        });
        return;
    }

    Swal.fire({
        icon: 'warning',
        title: '¿Desea eliminar la factura?',
        text: 'Esta acción no se puede deshacer',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
    }).then((result) => {
        if (result.value) {
            this.facturasService.deleteInvoiceRepor(item.id_factura).subscribe(res => {
              Swal.fire({
                icon: 'success',
                title: 'Factura eliminada correctamente',
                text: '',
                showCancelButton: false,
                allowOutsideClick: false,
                confirmButtonText: 'OK'
            }).then((result) => {
              if (result.value) {
                window.location.reload();
              }
            })
            })
        }
    });
}
descargarArchivo(data: string, tipo: 'pdf' | 'xml'): void {
  if (tipo === 'pdf' || (tipo === 'xml' && data.startsWith('http'))) {
    // Descargar desde una URL
    const link = document.createElement('a');
    link.href = data;
    link.target = '_blank';
    link.download = `archivo.${tipo}`;
    link.click();
  } else if (tipo === 'xml') {
    // Descargar texto como archivo
    const blob = new Blob([data], { type: 'application/xml' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'archivo.xml';
    link.click();
  }
}

startEditing() {
  this.isEditing = true; // Activar modo edición
}

stopEditing() {
  this.isEditing = false; // Desactivar modo edición
}
  /////////////////

}
