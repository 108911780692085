import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FundersService, PagosService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-funder-values',
  templateUrl: './funder-values.component.html',
  styleUrls: ['.././tags.component.css']
})
export class FunderValuesComponent implements OnInit {

  @Input() eventEmitter: EventEmitter<Object>;
  @Output() childEvent = new EventEmitter();
  funder_tags = [];
  cols = [];
  companies = [];
  company_id = 0;
  emmiter = {fondeador: ''};
  funder_values = [];
  agregarNuevo = false;

  constructor(private fundersService: FundersService,
    private pagosService: PagosService
  ) { }

  ngOnInit() {
    this.pagosService.getCadenas().subscribe((resp) => {
      this.companies = resp;
    })

    this.subscribeToParentEmitter();

  }

  subscribeToParentEmitter(): void { 
    swal2.showLoading();
    this.eventEmitter.subscribe((d: { item }) => {
      this.emmiter = d.item;
      this.armarTabla();
    })
  }

  armarTabla(){
    this.cols = [];

    this.fundersService.getTagsByFunder(this.emmiter['funder_id']).subscribe(resp => {
      this.funder_values = resp[0]['funder_values'];
      this.funder_values.forEach(fv => {
        fv['empresa'] = this.companies.find(c => c.id_cadena == fv.company_id).nombre_cadena;
      })
      Object.keys(resp[0]['funder_tags'][0]).forEach(k => {
        if(k.startsWith('value') && resp[0]['funder_tags'][0][k] != null){
         this.cols.push({field: k, header: resp[0]['funder_tags'][0][k], funder_value: ''})
        }
      })
      swal2.close();
    })

  }

  cancelar(){
    this.agregarNuevo = false;
    this.cols.forEach(v => {
      v['funder_value'] = '';
    })
  }

  cerrarValues() {
    this.childEvent.emit({message: 'cerrarcreausr'})
  }

  registrarValues(){
    if(this.company_id == 0){
      swal2.fire('Atención', 'Porfavor, seleccione una empresa válida', 'info');
      return
    }
    var values = {};
    this.cols.forEach(v => {
      values[v.field] = v['funder_value'];
    })
    var params = {
      company_id: this.company_id,
      funder_values: values,
      funder_id: this.emmiter['funder_id']
    }
    this.fundersService.creaFunderValues(params).subscribe(resp => {
      swal2.fire('Éxito', 'Valores registrados correctamente', 'success').then((result) => {
        if (result) {
          this.company_id = 0;
          this.agregarNuevo = false;
          location.reload(); // Refresca la pantalla
        }
      });
    }, err => {
      console.log(err);
      this.cancelar();
      try{
        swal2.fire('Atención', err.error.error, 'info');
      }catch{
        swal2.fire('Atención', 'Algo salió mal', 'info');
      }
    })
  }

}
