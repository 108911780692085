import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';


@Injectable()
export class StpService {

//   usuario: Usuario;
  token: string;
//   usuario2: Usuario2;
  idUsuario: string;
  options: any[] = [];
  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    this.cargarStorage();
  }

  cargarStorage() {

    if ( localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
    //   this.usuario = JSON.parse( localStorage.getItem('usuario') );
    } else {
      this.token = '';
    //   this.usuario = null;
    }

  }

  getStpAccountBalanceStp(params) {

    const url = `${environment.URL_SERVICIOS}/consultaSaldoCuenta?business=${params.empresa}&ordering_account=${params.cuentaOrdenante}&token=${this.token}&secret_key=${environment.SECRET_KEY}`;
    return this.http.post(url, {})

  }

  getContributorsWithStpAccounts() {

    const url = `${environment.URL_SERVICIOS}/get_contributors_with_stp_accounts?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
    return this.http.get(url)

  }

  generateAccountStp() {

    const url = `${environment.URL_SERVICIOS}/generate_stp_account?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
    return this.http.get(url)

  }

  registraOrdenPago( params ) {

    params.token = this.token;
    params.secret_key = environment.SECRET_KEY;
  
    const url = `${environment.URL_SERVICIOS}/registraOrden`;
  
    return this.http.post( url, params ).pipe(
      map( (resp: any) => {
        return resp;
      }));
  
  }

  conciliacion( params ) {

    params.token = this.token;
    params.secret_key = environment.SECRET_KEY;
  
    const url = `${environment.URL_SERVICIOS}/conciliacion`;
  
    return this.http.post( url, params ).pipe(
      map( (resp: any) => {
        return resp;
      }));
  
  }

  createStpAccount(params) {
    // https://dev.devfactorgfc.com/api/v1/create_stp_account?secret_key=93302eef21f513a83748e5104874bb7d&token=374bffc2c85cbdd9ea1422dcdf8be0ee&contributor_id=4&cuentaClave=646180352100000039&cuentaConcentradora=646180352100000000
    const url = `${environment.URL_SERVICIOS}/create_stp_account?contributor_id=${params.contributor_id}&cuenta_clabe=${params.cuenta_clabe}&cuenta_concentradora=${params.cuenta_concentradora}&token=${this.token}&secret_key=${environment.SECRET_KEY}`;
    return this.http.post(url, {})

  }


}
