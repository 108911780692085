import { Component, OnInit, Input, EventEmitter, OnDestroy } from '@angular/core';
import { ContribuyentesService, OptionsService, MantenimientoContribuyentesService } from '../../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Subscription, of, Observable } from 'rxjs';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-domicilios',
  templateUrl: './domicilios.component.html',
  styleUrls: ['../mantcontmain.component.css']
})
export class DomiciliosComponent implements OnInit, OnDestroy {
  @Input() eventEmitter: EventEmitter<Object>;
  message: string;
  direcciones: any[] = [];
  direccion: any[] = [];
  nombreestado = '';
  nombremunicipio = '';
  tipodomicilio = '';
  calle = '';
  numeroexterior = '';
  numerointerior = '';
  tipoasentamiento = '';
  codigopostal = '';
  neighborhood = '';
  referencia = '';
  muestradirecciones = false;
  estados: any[] = [];
  municipios: any[] = [];
  addresstype: any[] = [];
  suburbtype: any[] = [];
  suburb: any[] = [];
  suburbM: any[] = [];
  contribuyenteslist: any[] = [];
  idestado = '';
  ide = '';
  idm = '';
  idc: string;
  manual = false;
  manualp = false;
  constructor(
               public _contribuyentesService: ContribuyentesService,
               public _optionsservice: OptionsService,
               public _mantenimientocontservice: MantenimientoContribuyentesService,
               public router: Router,
               public http: HttpClient ) { }

  ngOnInit() {
    this.subscribeToParentEmitter();
  }

  ngOnDestroy(): void { 
    this.eventEmitter.unsubscribe();
   // console.log('DESTRUYO COMPONENTE DOMICILIOS');

}



  subscribeToParentEmitter(): void { 
    this.eventEmitter.subscribe(d => {//console.log(d) 
      this.idc = d.idc; 
      this._contribuyentesService.getStates().subscribe( resp => { this.estados = resp; } );
      this._contribuyentesService.getAdresstype().subscribe( resp => { this.addresstype = resp; } );
      this._contribuyentesService.getAsentamientotype().subscribe( resp => { this.suburbtype = resp; } );
      this._mantenimientocontservice.getDirecciones(this.idc).subscribe( resp => {this.direcciones = resp; } );
    })
}


filtramunicipios() {
  const estado: any = document.getElementById('estado');
  const valorestado = estado.options[estado.selectedIndex].value;
  for (const prop in this.estados) {
    if ( this.estados[prop].name === valorestado ) {
      this.idestado = this.estados[prop].id;
      break;
    }
  }
  this._contribuyentesService.getMunicipios( this.idestado ).subscribe(resp => {this.municipios = resp; });

}

buscacp() {

  const cp = (document.getElementById('postal_code') as HTMLInputElement).value;

  this._contribuyentesService.getColoniasxcp( cp ).subscribe( resp => { 
    this.suburb = resp;
    if(this.suburb.length == 0){
      this.manual = true;
    }  else {
      this.manual = false;
    }
  } );

}

modificardireccion() {
  swal2.showLoading();

  this.idm = '';

  const direccion: any = document.getElementById('direccion');
  const valordireccion = direccion.options[direccion.selectedIndex].value;

  const estado: any = document.getElementById('estado');
  const municipio: any = document.getElementById('municipio');
  const adresstype: any = document.getElementById('adress_type');
  const suburbtype: any = document.getElementById('suburb_type');
  
  const valorestado = estado.options[estado.selectedIndex].value;
  const valormunicipio = municipio.options[municipio.selectedIndex].value;
  const valoradresstype = adresstype.options[adresstype.selectedIndex].value;
  const valorsuburbtype = suburbtype.options[suburbtype.selectedIndex].value;
  let valorsuburb;
  if(!this.manual){
    const suburbb: any = document.getElementById('suburb');
    valorsuburb = suburbb.options[suburbb.selectedIndex].value;
  }else{
    valorsuburb = (document.getElementById('suburbM') as HTMLInputElement).value;
  }

  for (const prop in this.estados) {
    if ( this.estados[prop].name === valorestado ) {
      this.ide = this.estados[prop].id;
      break;
    }
  }

  for (const prop in this.municipios) {
    if ( this.municipios[prop].name === valormunicipio ) {
      this.idm = this.municipios[prop].id;
      break;
    }

}
  if (this.municipios.length == 0) {
  for (const prop in this.direcciones) {
    if ( this.direcciones[prop].id == valordireccion ) {
      this.idm = this.direcciones[prop].municipality_id;
      break;
    }
  }
}

  const params = {

    municipality_id: this.idm,
    state_id: this.ide,
    address_type: valoradresstype,
    street: (document.getElementById('street')as HTMLInputElement).value,
    external_number: (document.getElementById('external_number')as HTMLInputElement).value,
    apartment_number: (document.getElementById('apartment_number')as HTMLInputElement).value,
    suburb_type: valorsuburbtype,
    suburb: valorsuburb,
    postal_code: (document.getElementById('postal_code')as HTMLInputElement).value,
    address_reference: (document.getElementById('address_reference')as HTMLInputElement).value,
    token: '',
    secret_key: ''

  };

  this._mantenimientocontservice.modificaDireccion(this.idc, valordireccion , params).subscribe( resp => { swal2.close();
                                                                                                           swal2.fire({
title: 'Los datos se modificaron',
text: 'Con exito',
icon: 'success',
showConfirmButton: true,
showCancelButton: false,
allowOutsideClick: false
}). then ( res => {

if ( res.value ) {
window.location.reload();
}

} );

}, (err) => {
swal2.close();
console.log(err);
swal2.fire({
  title: 'Ocurrio un error',
  text: '',
  icon: 'error',
  showConfirmButton: true,
  showCancelButton: false,
  allowOutsideClick: false
}). then ( res => {
  if ( res.value ) {
    window.location.reload();
  }
} );
});

}

filtramunicipiosp() {

  const estado: any = document.getElementById('estadop');

  const valorestado = estado.options[estado.selectedIndex].value;

  for (const prop in this.estados) {
    if ( this.estados[prop].name === valorestado ) {
      this.idestado = this.estados[prop].id;
      break;
    }
  }

  this._contribuyentesService.getMunicipios( this.idestado ).subscribe(resp => {this.municipios = resp; });

}

buscacpp() {

  const cp = (document.getElementById('postal_codep') as HTMLInputElement).value;

  this._contribuyentesService.getColoniasxcp( cp ).subscribe( resp => { 
    this.suburb = resp; 
    if(this.suburb.length == 0){
      this.manualp = true;
    } else {
      this.manualp = false;
    }
  } );

}

registrardireccionp() {
  swal2.showLoading();
  const estado: any = document.getElementById('estadop');
  const municipio: any = document.getElementById('municipiop');
  const adresstype: any = document.getElementById('adress_typep');
  const suburbtype: any = document.getElementById('suburb_typep');
  
  const valorestado = estado.options[estado.selectedIndex].value;
  const valormunicipio = municipio.options[municipio.selectedIndex].value;
  const valoradresstype = adresstype.options[adresstype.selectedIndex].value;
  const valorsuburbtype = suburbtype.options[suburbtype.selectedIndex].value;
  let valorsuburb;
  if(!this.manualp){
    const suburbb: any = document.getElementById('suburbp');
    valorsuburb = suburbb.options[suburbb.selectedIndex].value;
  }else{
    valorsuburb = (document.getElementById('suburbpM') as HTMLInputElement).value;
  }

  for (const prop in this.estados) {
    if ( this.estados[prop].name === valorestado ) {
      this.ide = this.estados[prop].id;
      break;
    }
  }

  for (const prop in this.municipios) {
    if ( this.municipios[prop].name === valormunicipio ) {
      this.idm = this.municipios[prop].id;
      break;
    }
  }

  const params = {

    municipality_id: this.idm,
    state_id: this.ide,
    address_type: valoradresstype,
    street: (document.getElementById('streetp')as HTMLInputElement).value,
    external_number: (document.getElementById('external_numberp')as HTMLInputElement).value,
    apartment_number: (document.getElementById('apartment_numberp')as HTMLInputElement).value,
    suburb_type: valorsuburbtype,
    suburb: valorsuburb,
    postal_code: (document.getElementById('postal_codep')as HTMLInputElement).value,
    address_reference: (document.getElementById('address_referencep')as HTMLInputElement).value

  };

  this._contribuyentesService.creaDireccionxContribuyente(this.idc, params).subscribe( resp => {
    swal2.close();
    swal2.fire({
      title: 'Los datos se guardaron',
      text: 'Con exito',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
      }). then ( res => {
      if ( res.value ) {
      window.location.reload();
      }
      } );
      }, (err) => {
        swal2.close();
        console.log(err);
        swal2.fire({
          title: 'Ocurrio un error',
          text: '',
          icon: 'error',
          showConfirmButton: true,
          showCancelButton: false,
          allowOutsideClick: false
        }). then ( res => {
          if ( res.value ) {
            window.location.reload();
          }
        } );
       });

}

getDireccion() {
  const direccion: any = document.getElementById('direccion');
  const valordireccion = direccion.options[direccion.selectedIndex].value;
  if (valordireccion !== 'novalor') {
    this.muestradirecciones = true;
  } else {
    this.muestradirecciones = false;
  }
  // tslint:disable-next-line: forin
  for ( const prop in this.direcciones ) {
    if (this.direcciones[prop].id == valordireccion) {
      //console.log(this.direcciones);
      this._contribuyentesService.getMunicipios( this.direcciones[prop].state_id ).subscribe(resp => {this.municipios = resp; });
      this._contribuyentesService.getColoniasxcp( this.direcciones[prop].postal_code ).subscribe( resp => { 
        this.suburb = resp;
        if(this.suburb.length == 0){
          this.manual = true;
          this.suburbM = this.direcciones[prop].suburb;
        }  else {
          this.manual = false;
          this.neighborhood = this.direcciones[prop].suburb;
        }
      } );
      this._mantenimientocontservice.getNombreState(this.direcciones[prop].state_id).subscribe( resp => {this.nombreestado = resp[0].name; } );
      this._mantenimientocontservice.getNombreMunicipality(this.direcciones[prop].municipality_id).subscribe( resp => this.nombremunicipio = resp[0].name );
      this.tipodomicilio = this.direcciones[prop].address_type;
      this.calle = this.direcciones[prop].street;
      this.numeroexterior = this.direcciones[prop].external_number;
      this.numerointerior = this.direcciones[prop].apartment_number;
      this.tipoasentamiento = this.direcciones[prop].suburb_type;
      this.codigopostal = this.direcciones[prop].postal_code;
      this.neighborhood = this.direcciones[prop].suburb;
      this.referencia = this.direcciones[prop].address_reference;
      break;
    }
  }

}

borraDireccion() {
  const direccion: any = document.getElementById('direccion');
  const valordireccion = direccion.options[direccion.selectedIndex].value;

  swal2.fire({
    title: 'Desea elminiar la direccion seleccionada',
    text: '?',
    icon: 'question',
    showConfirmButton: true,
    showCancelButton: true,
    allowOutsideClick: false
  }). then ( resp => {
    if ( resp.value) {

      this._mantenimientocontservice.borraDireccion( this.idc, valordireccion ).subscribe( () => {

        swal2.fire({
          title: 'La direccion se elimino',
          text: 'Con exito',
          icon: 'success',
          showConfirmButton: true,
          showCancelButton: false,
          allowOutsideClick: false
          }). then ( res => {
          if ( res.value ) {
          window.location.reload();
          }
          } );
          }, (err) => {
            console.log(err);
            swal2.fire({
              title: 'Ocurrio un error',
              text: '',
              icon: 'error',
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: false
            }). then ( res => {
              if ( res.value ) {
                window.location.reload();
              }
            } );
           });

    }
  });
}


}