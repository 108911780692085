import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import swal2 from 'sweetalert2';
import { ReportesService } from '../../services/reportes/reportes.service';
import { ContribuyentesService } from '../../services/contribuyentes/contribuyentes.service';
import { DashboardService } from '../../services/dashboard/dashboard.service';
// import * as jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
// import * as pdfMake from 'pdfmake/build/pdfmake';
// // import htmlToPdfmake from 'html-to-pdfmake'
// import { style } from '@angular/animations';

@Component({
  selector: 'app-estado-cuenta-contribuyente',
  templateUrl: './estado-cuenta-contribuyente.component.html',
  styleUrls: ['./estado-cuenta-contribuyente.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EstadoCuentaContribuyenteComponent implements OnInit {
  companyes: any;
  cols = [

    //  { field:  'id_factura', header: 'ID'},
      { field:  'uuid', header: 'Invoice Number'},
      { field:  'valor_nominal', header: 'Invoice Value'},
      { field:  'monto_operado', header: 'Net Amount'},
      { field:  'reserva', header: 'Remaining'},
      { field:  'costo_descuento', header: 'Interests'},
      { field:  'fecha_vencimiento', header: 'Expiration_date'},
  ];


  selectedColumns = this.cols;
  facturas: any[] = [];
  fileName: string;
  company: any;
  delinquencyIndicator = [];
  report = false;
  periodos= {};
  treinta = [];
  sesenta = [];
  noventa = [];
  masNoventa = [];

  constructor(public _reportesservice: ReportesService,
              private contribuyentesService: ContribuyentesService,
              private dashboardService: DashboardService) { }

  ngOnInit() {
    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
 });
    swal2.showLoading();
   // this.getAcceso('/pagoscompany');
    this._reportesservice.getCompapanyes().subscribe( resp => { 
      this.companyes = resp;
      swal2.close();
    }, (err) => {
      swal2.fire({
        title: 'Ocurrio un error al cargar la información', 
        allowOutsideClick: false 
      })} );
  }

  generarReporte(){
    let idCadena = (document.getElementById('cadena') as HTMLInputElement).value;
    let dateFrom = (document.getElementById('dateFrom') as HTMLInputElement).value;
    let dateTo = (document.getElementById('dateTo') as HTMLInputElement).value;
    if(idCadena != ''){
      this.contribuyentesService.getcompany(idCadena).subscribe(resp => {
        this.company = resp['data']['attributes'];
        this._reportesservice.getInvoicesExpiration(idCadena).subscribe(resp => {
          this.delinquencyIndicator = resp;
          this.delinquencyIndicator.forEach(f => {
            switch(f.periodo){
              case '1-30':
                this.treinta.push(f);
                break;
              case '31-60':
                this.sesenta.push(f);
                break;
              case '61-90':
                this.noventa.push(f);
                break;
              case '+90':
                this.masNoventa.push(f);
                break;
              default:
                break;
            }
            this.periodos = {'1-30': this.treinta, '31-60': this.sesenta, '61-90': this.noventa, '+90': this.masNoventa};
          });
          this._reportesservice.getReportecc_invoices(idCadena, dateFrom, dateTo).subscribe(resp => {
            this.facturas = resp;
            // this.report = true;
          })
        })
      })
    }else{
      swal2.fire('Faltan datos para generar la consulta', '', 'info');
    }
  }

  exportReport(){
    var params = {
      "company": this.company,
      "facturas": this.facturas,
      "facturas_por_periodo": this.periodos,
      "user_id": localStorage.getItem('id')
    }
    this._reportesservice.sendAccountStatus(params).subscribe(resp => {
      console.log(resp);
      try{
        swal2.fire('Éxito', resp.message, 'success')
      }catch{
        swal2.fire('Éxito', 'Correo enviado correctamente', 'success')
      }
    }, err => {
      console.log(err);
    })
  }

   
  /* exportReport() {
    html2canvas(document.getElementById('reporte'), {
      // Opciones
      allowTaint: true,
      useCORS: false,
      // Calidad del PDF
      scale: 1
    }).then(function(canvas) {
      var img = canvas.toDataURL("image/png");
      var doc = new jsPDF();
      doc.addImage(img,'PNG',7, 20, 195, 105);
      doc.save('postres.pdf');
    });
  } */

  /* exportReport(){
    
    const doc = new jsPDF();
   
    const elementHtml = document.getElementById('reporte');
   
    var html = htmlToPdfmake(elementHtml.innerHTML);
     
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();
  } */

  /* buildTableBody(data) {
    var body = [];
    var columns = [];

    this.cols.forEach(c => {
      columns.push(c.header);
    })

    body.push(columns);

    var dataRow = [];
    data.forEach(f => {

      var d = [];
      this.cols.forEach(c => {
        console.log('f', f[c.field])
        if(!f[c.field]){
          d.push('N/A');
        }else{
          d.push(f[c.field]);
        }
      })
      dataRow.push(d.toString());

    });
    body.push(dataRow);

    return body;
} */

  /* exportReport(){
    var dd = {
      content: [
        {
          columns: [
            {
              image: '/assets/images/fileben.jpeg',
              text: 'We help you move the world',
              style: {
                alignment: 'center'
              }
            },
            {},
            {}
          ]
        },
        {
          text: '\n\n'
        },
        {
          columns: [
            {
              text: `Client number: \n ${this.company.id} \n\n Credit limit: \n ${this.company.credit_limit}`, 
              style: {
                alignment: 'center'
              }
            },
            {
              text: `Customer Since: \n ${this.company.start_date} \n\n Used credit: \n ${this.company.balance}`,
              style: {
                alignment: 'center'
              }
            },
            {
              text: `Account state period: \n Credit available: \n\n ${this.company.credit_available}`,
              style: {
                alignment: 'center'
              }
            },
          ]
        },
        {
          table: {
            headerRows: 1,
            body: this.buildTableBody(this.facturas),
            fillColor: '#555555',
            fillOpacity: '#555555'
          }
        }
      ]
    }

    pdfMake.createPdf(dd).open();
  } */

  /* exportReport(){
    var pdf = new jsPDF('p', 'pt', 'a4');
    pdf.addHTML(document.getElementById('reporte') , function() {
      pdf.save('web.pdf');
    });
  } */

}
