import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportesService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';
import * as fs from 'file-saver';
import { Angular2Txt } from 'angular2-txt/Angular2-txt';

@Component({
  selector: 'app-bbva',
  templateUrl: './bbva.component.html',
  styles: []
})
export class BbvaComponent implements OnInit {
  cadenaRespuesta = {};
  fecharepor: string;
  header = [];
  originalHeader = [];
  cadenas = [];

  constructor(private _reportesservice: ReportesService) { }

  facturas: any[] = [];
  usuario: string;
  cols: any[];
  colspdf: any[];
  selectedFac: any[];
  router: Router;
  fileName = 'ListaDeFacturas.xlsx';
  selectedColumnsp: any[];
  selectedColumnspdf: any[];
  exportColumns: any[];
  companieswrecurso: any[];
  muestracompanies = false;
  _selectedColumns: any[];

  ngOnInit() {
    this.muestracompanies = false;
    this._reportesservice.getpayment_with_recourse_companies().subscribe(r => {this.companieswrecurso = r;})
    this.cols = [

    //  { field:  'id_factura', header: 'ID'},
    //  { field:  'payment_report_folio', header: 'Folio'},
      { field:  'tipo_operacion', header: 'Operacion'},
      { field:  'destinatario', header: 'Destinatario'},
      { field:  'cuenta_destino', header: 'Cuenta destino'},
      { field:  'importe', header: 'Importe'},
      { field:  'referencia_numerica', header: 'Referencia numerica' },
      { field:  'referencia_concepto', header: 'Referencia concepto'},
      { field:  'referencia', header: 'Referencia'},
      { field:  'divisa', header: 'Divisa'},
      { field:  'iva', header: 'IVA'},
      { field:  'rfc_destinatario', header: 'RFC destinatario'},
      { field:  'cuenta_cargo', header: 'Cuenta cargo'}
    ];

    this._selectedColumns = this.cols;
    this.colspdf = [

    //  { field:  'id_factura', header: 'ID'},
      { field:  'payment_report_folio', header: 'Folio'},
      { field:  'tipo_operacion', header: 'Operacion'},
      { field:  'destinatario', header: 'Destinatario'},
      { field:  'cuenta_destino', header: 'Cuenta Destino'},
      { field:  'importe', header: 'Importe'},
      { field:  'referencia_concepto', header: 'Referencia Concepto'},
      { field:  'referencia', header: 'Referencia'},
      { field:  'divisa', header: 'Divisa'}
    ];
    this.selectedColumnsp = this.cols;
    this.exportColumns = this.colspdf.map(col => ({title: col.header, dataKey: col.field}));
  }


  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    // restore original order
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }

  muestraonocompanies(e) {
    if (e === 'aforo') {
      this.muestracompanies = true;
    } else {
      this.muestracompanies = false;
    }
  }

generarReporte() {

  swal2.fire({
    title: 'Cargando',
    allowOutsideClick: false
  });
  swal2.showLoading();
  const curr: any = document.getElementById('curr');
  const valorCurr = curr.options[curr.selectedIndex].value;
  const d = new Date((document.getElementById('fechaconsulta')as HTMLInputElement).value);
  d.setMinutes( d.getMinutes() + d.getTimezoneOffset() );
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();
  if (month.length < 2) {
        month = '0' + month;
    }
  if (day.length < 2) {
        day = '0' + day;
    }
  this.fecharepor = [year, month, day].join('-');
  const type = ((document.getElementById('type')as HTMLInputElement).value);
  if (type.toLowerCase() === 'aforo') {
    const idc = ((document.getElementById('compa')as HTMLInputElement).value);
    console.log(idc)
    console.log(valorCurr);
    console.log(this.fecharepor);
    this._reportesservice.getBaseAforo(this.fecharepor, valorCurr, idc).subscribe(resp => {this.facturas = resp;
      swal2.close();
      if ( this.facturas.length === 0 ) {
        swal2.fire(
          'No se encontraron datos con la información proporcionada',
          '',
          'info'
          );
      } else {
        // tslint:disable-next-line: forin
        for (const prop in this.facturas) {
        // this.facturas[prop].importe = this.facturas[prop].importe.replace(/^(0+)/g, '');
          this.facturas[prop].importe = this.facturas[prop].importe.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      }
    }, (err) => {
      swal2.close();
      console.log(err);
      swal2.fire(
      'Error al Consultar los Datos',
      '',
      'error'
      );
      this.ngOnInit();
    } );
  } else {
    this._reportesservice.getBbva(this.fecharepor, valorCurr, type).subscribe(resp => {
      swal2.close();
      if(resp.length == 0){
        swal2.fire(
          'No se encontraron datos con la fecha:',
          this.fecharepor,
          'error'
          );
      }
      this.cadenaRespuesta = resp.respuesta[0];
      this.cadenas = [this.cadenaRespuesta];
      this.header = [];
      this.originalHeader = Object.keys(this.cadenaRespuesta);
      this.originalHeader.forEach(k => {
        this.header.push(k.replace(/_/g, ' '))
      })
    }, (err) => {
    swal2.close();
    console.log(err);
    swal2.fire(
    'Error al Consultar los Datos',
    '',
    'error'
    );
    this.ngOnInit();
    } );
  }
  


}


  exportexcel() {
    this.fileName = 'layoutBBVA-' + this.fecharepor + '.xlsx';
    const Excel = require('exceljs');
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('BBVA');
    
    worksheet.addRow(this.header);
    var contenido = [];
    this.originalHeader.forEach(k => {
      contenido.push(this.cadenaRespuesta[k]);
    })
    worksheet.addRow(contenido);
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: '.xlsx' });
      const file = blob;
      fs.saveAs(blob, this.fileName);
    });
  }


  exporttxt() {
    var options = { 
      fieldSeparator: '',
      quoteStrings: ''
    };
    let datos = [this.cadenaRespuesta]
    this.fileName = 'layoutBBVA' + this.fecharepor;
    new Angular2Txt(datos, this.fileName, options);

  }

}
