import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { ContribuyentesService, OptionsService } from '../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-mantcont',
  templateUrl: './mantcont.component.html',
  styleUrls: ['./mantcontmain.component.css']
})
export class MantContComponent implements OnInit {
  mantcontEmitter = new EventEmitter<Object>();
  constructor(
               public _contribuyentesService: ContribuyentesService,
               public _optionsservice: OptionsService,
               public router: Router,
               public http: HttpClient ) { }
  shotbl = false;
  parametros: any[] = [];
  cols: any[];
  selectedFac: any[];
  contribuyentes: any[];
  fileName = 'ReporteContribuyentes.xlsx';
  _selectedColumns: any[];
  idc: string;
  mantcont = true;
  hidn = true;
  ngOnInit() {
    swal2.showLoading();
    this.shotbl = false;
    this.mantcont = true;
    this.hidn = true;
    this._contribuyentesService.getContribuyentesMain().subscribe( resp => {this.contribuyentes = resp; swal2.close(); } );
    this.cols = [
      { field: 'rfc_contribuyente', header: 'RFC' },
      { field: 'nombre', header: 'Nombre' },
      { field: 'correo', header: 'Correo' },
      { field: 'ver', header: 'Ver' }
  ];
    this._selectedColumns = this.cols;
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
}

set selectedColumns(val: any[]) {
  // restore original order
  this._selectedColumns = this.cols.filter(col => val.includes(col));
}

exportexcel() {
  /* table id is passed over here */
  const element = document.getElementById('tablaFacturas');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  /* save to file */
  XLSX.writeFile(wb, this.fileName);
}

mantcontri(contid, nomcont) {
  this.hidn = false;
  this.mantcont = false;
  this.shotbl = false;
  setTimeout(() => {
    this.mantcontEmitter.emit({contid: contid, nomcont: nomcont});
  }, 300);   
}

pru() {
  
  this.mantcont = !this.mantcont;
}

myFunction() {
  var input, filter, table, tr, td, i, txtValue;
  input = document.getElementById("myInput");
  filter = input.value.toUpperCase();
  table = document.getElementById("myTable");
  tr = table.getElementsByTagName("tr");
  for (i = 1; i < tr.length; i++) {
  td = tr[i];
  if (td) {
    txtValue = td.textContent || td.innerText;
    if (txtValue.toUpperCase().indexOf(filter) > -1) {
      tr[i].style.display = "";
    } else {
      tr[i].style.display = "none";
    }
  }
}
}
//recibo evento y evaluo si viene de click en flecha o desde la caratula para disparar evento principal
rcproveedor(even) {
  
  if (even.check) {
    this.mantcontri(even.clk, even.nombcont);
  } else {
    this.shotbl = even.clk;
  }

}


}
